import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const goUrl = require("../../../img/go_to_bp.svg");
const goOtherTasks = require("../../../img/other_tasks.svg");

type Props = {
  id_bp: number | string | undefined | null;
};

const WrapperBPModal = styled.div`
  position: absolute;
  padding-top: 13px;
  padding-right: 15px;
  min-width: 240px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid lightgray;
  background: #ffffff;
  right: 37px;
  top: 13px;
  align-items: center;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  &:hover {
    background: #f4f4f4;
  }
`;
const BPModalBlock = styled.div`
  width: 100%;
  & span {
    padding-left: 30px;
    padding-top: 35px;
    height: 24px;
    width: 135px;
    border-radius: 4px;
  }
  & img {
    padding-left: 16px;
  }
  & .BPdiv1 {
    padding-bottom: 30px;
  }
`;

const BPModal: React.FC<Props> = ({ id_bp }) => {
  const history = useHistory();
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/bp?${id_bp}`);
  };

  return (
    <WrapperBPModal>
      <BPModalBlock>
        <div className="BPdiv1" onClick={handleClick}>
          <img src={goUrl} alt="" />
          <span>Относится к бизнес-процессу</span>
        </div>
        {/* <div>
          <img src={goOtherTasks} alt="" />
          <span style={{ color: "#757575" }}>Связанные задачи</span>
        </div> */}
      </BPModalBlock>
    </WrapperBPModal>
  );
};

export default BPModal;
