import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TaskCard from "./components/TaskCard";
import styled from "styled-components";
import { Priority as PriorityType } from "../../../../common/types";
import { State } from "../../../../rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchData } from "../../../../utils/fetchData";
import Select from "react-select";
import DateMaster from "../../../../utils/DateMaster";
import { milisecondDateFromOurFormat } from "../../../TaskInfoPlate/reducer";
import { COMPLETED_STATUS, FOR_PERFORMER_STATUS, IN_WORK_STATUS, MADE_STATUS, OVERDUE_STATUS } from "../../helpers";

type Props = {
  pageNum: number;
  setPageNum: (mode: number) => void;
  setIsTaskView: (mode: boolean) => void;
  statuses?: any;
  priorities?: PriorityType[];
  currentStatus: (stat: number, date: Date) => void;
};

const TaskSection = styled.div`
  width: 440px;
  position: fixed;
  z-index: 222;
  right: 0;
  height: calc(100vh - 65px);
  background-color: #ffffff;
  font-family: "Roboto";
  overflow-y: auto;
  overflow-x: none;
  padding: 15px;
`;

const TasksHead = styled.div`
  height: 80px;
  font-size: 20px;
  line-height: 80px;
`;

const FilterSection = styled.div`
  // width: 85%;
  display: flex;
  font-size: 13px;
  margin-bottom: 20px;
`;

const HideButton = styled.span`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const PriorityContent = styled.div``;

const PriorityHead = styled.div`
  margin-bottom: 10px;
`;

const Statuses = styled.div`
  margin-right: 15px;
`;

const StatusHead = styled.div`
  margin-bottom: 10px;
`;

const StyledSelect = styled(Select)`
  width: 137px;
  height: 40px;

  .css-1pahdxg-control {
    max-height: 40px;
    overflow-y: auto;
  }
`;

const TasksNone = styled.div`
  margin-top: 20px;
`;

const taskSelectStyles = {
  multiValue: (styles) => ({
    ...styles,
    flexShrink: '0',
    maxWidth: '100%',
  }),
  valueContainer: (styles) => ({
    ...styles,
    flexWrap: 'nowrap',
  }),
};

const TasksSection: React.FC<Props> = ({
  pageNum,
  setPageNum,
  setIsTaskView,
  statuses,
  currentStatus,
  priorities,
}) => {
  const params = useParams<{ id: string }>();

  const [tasks, setTasks] = useState<any>([]);
  const [priority, setPriority] = useState<any>();
  const [priorityId, setPriorityId] = useState<any[]>();
  const [status, setStatus] = useState<any>();
  const [statusId, setStatusId] = useState<any[]>();

  const ref = useRef<HTMLDivElement>(null);
  const userId = localStorage.getItem("user_id");

  const buildURL = (path, queryParams) => {
    let url = '';
    // Add path
    if (path) {
      url += path;
    }
    // Add query parameters if provided
    if (queryParams) {
      const queryString = Object.keys(queryParams)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&');
      if (queryString) {
        url += `?${queryString}`;
      }
    }

    return url;
  }

  const getEvent = async () => {
    fetchData
      .get(
          buildURL('/api/v1/tasks', {
            page: pageNum,
            executor_id: params.id,
            ...statusAndPriority(),
            order: 'end',
            apply_calendar_filter: '1',
            includePrivate: userId === params.id && "true"
          })
      )
      .then((task) => {
        if (pageNum > 1) {
          setTasks((prevArray) => [...prevArray, ...task]);
        } else {
          setTasks(task);
        }
      })
      .catch((error) => console.error(error));
  };

  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight <= scrollTop + clientHeight + 10 &&
        (tasks.length === 20 * pageNum || tasks.length >= 20 * pageNum)
      ) {
        setPageNum(pageNum + 1);
      }
    },
    [pageNum, tasks?.length]
  );

  useEffect(() => {
    getEvent();
  }, [params.id, pageNum, statusId, priorityId]);

  useEffect(() => {
    setPageNum(1);
  }, [params.id]);

  const statusAndPriority = () => {
    const resultObj = {};
    if (priorityId?.length) {
      resultObj['priority_id'] = priorityId;
    }
    if (statusId?.length) {
      resultObj['status_id'] = statusId;
    }
    return resultObj;
  };

  const availableStatusForFilter = [FOR_PERFORMER_STATUS, IN_WORK_STATUS, MADE_STATUS, COMPLETED_STATUS, OVERDUE_STATUS];
  const statuses_bank = statuses
    ?.filter((item) => {
      if (item.show_in_calendar && availableStatusForFilter.includes(item.id)) {
        return item;
      }
    })
    .map((status) => {
      return {
        value: status.id,
        label: status.name,
      };
    });

  const priority_bank = priorities?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const customMultiValueLabel = ({ data, innerProps }) => (
      <div {...innerProps} title={data.label}>{data.label}</div>
  );

  return (
    <TaskSection onScroll={scrollHandler} ref={ref}>
      <TasksHead>
        <span>Список задач</span> (
        <HideButton
          onClick={() => {
            setIsTaskView(false);
          }}
        >
          скрыть
        </HideButton>
        )
      </TasksHead>
      <FilterSection>
        <Statuses>
          <StatusHead>
            Статус{" "}
            <HideButton
              onClick={() => {
                setPageNum(1);
                setStatusId([]);
                setStatus(null);
              }}
            >
              (сбросить)
            </HideButton>
          </StatusHead>
          <StyledSelect
            isClearable={false}
            options={statuses_bank}
            isMulti={true}
            placeholder="Все"
            name={"status"}
            value={status}
            styles={taskSelectStyles}
            components={{ MultiValueLabel: customMultiValueLabel }}
            onChange={(arg) => {
              setPageNum(1);
              setStatus(arg);
              setStatusId(arg.map((item) => item.value));
            }}
          />
        </Statuses>
        <PriorityContent>
          <PriorityHead>
            Приоритет{" "}
            <HideButton
              onClick={() => {
                setPageNum(1);
                setPriorityId([]);
                setPriority(null);
              }}
            >
              (сбросить)
            </HideButton>
          </PriorityHead>
          <StyledSelect
            isClearable={false}
            isMulti={true}
            options={priority_bank}
            value={priority}
            placeholder="Все"
            name={"priority"}
            styles={taskSelectStyles}
            components={{ MultiValueLabel: customMultiValueLabel }}
            onChange={(arg) => {
              setPageNum(1);
              setPriority(arg);
              setPriorityId(arg.map((item) => item.value));
            }}
          />
        </PriorityContent>
      </FilterSection>
      {tasks.length > 0 ? (
        tasks.map((item) => (
          <TaskCard key={item.id} task={item} currentStatus={currentStatus} />
        ))
      ) : (
        <TasksNone>Не нашлось ни одной задачи</TasksNone>
      )}
    </TaskSection>
  );
};

const mapStateToProps = (state: State) => {
  return {
    statuses: state.commonInfo.statuses,
    priorities: state.commonInfo.priorities,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TasksSection);
