import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  AccordionIcon1,
  AccordionIcon2,
  AccordionIcon3,
  AccordionIcon4,
  AccordionIcon6,
  AccordionIcon5,
  AccordionIcon7,
} from "../../../../../../img/projectIcons";
import Button from "@material-ui/core/Button";
import CommonCirclePreloader from "../../../../../../common/components/CommonInfoPreloader/CommonCirclePreloader";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MeetingTaskSection from "../Questions/Tasks/MeetingTaskSection";
import MeetingTableMiniHead from "../Questions/Tasks/MeetingTableMiniHead";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MeetingMemberItem from "./MeetingMemberItem";
import { MeetingListItem } from "../../../../types";

interface Props {
  meeting: any;
  setActiveMeeting: (meeting: any) => void;
  setIsEdit: (mode: boolean) => void;
}

const DivRight = styled.div`
  display: flex;
  align-items: center;
`;
const DivLeft = styled.div`
  display: flex;
  align-items: center;
`;

const TextSection = styled.h2`
  display: inline;
  width: 400px;
  color: #292a34;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
`;

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: "0",
      border: "1px solid rgba(41, 42, 52, 0.5)",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    ".Mui-expanded": {
      minWidth: "48px",
      border: "1px solid #36EA0",
    },
  },
  MuiAccordionrootNone: {
    "&>.MuiAccordionSummary-root:hover": {
      cursor: "default",
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const MeetingMembers: React.FC<Props> = ({
  meeting,
  setActiveMeeting,
  setIsEdit,
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userId = Number(localStorage.getItem("user_id"));

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const DivIcons2 = styled.p(({ rotate }) => ({
    transform: rotate && "rotate(90deg)",
    width: "10px",
    height: "15px",
  }));

  return (
    <Accordion
      expanded={openAccord}
      onChange={(e, expanded) => setOpenAccord(expanded)}
      classes={{
        root: classes.MuiAccordionroot,
      }}
      style={{
        width: "98%",
        boxShadow: "none",
        marginBottom: "10px",
        // border: "1px solid #436EA0",
        borderRadius: "4px 4px 0px 0px",
      }}
    >
      <AccordionSummary
        //   disableTouchRipple={!tasksState.length}
        classes={{
          root: classes.MuiAccordionSym,
        }}
        style={{
          height: "46px",
          overflow: "hidden",
          border: "1px solid #dbdbdb",
          borderRadius: "4px",
          //   marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "0px 12px",
          minHeight: "46px",
        }}
      >
        <DivLeft>
          <div style={{ width: "70px", paddingLeft: "15px" }}>
            <DivIcons2 rotate={openAccord}>
              <AccordionIcon2 />
            </DivIcons2>
          </div>
          <TextSection>Участники</TextSection>
        </DivLeft>
        <DivRight>
          {meeting.initiator_id === userId ||
          meeting.chairman.user_id === userId ||
          meeting.secretary.user_id === userId ? (
            <>
              <Button onClick={handleClick}>
                <AccordionIcon7 />
              </Button>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    // editClickHandler();
                    setActiveMeeting(meeting);
                    setIsEdit(true);
                    // editClickHandler();
                    handleClose(e);
                  }}
                >
                  Редактировать
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </DivRight>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: "#f4f4f4",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MeetingMemberItem meeting={meeting} />
      </AccordionDetails>
    </Accordion>
  );
};

export default MeetingMembers;
