// import { type } from 'os';
import React from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import palette from '../palette';

type Props = {
  checked?: boolean;
  onChange?: (e: Event) => void;
  disabled?: boolean;
  onClick?: (e: Event) => void;
  data_value?: number;
  name?: string;
};

const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
`;
const BoxCheckboxLabel = styled.label`
  position: relative;
  display: flex;
  > input {
    display: none;
  }
`;

const CheckBoxStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  background-color: ${palette.white};
  border: 1px solid ${palette.gray};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${palette.darkBlue};
  }
`;

const CheckboxInput = styled.input`
  &:checked + ${CheckBoxStyled} {
    background-color: ${palette.blue};
    border-color: ${palette.blue};

    &:hover {
      background-color: ${palette.darkBlue};
      border-color: ${palette.darkBlue};
    }
  }
  &:checked:disabled + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }
  &:checked:disabled:hover + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }

  &:disabled + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }
`;

const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  disabled = false,
  onClick,
  data_value,
  name,
}) => {
  return (
    <WrapperStyled>
      <BoxCheckboxLabel>
        <CheckboxInput
          data-value={data_value}
          onChange={onChange}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onClick={onClick}
          name={name}
        />
        <CheckBoxStyled>{checked && <Icon name="whiteOK" />}</CheckBoxStyled>
      </BoxCheckboxLabel>
    </WrapperStyled>
  );
};

export default Checkbox;
