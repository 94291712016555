import React from "react";

const Pause = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        fill="#FFD600"
        fill-opacity="0.1"
        stroke="#FFD600"
      />
      <path
        d="M23.0625 11.8125H20.5312C20.2206 11.8125 19.9688 12.0643 19.9688 12.375V23.625C19.9688 23.9357 20.2206 24.1875 20.5312 24.1875H23.0625C23.3732 24.1875 23.625 23.9357 23.625 23.625V12.375C23.625 12.0643 23.3732 11.8125 23.0625 11.8125Z"
        stroke="#FFD600"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4688 11.8125H12.9375C12.6268 11.8125 12.375 12.0643 12.375 12.375V23.625C12.375 23.9357 12.6268 24.1875 12.9375 24.1875H15.4688C15.7794 24.1875 16.0312 23.9357 16.0312 23.625V12.375C16.0312 12.0643 15.7794 11.8125 15.4688 11.8125Z"
        stroke="#FFD600"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Pause;
