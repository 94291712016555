import { Action } from "redux";
import { Task, Project } from "../../common/types";

export enum types {
  CHANGE_TASK_LIST = "@@PROJECT/CHANGE_TASK_LIST",
  FETCH_TASKS = "@@PROJECT/FETCH_TASKS",
  SELECT_PROJECT = "@@PROJECT/SELECT_PROJECT",
  SET_PROJECT_ADD_MODE = "@@PROJECTS/SET_PROJECT_ADD_MODE",
  SET_PROJECT_SECTION_ADD_MODE = "@@PROJECTS/SET_PROJECT_SECTION_ADD_MODE",
  SET_IS_FETCHING_TASKS = "@@PROJECTS/SET_IS_FETCHING_TASKS",
  SET_TABLE_FILTER = "@@PROJECTS/SET_TABLE_FILTER",
  SET_STATUS_FILTER = "@@PROJECTS/SET_STATUS_FILTER",
  SET_TABLE_ORDER = "@@PROJECTS/SET_TABLE_ORDER",
  SET_FILTER_WIN_PROJECTS_MODE = "@@TASKS/SET_FILTER_WIN_PROJECTS_MODE",
  SET_FILTERS_PROJECTS = "@@TASKS/SET_FILTERS_PROJECTS",
  SET_DRAFT_VALUE_PROJECTS = "@@TASKS/SET_DRAFT_VALUE_PROJECTS",
  SET_FILTERS_ADD_NEW_ROW_PROJECTS = "@@TASKS/SET_FILTERS_ADD_NEW_ROW_PROJECTS",
  SET_ALL_OR_ACTUAL_PROJECTS = "@@TASKS/SET_ALL_OR_ACTUAL_PROJECTS",
  SET_SHOW_COLUMNS_WINDOW_PROJECTS = "@@TASKS/SET_SHOW_COLUMNS_WINDOW_PROJECTS",
  SET_SHOWING_COLS_PROJECTS = "@@TASKS/SET_SHOWING_COLS_PROJECTS",
  SET_TABLE_OR_TREE_TOGGLER = "@@PROJECTS/SET_TABLE_OR_TREE_TOGGLER",
  BUSINESS_PROCESSES_MODAL_SHOW = "@@PROJECTS/BUSINESS_PROCESSES_MODAL_SHOW",
}

export interface businessProcessesModalShowAction
  extends Action<types.BUSINESS_PROCESSES_MODAL_SHOW> {
  show: boolean;
}

export interface fetchTasksAction extends Action<types.FETCH_TASKS> {
  projectId: number;
  pageNum: number;
  abortController?: AbortController;
}

export interface changeTaskListAction extends Action<types.CHANGE_TASK_LIST> {
  tasks: Task[];
}

export interface selectProjectAction extends Action<types.SELECT_PROJECT> {
  project: Project | null;
}

export interface setProjectAddModeAction
  extends Action<types.SET_PROJECT_ADD_MODE> {
  mode: boolean;
}
export interface setProjectSectionAddModeAction
  extends Action<types.SET_PROJECT_SECTION_ADD_MODE> {
  mode: boolean;
}

export interface setIsFetchingTasksAction
  extends Action<types.SET_IS_FETCHING_TASKS> {
  isFetchingTasks: boolean;
}

export interface setTableFilterAction extends Action<types.SET_TABLE_FILTER> {
  filter: string;
}

export interface setTableOrderAction extends Action<types.SET_TABLE_ORDER> {
  col_name: string;
  order_direct: boolean;
  customType?: string;
}

export interface setFilterAddModeAction
  extends Action<types.SET_FILTER_WIN_PROJECTS_MODE> {
  mode: boolean;
}

export interface setStatusFilterAction
  extends Action<types.SET_FILTERS_PROJECTS> {
  filterObject: object;
}

export interface setFilterDraftAction
  extends Action<types.SET_DRAFT_VALUE_PROJECTS> {
  draft: number;
}

export interface setFilterAddNewRow
  extends Action<types.SET_FILTERS_ADD_NEW_ROW_PROJECTS> {
  value: boolean;
}

export interface setAllOrActualAction
  extends Action<types.SET_ALL_OR_ACTUAL_PROJECTS> {
  value: object;
}

export interface setShowColumnsCustomAction
  extends Action<types.SET_SHOW_COLUMNS_WINDOW_PROJECTS> {
  show: boolean;
}

export interface setShowableColumns
  extends Action<types.SET_SHOWING_COLS_PROJECTS> {
  value: string;
}

export interface tableOrTreeTogglerAction
  extends Action<types.SET_TABLE_OR_TREE_TOGGLER> {
  value: string;
}

export type ProjectsAction =
  | fetchTasksAction
  | changeTaskListAction
  | selectProjectAction
  | setProjectAddModeAction
  | setProjectSectionAddModeAction
  | setIsFetchingTasksAction
  | setTableFilterAction
  | setStatusFilterAction
  | setTableOrderAction
  | setFilterAddModeAction
  | setFilterDraftAction
  | setAllOrActualAction
  | setFilterAddNewRow
  | setShowColumnsCustomAction
  | setShowableColumns
  | businessProcessesModalShowAction
  | tableOrTreeTogglerAction;
