import { getUsers, getProjects } from "../../common/selectors";
import { createSelector } from "reselect";
import { Task, Item } from "../../common/types";
import { State } from "../../rootReducer";
import { getNewTaskSectionId } from "../NewTask/selectors";

export const getSelectedTask = (state: State): Task | null =>
  state.taskInfoPlate.selectedTask;
export const getTaskInfoActiveTab = (state: State): string =>
  state.taskInfoPlate.taskInfoActiveTab;

const getProjectSections = (state: State) =>
  state.taskInfoPlate.projectSections;
const getProjectId = (state: State) => state.taskInfoPlate.project_id;
const getProjectSectionId = (state: State) =>
  state.taskInfoPlate.project_section_id;
const get_current_user = (state: State) => state.commonInfo.current_user;

export const getUsersSelectList = createSelector(
  [getUsers, getSelectedTask, get_current_user],
  (users, selectedTask, current_user) => {
    let executor, author;
    let list = users.map((user) => {
      let listElem = {
        text: `${user.surname} ${user.name}`,
        value: user.id,
        urlImage: user?.image?.url,
      };

      if (selectedTask && user.id === selectedTask?.author_id)
        author = Object.assign({}, listElem);
      else if (current_user && user.id === current_user && !selectedTask) {
        author = Object.assign({}, listElem);
      }

      // if (user.id === selectedTask?.executor_id) executor = Object.assign({}, listElem)

      return listElem;
    });
    return { author, executor, list };
  }
);

export const getProjectsSelectList = createSelector(
  [getProjects, getSelectedTask, getProjectId],
  (projects, selectedTask, ProjectId) => {
    let selected: Item | null = null;
    let list = projects.map((project) => {
      let listElem = {
        text: project.name,
        value: project.id,
      };
      if (selectedTask && project.id === selectedTask?.project_id)
        selected = listElem;
      else if (ProjectId && project.id === ProjectId) selected = listElem; // new task

      return listElem;
    });

    return { selected, list };
  }
);

export const getSectionSelectList = createSelector(
  [getSelectedTask, getProjectSections, getProjectSectionId],
  (selectedTask, sections, ProjectSectionId) => {
    const sectionId = selectedTask?.project_section_id;

    let selected = null as Item | null;
    const list = sections.map((section) => {
      let item = {
        text: section.name,
        value: section.id,
      };
      if (sectionId && section.id === sectionId) {
        selected = item;
      } else if (ProjectSectionId && section.id === ProjectSectionId) {
        selected = item;
      }
      return item;
    });
    return { selected, list };
  }
);
