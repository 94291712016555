import React, { useEffect, useLayoutEffect, useState } from "react";
import Calendar from "react-calendar";
import "./style.sass";
import styled from "styled-components";
import { getWeek } from "date-fns";
import {
  AVERAGE_PRIORITY,
  HIGH_PRIORITY,
  SHORT_PRIORITY,
  URGENT_PRIORITY,
} from "../../helpers";

const StyledCalendar = styled(Calendar)`
  font-family: sans-serif;
  position: relative !important;
  height: ${({ switchContent }) =>
    !switchContent ? "50px !important" : "min-content !important"};
}
  .react-calendar__navigation {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #292a34;

    margin-bottom: 1rem;

    button {
      border: none;
      background: none;
      font-size: 1.5rem;
      color: #333;
      cursor: pointer;
    }
  }
  .react-calendar__navigation__label {
    // pointer-events: none;
  }
  .react-calendar__navigation__prev2-button {
    display: none !important;
  }
  .react-calendar__navigation__next2-button {
    display: none !important;
  }
  .react-calendar__viewContainer {
    visibility: ${({ switchContent }) =>
      !switchContent ? "hidden" : "visible"};
  }
  .react-calendar__navigation__arrow{
    display: ${({ switchContent }) => (!switchContent ? "none" : "block")};
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    gap: 6px;

    abbr[title] {
      text-decoration: none;
      border-bottom: 1px dotted #ccc;
      cursor: help;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #7f7f7f;
  }
  .react-calendar__month-view__weekdays__weekday {
    color: #7f7f7f;
    align-self: flex-start;
    flex: none !important;
    width: 37px;
    margin-top: 20px;
  }

  .react-calendar__month-view__days__day {
    border-radius: 50%;
    flex: none !important;
    overflow: visible !important;
    width: 37px;
    height: 37px;
    margin-top: 17px;
  }

  .react-calendar__month-view__days {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    gap: 6px;
    min-width: 300px;
   
    .react-calendar__tile {
      padding: 0.5rem;
      background: none;
      font-weight: 400;
      font-size: 16px;

      abbr[title] {
        text-decoration: none;
        border-bottom: none;
        cursor: default;
      }

      &:hover {
        background: #eee;
      }

      &.react-calendar__tile--now {
        background: #ddd;
      }

      &.react-calendar__tile--active {
        background: #02a7f0;
        color: #fff;
        border-radius: 50%;

        &:hover {
          background: #555;
        }
      }

      &.react-calendar__tile--weekend {
        background: #f5f5f5;
      }

      &.react-calendar__navigation {
        display: none;
      }

      &.react-calendar__tile--hasActive {
        background: #555;
        color: #fff;

        &:hover {
          background: #777;
        }
      }
    }
  }
`;

const StyledTile = styled.div`
  position: relative;
  padding: 10px;
  &.react-calendar__tile--neighboringMonth {
    color: gray;
    background-color: #eee;
  }
`;
const StyledNavigationLabel = styled.span`
  &.react-calendar__navigation__label {
    font-weight: bold;
  }
`;
interface Props {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  currentView: "day" | "week";
  setCurrentView: (mode: "day" | "week") => void;
  switchContent: boolean;
  setSwitchContent: (mode: boolean) => void;
  events: any;
  filter: any;
}
const scheduler = window.scheduler;
const MiniCalendar: React.FC<Props> = ({
  selectedDate,
  setSelectedDate,
  currentView,
  setCurrentView,
  switchContent,
  setSwitchContent,
  events,
  filter,
}) => {
  const [activeClass, setActiveClass] = useState("");

  const [filteredEvents, setFilteredEvents] = useState([...events]);

  function onChange(date) {
    setSelectedDate(date);
    setCurrentView("day");
    scheduler.setCurrentView(selectedDate, "day");
  }
  const formatNavigationLabel = (date) => {
    return date.toLocaleDateString("ru-RU", { month: "long", year: "numeric" });
  };
  const tileClassName = ({ date, view }) => {
    const week = getWeek(selectedDate);
    const tileWeek = getWeek(date);

    if (tileWeek === week) {
      return "week-start";
    }

    return "";
  };
  const onPrevMonthClick = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(selectedDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const onNextMonthClick = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(selectedDate.getMonth() + 1);
    setSelectedDate(newDate);
  };
  useEffect(() => {
    setFilteredEvents(() => {
      return events.filter((item) => {
        if (item.entity.type == "EVENT_TASK_TYPE" && filter.tasks == true) {
          if (item.meta.is_cyclic == true && filter.hideEveryDay == true) {
            return false;
          }
          if (item.meta.is_done == true && filter.hideDone == true) {
            return false;
          }
          if (item.meta.is_completed == true && filter.hideCompleted == true) {
            return false;
          }
          return item;
        } else if (item.entity.type == "TASK_TYPE" && filter.tasks == true) {
          return item;
        } else if (item.entity.type == "EVENT_TYPE" && filter.events == true) {
          return item;
        } else if (
          item.entity.type == "MEETING_TYPE" &&
          filter.meetings == true
        ) {
          return item;
        }
      });
    });
  }, [filter, events]);

  return (
    <>
      <div className="scheduler-date-box-month">
        <>
          <div className="scheduler-prev-month" onClick={onPrevMonthClick}>
            <svg
              width="9"
              height="16"
              viewBox="0 0 7 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 0L-1.8677e-07 7L7 14L7 0Z"
                fill="#292A34"
              />
            </svg>
          </div>
          <div className="scheduler-date-month">
            {formatNavigationLabel(selectedDate)}
          </div>
          <div className="scheduler-next-month" onClick={onNextMonthClick}>
            <svg
              width="9"
              height="16"
              viewBox="0 0 7 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 14L7 7L1.66948e-07 0L0 14Z"
                fill="#292A34"
              />
            </svg>
          </div>
        </>
      </div>
      <StyledCalendar
        className={"mini_calendar"}
        switchContent={switchContent}
        onChange={onChange}
        value={selectedDate}
        activeStartDate={selectedDate}
        tileContent={({ date, view }) => {
          const result = filteredEvents.filter((item) => {
            if (new Date(item.start_date).getMonth() == date.getMonth()) {
              return new Date(item.start_date).getDate() == date.getDate();
            }
          });

          function getMaxKeyNumber(myArray) {
            if (!Array.isArray(myArray) || myArray.length === 0) {
              return null;
            }

            const maxKeyObj = myArray.reduce((prev, current) => {
              return prev.meta.task_priority_id > current.meta.task_priority_id
                ? prev
                : current;
            });

            return Number(maxKeyObj.meta.task_priority_id);
          }

          const taskType = result.filter(
            (obj) => obj.entity.type === "TASK_TYPE" || obj.entity.type === "EVENT_TASK_TYPE"
          );
          const meet = result.find(
            (item) => item.entity.type == "MEETING_TYPE"
          );
          const event = result.find((item) => item.entity.type == "EVENT_TYPE");

          const taskPriority = () => {
            let css = "";
            if (getMaxKeyNumber(taskType) === URGENT_PRIORITY) {
              css = "red_task";
            } else if (getMaxKeyNumber(taskType) === HIGH_PRIORITY) {
              css = "orange_task";
            } else if (getMaxKeyNumber(taskType) === AVERAGE_PRIORITY) {
              css = "yellow_task";
            } else if (getMaxKeyNumber(taskType) === SHORT_PRIORITY) {
              css = "task";
            }
            return css;
          };

          return (
            <StyledTile>
              <div className="prioritet-container">
                {taskType ? <div className={taskPriority()}></div> : null}
                {meet ? <div className="meet_cube"></div> : null}
                {event ? <div className="event_cube"></div> : null}
              </div>
              {currentView === "week" && date.getDay() === 1 && (
                <div className={tileClassName({ date, view })}>
                  {" "}
                  <div className="week-start-right"></div>
                  <div className="week-start-left"></div>
                </div>
              )}
            </StyledTile>
          );
        }}
        navigationLabel={({ date }) => (
          <StyledNavigationLabel>
            {formatNavigationLabel(selectedDate)}
          </StyledNavigationLabel>
        )}
      />
    </>
  );
};

export default MiniCalendar;
