import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import { State } from "../../../../rootReducer";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  setSelectedUserId,
  changeTaskList,
  fetchTasks,
} from "../../../Tasks/actions";
import { Task } from "../../../../common/types";
import { setFilters } from "../../../../common/actions";
import { fetchData } from "../../../../utils/fetchData";

interface Props {
  setSelectedUserId: (id: number) => void;
  currentId: number;
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  current_set: any;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  setCurrentUser: (id: number | string) => void;
}

const StyledSelect = styled(Select)`
  /* Обертка React Select */
  .react-select-container {
    position: relative;
    width: 100% !important;
  }

  /* Основной блок Select */
  .react-select__control {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    transition: all 0.2s;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    cursor:pointer;
  }

  /* Активный Select */
  .react-select__control:hover {
    box-shadow: none;
    background-color: #e8e8e8;
  }

  /* Скрытие подчеркивания при фокусе на Select */
  .react-select__control--is-focused {
    box-shadow: none;
  }

  /* Скрытие стрелки Select */
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    margin-left: 10px;
    margin-right: 10px;
    color: #292A34;
  }

  .react-select__indicator:hover {
    color: #292A34;
  }

  
  /* Иконка стрелки */
  .react-select__dropdown-indicator  {
   color:"#292A34";  
   position:relative; 
   top:8px;
  }

  .react-select__dropdown-indicator path {
    d: path("M0.5 0L7.5 7L14.5 0L0.5 0Z");
  }

  .react-select__clear-indicator {
    display:none;
  }

  .react-select__indicator:hover {
    color: #292A34;
  }

  /* Разделитель между опциями */
  .react-select__menu-list {
    border-top: 1px solid #e0e0e0;
  }

  /* Опции Select */
  .react-select__option {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }

  /* Активная опция */
  .react-select__option--is-focused {
    background-color: #e8e8e8;
  }

  /* Выбранная опция */
  .react-select__option--is-selected {
    background-color: #d6d6d6;
    color: #212121;
  }
`;

const InputBox = styled.div`
  width: 248px;
  heght: 40px;
  display: flex;
`;
const UsersSelect: React.FC<Props> = ({
  setSelectedUserId,
  currentId,
  changeTaskList,
  fetchTasks,
  current_set,
  setFilters,
  setCurrentUser,
}) => {
  const params = useParams<{ id: string }>();
  let history = useHistory();

  const [users, setUsers] = useState<any>();
  const [userSelect, setUserSelect] = useState<any>();

  const userss = () => {
    fetchData
      .get(`/api/v1/day/employees`)
      .then((users) => setUsers(users))
      .catch((error) => console.error(error));
  };

  const us = users?.map((user) => {
    return {
      value: user.id,
      label: user.name,
    };
  });

  useEffect(() => {
    if (params.id) {
      setSelectedUserId(parseInt(params.id));
    } else {
      setSelectedUserId(currentId);
    }
  }, [currentId, params.id, setSelectedUserId]);

  useEffect(() => {
    fetchData
      .get(`/api/v1/users/${params.id}`)
      .then((item) => setUserSelect({ value: item.id, label: item.name }))
      .catch((error) => console.error(error));
  }, [params.id]);

  useEffect(() => {
    changeTaskList([]);
  }, [params.id, changeTaskList]);

  useEffect(() => {
    fetchTasks(1);
  }, [fetchTasks, params.id]);

  useEffect(() => {
    setFilters("", "", [], [], [], false);
  }, []);

  return (
    <InputBox onClick={() => userss()}>
      <div className="users-select">
        <StyledSelect
          isClearable={true}
          placeholder="Пользователи"
          className="react-select"
          value={userSelect}
          options={us}
          classNamePrefix="react-select"
          onChange={(arg) => {
            if (arg) {
              setCurrentUser(arg.value);
              if (
                current_set.authors.length + current_set.executors.length ===
                1
              ) {
                if (current_set.authors.length) {
                  setFilters(undefined, undefined, undefined, [arg?.value]);
                } else {
                  setFilters(undefined, undefined, [arg?.value]);
                }
              } else {
                setFilters("", "", [arg?.value], [], [2, 3, 10, 12, 14]);
              }
              arg?.value
                ? history.replace(`${arg.value}`)
                : history.replace(`${currentId}`);
            } else {
              setFilters("", "", [], [], [], false);
              history.replace(`${currentId}`);
            }
          }}
        />
      </div>
    </InputBox>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentId: state.commonInfo.current_user,
    current_set: state.commonInfo.filters.current_set,
  };
};

const mapDispatchToProps = {
  setSelectedUserId,
  changeTaskList,
  fetchTasks,
  setFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersSelect);
