import React, { useContext } from "react";
import { StatusContext } from "../../context/status.js";
import BpItemStatus from "../ui/bp-item-status/BpItemStatus";
import BpItemMenu from "../ui/bp-item-menu/BpItemMenu.jsx";
import TasksList from "../tasks-list/TasksList.jsx";
import { useState } from "react";
import { useEffect } from "react";
import infoBp from "../Icons/VectorBp.svg";
import messageBp from "../Icons/icon.chat.svg";
import moment from "moment";

const BpItem = ({ el, redirect }) => {
  const {
    createBpStatus,
    createBpSampleStatus,
    setIdBp,
    openTasks,
    setOpenTasks,
    setOpenMenuBp,
    openMenuTasks,
    openMenuBp,
    setIdCall,
    setOpenMenuTasks,
    selectedBp,
    setSelectedBp,
    bp,
    createBpFromSample,
    setOpenMenuTask,
    users,
    projects,
    setTaskId,
    setShowChatWindow,
    setThisTabs,
    setRedirectedByClick,
    selectColumns,
  } = useContext(StatusContext);

  const [project, setProject] = useState();
  const [projectSection, setProjectSection] = useState({});
  const [statusDisplay, setStatusDisplay] = useState("none");
  const [reg, setReg] = useState(false);

  const openTasksMenu = (e) => {
    if (e.id === openTasks) {
      setOpenTasks("");
      setTaskId();
    } else {
      setOpenTasks(e.id);
    }
  };

  //раскрытие плитки меню в случае перехода к БП из задач через модальное окно

  useEffect(() => {
    if (redirect) {
      setOpenTasks("business-item-" + el.id);
      setSelectedBp(el.id);
      setIdCall(el.id);
      setOpenMenuTasks(false);
    } else {
      if (selectedBp) {
        setOpenTasks("business-item-" + selectedBp);
        setSelectedBp(selectedBp);
        setIdCall(selectedBp);
      }
      setOpenMenuTasks(false);
    }
  }, [redirect]);

  const initiator = users.find((user) => user.id === el.initiator_id);
  const executor = users.find((user) => user.id === el.executors[0]);
  const executor2 = users.find((user) => user.id === el.executors[1]);
  const executor3 = users.find((user) => user.id === el.executors[2]);

  useEffect(() => {
    if (projects)
      setProject((prev) =>
        projects.find((project) => project.id === el.project_id)
      );
  }, [projects, el, el.project_id]);

  useEffect(() => {
    if (project)
      setProjectSection(
        project.sections.find((section) => section.id === el.project_section_id)
      );
  }, [project, projectSection, el, el.project_section_id]);

  let duration = moment.duration(el.duration)._days;

  const durationBp = (days) => {
    if (days >= 2 && days <= 4) {
      return "дня";
    } else if (days === 1) {
      return "день";
    } else {
      return "дней";
    }
  };
  // console.log(el.tasks.length);
  return (
    <div>
    <div
      className={
        createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
          ? "business__main-content__list-block__item business__main-content__list-block__item-active"
          : "business__main-content__list-block__item"
      }
    >
      <div
        id={"business-item-" + el.id}
        onClick={(e) => {
          setIdCall(el.id);
          // setTaskId(null)
          if (el.id) setSelectedBp(el.id);

          // для закрытия меню "запуск с" в BpItemTaskMenu
          // Mount.current = false
          setOpenMenuTask(false);
          openTasksMenu(e.currentTarget);
          setTaskId();
          setReg(!reg);
        }}
      >
        <div className="business__main-content__list-block__item-left">
          <div
            className={
              createBpStatus ||
              createBpSampleStatus ||
              openMenuTasks ||
              openMenuBp
                ? "item_left_name_one"
                : "item_left_name"
            }
          >
            <div className="infoBp">
              <img src={infoBp} alt="infoBp" style={{opacity: 0}}/>
              <div
                style={{ marginLeft: "10px" }}
                className={`business__main-content__list-block__item__arrow ${
                  reg && el.id === selectedBp ? "" : "item_id__top-btn__active"
                }`}
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
                  alt="drop"
                  style={
                    el.tasks.length > 0 ? { opacity: 0.5 } : { opacity: 0 }
                  }
                />
              </div>
            </div>
            {selectColumns.includes("id1") && (
              <div className={`item_id `}>{el.id}</div>
            )}
            <div
              className="business__main-content__list-block__item__message business__main-content__list-block__item__message-active"
              id={"business-item-btn-" + 1}
              onClick={(e) => {
                e.stopPropagation();
                setStatusDisplay("none");
                setOpenMenuBp(true);
                setShowChatWindow(true);
                setRedirectedByClick(false);
                setThisTabs(3);
                setSelectedBp(el.id);
                setTaskId();
              }}
              style={{ padding: "10px", display: "flex" }}
            >
              <div style={{ marginRight: "5px" }}>
                <img
                  src={messageBp}
                  alt="message"
                  title="Чат бизнес-процесса"
                />
              </div>
              <span>{el.commentsCount}</span>
              {el.countUnreadComments ? (
                <span className="message_active"></span>
              ) : (
                ""
              )}
            </div>

            {selectColumns.includes("name") && (
              <div
                className={
                  createBpStatus ||
                  createBpSampleStatus ||
                  openMenuTasks ||
                  openMenuBp
                    ? "business__main-content__list-block__item__name_one"
                    : "business__main-content__list-block__item__name"
                }
              >
                <p className="p-black" title={el.name}>
                  {el.name}
                </p>
              </div>
            )}
          </div>
          <div className="item_right_bp">
            {selectColumns.includes("author") && (
              <div style={{width: "56px"}}>
                <div className="business__main-content__list-block__item__logo">
                  {initiator && initiator.image ? (
                    <img
                      title={
                        initiator?.name + " " + initiator?.surname + " "
                        //+ initiator?.patronymic
                      }
                      src={`${process.env.REACT_APP_PUBLIC_URL}/files/${initiator?.image.url}/download`}
                      alt="logo"
                    />
                  ) : (
                    <span title={initiator?.name + " " + initiator?.surname}>
                      {initiator?.name[0] + initiator?.surname[0]}
                    </span>
                  )}
                </div>
              </div>
            )}
            {selectColumns.includes("initiator_id") && (
              <div className="logo_parent">
                {executor ? (
                  <div className="business__main-content__list-block__item__logo logo_task_1">
                    {executor && executor.image ? (
                      <img
                        title={executor?.name + executor?.surname}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/${executor?.image.url}/download`}
                        alt="logo"
                      />
                    ) : (
                      <span title={executor?.name + executor?.surname}>
                        {executor?.name[0] + executor?.surname[0]}
                      </span>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {el.executors.length > 1 && (
                  <div className="business__main-content__list-block__item__logo logo_task_2">
                    {executor2 && executor2.image ? (
                      <img
                        title={executor2?.name + executor2?.surname}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/${executor2?.image.url}/download`}
                        alt="logo"
                      />
                    ) : (
                      <span title={executor2?.name + executor2?.surname}>
                        {executor2?.name[0] + executor2?.surname[0]}
                      </span>
                    )}
                  </div>
                )}
                {el.executors.length > 2 && (
                  <div className="business__main-content__list-block__item__logo logo_task_3">
                    {executor3 && executor3.image ? (
                      <img
                        title={executor3?.name + executor3?.surname}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/${executor3?.image.url}/download`}
                        alt="logo"
                      />
                    ) : (
                      <span title={executor3?.name + executor3?.surname}>
                        {executor3?.name[0] + executor3?.surname[0]}
                      </span>
                    )}
                  </div>
                )}
                {el.executors.length > 3 && (
                  <div
                    style={{ background: "#F4F4F4" }}
                    className="business__main-content__list-block__item__logo logo_task_4 "
                  >
                    <span style={{ color: "rgba(41, 42, 52, 0.5)" }}>
                      +{el.executors.length - 3}
                    </span>
                  </div>
                )}
              </div>
            )}
            {/* </div> */}

            {/* <div className="business__main-content__list-block__item-status"> */}
            {selectColumns.includes("status") && (
              <BpItemStatus
                deadline={el.deadline}
                status={el.status}
                id={el.id}
                workflow={el.workflow_id}
              />
            )}

            {selectColumns.includes("deadline") &&
              (el.tasks.length > 0 ? (
                <p className="business__main-content__list-block__item__deadline p-black">
                  {new Date(el.deadline.replace(new RegExp('-', 'g'), '/')).toLocaleString("ru", {
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              ) : (
                <span className="business__main-content__list-block__item__deadline p-black">
                  __________
                </span>
              ))}
            {selectColumns.includes("duration") && (
              <div style={{ width: "106px" }}>
                {duration + " " + durationBp(duration)}
              </div>
            )}
            {selectColumns.includes("project_id") && (
              <div className="business__main-content__list-block__item__project">
                <p className="p-black project-name">
                  {/* {!openMenuBp &&
                  !createBpStatus &&
                  !createBpSampleStatus &&
                  !openMenuTasks
                    ?  */}
                    {project?.name}
                    {/* // : ""} */}
                </p>
                <span className="p-grey">
                  {/* {!openMenuBp &&
                  !createBpStatus &&
                  !createBpSampleStatus &&
                  !openMenuTasks */}
                    {/* // ?  */}
                   {projectSection?.name}
                    {/* // : "" */}
                </span>
              </div>
            )}
            <div
              className="dropdown-menu__bpItem"
              onClick={(e) => {
                e.stopPropagation();
                setIdBp(+el.id);
                setSelectedBp(+el.id);
                setOpenMenuTask(false);
                setStatusDisplay((prev) =>
                  prev === "none" ? "block" : "none"
                );
                // setTaskId(null);
              }}
            >
              <div
                style={{
                  marginTop: "17px",
                  paddingRight: "10px",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/drop-down-menu.svg`}
                  alt="menu"
                  data-id={el.id}
                  className="img_menu_bp_item"
                />
                <BpItemMenu
                  el={el}
                  id={el.id}
                  status={el.status}
                  statusDisplay={statusDisplay}
                  setStatusDisplay={setStatusDisplay}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedBp(+el.id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* {+el.completion * 100 === 100 ? (
        <div
          className="loading"
          style={{
            width: `${+el.completion * 100}%`,
            borderRadius: "0px 0px 4px 4px",
          }}
        ></div>
      ) : (
        <div
          className="loading"
          style={{
            width: `${+el.completion * 100}%`,
            borderRadius: "0px 0px 0 4px",
          }}
        ></div>
      )} */}
    </div>
    {openTasks === "business-item-" + el.id ? (
      <>
        <TasksList
          tasks={el.tasks}
          projectName={project && project.name ? project.name : ""}
          projectSectionName={
            projectSection && projectSection.name ? projectSection.name : ""
          }
        />
        </>
      ) : (
        <></>
      )}

      {createBpFromSample && bp.id == el.id && openTasks ? (
        <TasksList
          tasks={el.tasks}
          projectName={project && project.name ? project.name : ""}
          projectSectionName={
            projectSection && projectSection.name ? projectSection.name : ""
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default BpItem;
