import React from "react";

interface Props {
  width?: number;
}

const Time: React.FC<Props> = ({ width }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        fill="#01B0E9"
        fill-opacity="0.1"
        stroke="#01B0E9"
      />
      <path
        d="M18 24.75C21.7279 24.75 24.75 21.7279 24.75 18C24.75 14.2721 21.7279 11.25 18 11.25C14.2721 11.25 11.25 14.2721 11.25 18C11.25 21.7279 14.2721 24.75 18 24.75Z"
        stroke="#01B0E9"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18 14.0625V18H21.9375"
        stroke="#01B0E9"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Time;
