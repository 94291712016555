// Аккордеон в разделе "Проекты"
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  AccordionIcon2,
  AccordionIcon4,
  AccordionIcon6,
  AccordionIcon5,
  AccordionIcon7,
} from "../../../img/projectIcons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import ProjectTableMiniHead from "./ProjectAllTableMiniHead";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { fetchData } from "../../../utils/fetchData";
import ProjectSectionTasks from "./ProjectAllSectionTasks";
import { useHistory } from "react-router-dom";
// import { connect } from "react-redux";
// import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import CommonCirclePreloader from "../../../common/components/CommonInfoPreloader/CommonCirclePreloader";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditSection from "./EditSection/EditSection";
import { changeSectionName } from "../../NewProject/actions";
import { formatStringDate } from "../../../common/format";
import {
  selectTask,
  setTaskPriority,
  setShownTaskInfoPlate,
} from "../../TaskInfoPlate/actions";
import { useStateContext } from "../../../useStateContext";
// import { isConditionalExpression } from "typescript";

interface Props {
  projectState: any;
  current_user?: any;
  users?: any;
  currentUserInfo?: any;
  openTasks: any;
  handleSetOpenTasks: (item: any) => void;
  openAccordSelect: boolean;
  changeSectionName: (sectionId: number, name: string) => void;
  priorities: any;
  correctBool: boolean;
  setCorrectBool: (mode: boolean) => void;
}
const DivNumberTasks = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;
const DivRight = styled.div`
  display: flex;
  align-items: center;
`;
const DivLeft = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DivContainerDetails = styled.div`
  /* padding: 0px 19px 21px 21px; */
  display: flex;
  flex-direction: column;
`;

const DivButton = styled.div`
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin: 0 auto;
`;
const Button1 = styled.button`
  letter-spacing: 0.15px;
  color: #01b0e9;
  background: rgba(0, 0, 0, 0);
  font-size: 16px;
  /* transition:all 0.3s;
  :hover {
    color: #008ab6;
  } */
`;
const TextSection = styled.h2`
  box-sizing: border-box;
  display: inline;
  color: #292a34;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: "0",
      border: "1px solid rgba(41, 42, 52, 0.5)",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    ".Mui-expanded": {
      minWidth: "48px",
      border: "1px solid #36EA0",
    },
  },
  MuiAccordionrootNone: {
    "&>.MuiAccordionSummary-root:hover": {
      cursor: "default",
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const ProjecAllSections: React.FC<Props> = ({
  projectState,
  openAccordSelect,
  changeSectionName,
  priorities,
}) => {
  const [tasksState, setTaskState] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idOfTaskToShow, setIdOfTaskToShow] = useState<number>(0);
  const [showSidePlate, setShowSidePlate] = useState<boolean>(true);
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const [nameSection, setNameSection] = useState<string>(projectState.name);
  const [fetchDataZeroLength, setFetchZeroLength] = useState<boolean>(false); //для того чтобы убрать преждевременную надпись о том что постов нет
  const [fetchSectionAllTasksNumber, setFetchSectionAllTasksNumber] =
    useState<any>({
      tasks_count: null,
      tasks_in_work_count: null,
      completed_tasks_count: null,
      overdue_tasks_count: null,
    });

  const {
    projectIdCurrent,
    setProjectIdCurrent,
    projectSectionCurrent,
    setProjectSectionCurrent,
  } = useContext(useStateContext);

  const dispatch = useDispatch();

  const navigate = useHistory();
  const classes = useStyles();

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const btnProject = () => {
    navigate.push(`/projects/${projectState.project_id}`);
  };

  useEffect(() => {
    if (openAccordSelect) {
      fetchData
        .get(`/api/v1/projectsections/${projectState.id}/task-statistics`)
        .then((res) => {
          setFetchSectionAllTasksNumber(res);
        })
        .catch((error) => console.log(error));
    }
  }, [openAccordSelect]);

  const DivIcons2 = styled.p(({ rotate }) => ({
    transform: rotate && "rotate(90deg)",
    width: "10px",
    height: "15px",
  }));

  const handleClick = (expanded) => {
    if (expanded && fetchSectionAllTasksNumber.tasks_count) {
      fetchData
        .get(`/api/v1/tasks?project_section_id=${projectState.id}&limit=3`)
        .then((res) => {
          if (res && res.length > 0) {
            res.forEach((item, index, array) => {
              let begin = item.begin;
              let end = item.end;
              item.begin = formatStringDate(begin);
              item.end = formatStringDate(end);
            });
            setTaskState(res);
          }
          if (!res.length) {
            setFetchZeroLength(false);
          } else if (res.length) {
            setFetchZeroLength(true);
            setOpenAccord(expanded);
          }
        })
        .catch((error) => console.log(error));
    } else {
      setOpenAccord(false);
      setFetchZeroLength(false);
    }
    fetchData
      .get(`/api/v1/tasks?project_section_id=${projectState.id}&limit=3`)
      .then((res) => {
        if (res && res.length > 0) {
          res.forEach((item, index, array) => {
            let begin = item.begin;
            let end = item.end;
            item.begin = formatStringDate(begin);
            item.end = formatStringDate(end);
          });
          setTaskState(res);
        }
        if (!res.length) {
          setFetchZeroLength(false);
        } else if (res.length) {
          setFetchZeroLength(true);
          setOpenAccord(expanded);
        }
      })
      .catch((error) => console.log(error));
  };

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setProjectIdCurrent(() => projectState.project_id);
    setProjectSectionCurrent(() => projectState.id);
    let priority =
      priorities.find((el) => el.slug === "medium") || priorities[0];
    dispatch(setTaskPriority(priority.id));
    dispatch(setShownTaskInfoPlate(true));
    handleCloseMenu(e);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <>
      {openWindow && (
        <EditSection
          openAddWindow={openWindow}
          setOpenAddWindow={setOpenWindow}
          addHandler={changeSectionName}
          valueQuestions={nameSection}
          setValueQuestions={setNameSection}
          id={projectState.id}
        />
      )}
      <Accordion
        expanded={openAccord}
        onChange={(e, expanded) => {
          handleClick(expanded);
        }}
        classes={
          fetchSectionAllTasksNumber.tasks_count
            ? {
                root: classes.MuiAccordionroot,
              }
            : {
                root: classes.MuiAccordionrootNone,
              }
        }
        style={{
          width: "100%",
          boxShadow: "none",
          marginBottom: "10px",
          borderRadius: "4px 4px 0px 0px",
        }}
      >
        <AccordionSummary
          disableTouchRipple={!tasksState.length}
          classes={{
            root: classes.MuiAccordionSym,
          }}
          style={{
            height: "46px",
            overflow: "hidden",
            border: "1px solid #dbdbdb",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 12px",
            minHeight: "46px",
          }}
        >
          <DivLeft>
            <div style={{ paddingLeft: "15px", width: "70px" }}>
              {!!fetchSectionAllTasksNumber.tasks_count ? (
                <DivIcons2 rotate={openAccord}>
                  <AccordionIcon2 />
                </DivIcons2>
              ) : (
                <DivIcons2 style={{ opacity: "0" }}></DivIcons2>
              )}
            </div>
            <TextSection>{projectState?.name}</TextSection>
          </DivLeft>
          <DivRight>
            <div
              style={{
                paddingLeft: "20px",
                minWidth: "170px",
              }}
            >
              <p>
                {fetchSectionAllTasksNumber?.tasks_count !== null ? (
                  fetchSectionAllTasksNumber?.tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </p>
            </div>
            <DivNumberTasks>
              <AccordionIcon4 />
              <p style={{ marginLeft: "6px", color: "#436ea0" }}>
                {fetchSectionAllTasksNumber?.tasks_in_work_count !== null &&
                fetchSectionAllTasksNumber?.overdue_tasks_count !== null ? (
                  fetchSectionAllTasksNumber?.tasks_in_work_count -
                  fetchSectionAllTasksNumber?.overdue_tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </p>
            </DivNumberTasks>
            <DivNumberTasks>
              <AccordionIcon5 />
              <p style={{ marginLeft: "6px", color: "#44a047" }}>
                {fetchSectionAllTasksNumber?.completed_tasks_count !== null ? (
                  fetchSectionAllTasksNumber?.completed_tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </p>
            </DivNumberTasks>
            <DivNumberTasks>
              <AccordionIcon6 />
              <p style={{ marginLeft: "6px", color: "#f44336" }}>
                {fetchSectionAllTasksNumber?.overdue_tasks_count !== null ? (
                  fetchSectionAllTasksNumber.overdue_tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </p>
            </DivNumberTasks>
            <Button onClick={handleClickMenu} style={{ minWidth: "53px" }}>
              <AccordionIcon7 />
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              style={{ marginTop: "45px" }}
            >
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu(e);
                  setOpenWindow(!openWindow);
                }}
              >
                Редактировать
              </MenuItem>
              <MenuItem onClick={(e) => clickHandler(e)}>
                Создать задачу
              </MenuItem>
            </Menu>
          </DivRight>
        </AccordionSummary>
        {fetchDataZeroLength && !!tasksState.length && (
          <AccordionDetails
            style={{
              backgroundColor: "#f4f4f4",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!!tasksState.length ? (
              <>
                <ProjectTableMiniHead />
                <DivContainerDetails>
                  {tasksState?.slice(0, 3).map((item) => (
                    <ProjectSectionTasks
                      idOfTaskToShow={idOfTaskToShow}
                      setIdOfTaskToShow={setIdOfTaskToShow}
                      setShowSidePlate={setShowSidePlate}
                      task={item}
                      key={item.id}
                    />
                  ))}

                  {fetchSectionAllTasksNumber?.tasks_count > 3 && (
                    <DivButton>
                      <Button1 onClick={btnProject}>Все задачи</Button1>
                      <ArrowForwardIosIcon
                        style={{
                          color: "#01b0e9",
                          width: "17px",
                          paddingTop: "2px",
                        }}
                      />
                    </DivButton>
                  )}
                </DivContainerDetails>
              </>
            ) : null}
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    current_user: state.commonInfo.current_user,
    currentUserInfo: state.commonInfo.currentUserInfo,
    sections: state.newProject.sections,
    priorities: state.commonInfo.priorities,
  };
};

const mapDispatchToProps = {
  changeSectionName,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjecAllSections);
