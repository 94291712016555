import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { connect } from "react-redux";
import Input from "../../../common/components/Inputs/Input";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import { State } from "../../../rootReducer";
import { getUsers } from "../../../common/selectors";
import { User, Item } from "../../../common/types";
import {
  saveDep,
  saveGroup,
  setDepGroupType,
  showWindowCreateDepGroup,
} from "../actions";
import Button from "../../../common/components/newComponent/Buttons/Button";
// import ColumnsCheckboxItem from '../../../common/components/ColumnsCheckboxItem/ColumnsCheckboxItem';
// import Search from '../../../common/components/Inputs/Search';

type Props = {
  users: User[];
  isCreatingDep: boolean;
  plateType: string | null;
  setShow: (value: string | null) => void;
  saveDep: (name: string, head_id: number, parent_id: number | null) => void;
  saveGroup: (name: string, head_id: number) => void;
  setDepGroupType: (value: string | null) => void;
  showWindowCreateDepGroup: (val: boolean) => void;
  selectedDepGroupId: number | null;
};

const AddDepPlate: React.FC<Props> = ({
  users,
  showWindowCreateDepGroup,
  setDepGroupType,
  isCreatingDep,
  plateType,
  setShow,
  saveDep,
  saveGroup,
  selectedDepGroupId,
}) => {
  const [name, setName] = useState<string>("");
  const [headId, setHead] = useState<number | null>(null);
  const [parent_id, setParent_id] = useState<number | null>(null);

  useEffect(() => {
    setParent_id((prev) => selectedDepGroupId);
  }, [selectedDepGroupId]);

  console.log("=========================", parent_id);

  const selectInfo = useMemo(() => {
    let selected: Item | null = null;
    // если сейчас департаменты, то выбирать 5ю роль. Если группы, то 6ю.
    let list = users
      .filter((user) => user.roles.includes(plateType === "deps" ? 5 : 6))
      .map((user) => {
        let item = { text: `${user.surname} ${user.name}`, value: user.id };
        if (user.id === headId) selected = item;
        return item;
      });
    return { selected, list };
  }, [users, headId]);

  const saveDepHandler = useCallback(() => {
    if (!name || !headId) return;
    if (plateType === "deps") {
      saveDep(name, headId, parent_id);
    } else if (plateType === "groups") {
      saveGroup(name, headId);
    }
    // setDepGroupType(null)
  }, [headId, name, plateType, saveDep, saveGroup]);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let element = e.target as Node;
      if (element === plate.current) {
        setShow(null);
        showWindowCreateDepGroup(false);
      }
    },
    [setShow]
  );
  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  // self-close for window

  // useEffect(() => {
  //     document.addEventListener('click', outsideClickHandler)
  //     return () => document.removeEventListener('click', outsideClickHandler)
  // }, [outsideClickHandler])

  // const checkboxData = useMemo(() => {
  //     let fltr = filter.toLocaleLowerCase()
  //     return users.filter(user => user.name.toLowerCase().match(fltr) || user.surname.toLowerCase().match(fltr))
  // }, [filter, users])

  return (
    <div className="add_pannel_wrapper" ref={plate} style={{zIndex:10001}}>
      <div className={`add_pannel add_dep_plate in_center`}>
        <div className={"main_header"}>
          <div className="main_title">
            {plateType === "deps" ? "Новый отдел" : "Новая группа"}
          </div>
          <div
            className={"close_button"}
            onClick={() => {
              setShow(null);
              showWindowCreateDepGroup(false);
            }}
          />
        </div>

        {/*// <div ref={plate} className="add_dep_plate">*/}
        {/*<h1 className="main_title">{plateType === 'deps' ? 'Новый отдел' : 'Новая группа'}</h1>*/}

        <div className={"header"}>Название</div>
        <Input
          inputType="text"
          placeholder=""
          title=""
          value={name}
          changeHandler={setName}
        />

        <div className={"header"}>Руководитель</div>
        <SelectWithSecondaryBtn
          list={selectInfo.list}
          selected={selectInfo.selected}
          selectHandler={(value) => {
            setHead(value as number);
          }}
        />
        {/* <span>Выберите сотрудников</span>
            <Search value={filter} placeholder="Поиск сотрудников" changeHandler={fitlerChangeHandler}/>
            <div className="users_list">
                {checkboxData.map(user => (
                    <ColumnsCheckboxItem
                        text={`${user.surname} ${user.name}`}
                        key={user.id}
                        value={user.id}
                        checked={checkedUsers.includes(user.id)}
                        addHandler={(value) => setUsers([...checkedUsers, value])}
                        removeHandler={(value) => setUsers(checkedUsers.filter(id => id !== value))}
                    />
                ))}
            </div> */}
        <Button
          title="Сохранить"
          onClick={saveDepHandler}
          isSending={isCreatingDep}
          disabled={!name || !headId}
          big
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  users: getUsers(state),
  isCreatingDep: state.usersPage.isCreatingDep,
  plateType: state.usersPage.depGroupPlateType,
  selectedDepGroupId: state.usersPage.selectedDepGroupId,
});

const mapDispatchToProps = {
  saveDep,
  saveGroup,
  setDepGroupType,
  showWindowCreateDepGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDepPlate);
