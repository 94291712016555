import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import TableSearch from "../../../common/components/TableSearch/TableSearch";
import { State } from "../../../rootReducer";
import {
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
} from "../actions";
import { connect } from "react-redux";
import {
  fetchTasksAction,
  setTableFilterAction,
  changeTaskListAction,
} from "../action_types";
import { Task } from "../../../common/types";

import DetailsRoundedIcon from "@material-ui/icons/DetailsRounded";
import ChangeHistoryRoundedIcon from "@material-ui/icons/ChangeHistoryRounded";
// import { columnsOfTables } from "../../../common/shared_data";
import Icon from "../../../common/components/Icon";
import styled from "styled-components";
import SortIcon from "../../../common/components/SortIcon";

type Props = {
  tableFilter: string;
  projectId: number | null;
  setTableFilter: (filter: string) => setTableFilterAction;
  changeTaskList: (tasks: Task[]) => changeTaskListAction;
  fetchTasks: (projecId: number, pageNum: number) => fetchTasksAction;
  setOrder: any;
  activeColumns: string[];
};

const WrapperTasksTableHead = styled.div`
  /* max-width: 1280px; */
  overflow: hidden;
  width: 100%;
  padding: 0px 5px 0px 40px;
  margin-right: ${({ isScroll }) => isScroll && "8px"};
  display: flex;
  height: 69px;
  justify-content: space-between;
`;

const BlockStyled = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const HeaderItemName = styled.div`
  cursor: pointer;
  padding-left: 4px;
  & > span {
    position: relative;
  }
`;

const columnsOfTables = [
  {
    columnId: "id",
    showingText: "№",
    style: { minWidth: "110px", paddingLeft: "38px" },
  },
  {
    columnId: "nameTask",
    showingText: "Задача",
    style: { minWidth: "65%", maxWidth: "800px", marginLeft: "11px" },
  },
  {
    columnId: "author_id",
    showingText: "Автор",
    style: { width: "80px", paddingLeft: "10px" },
  },
  {
    columnId: "executor_id",
    showingText: "Исполнитель",
    style: {
      width: "100px",
      marginRight: "15px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  {
    columnId: "status",
    showingText: "Cтатус",
    style: { minWidth: "175px", paddingLeft: "20px" },
  },
  {
    columnId: "end",
    showingText: "Дедлайн",
    style: { minWidth: "100px" },
  },
  {
    columnId: "task_load",
    showingText: "Загрузка",
    style: { minWidth: "98px" },
  },
  {
    columnId: "priority_id",
    showingText: "Приоритет",
    style: { minWidth: "100px" },
  },
];

const ProjectAllTableMiniHead: React.FC<Props> = ({
  tableFilter,
  activeColumns,
  projectId,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
}) => {
  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  const [Clicked, setClicked] = useState<any>({ col: "id", count: 1 });

  const searchChangeHandler = useCallback(
    (filter: string) => {
      if (!projectId) return;
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          changeTaskList([]);
          fetchTasks(projectId, 1);
        }, 500)
      );
    },
    [changeTaskList, fetchTasks, projectId, setTableFilter, timer]
  );

  const firstUpdate = useRef(false);

  return (
    <WrapperTasksTableHead>
      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return (
            (columnsOfTables[key].columnId === "id" ||
              columnsOfTables[key].columnId === "nameTask") && (
              <HeaderItemName
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => {
                  setClicked({
                    col: columnsOfTables[key].columnId,
                    count:
                      Clicked.col === columnsOfTables[key].columnId
                        ? Clicked.count === 2
                          ? 1
                          : Clicked.count + 1
                        : 1,
                  });
                }}
              >
                <span>
                  {columnsOfTables[key].showingText}
                </span>
              </HeaderItemName>
            )
          );
        })}
      </BlockStyled>

      <BlockStyled style={{ marginRight: "50px" }}>
        {Object.keys(columnsOfTables).map((key) => {
          return columnsOfTables[key].columnId === "author_id" ||
            columnsOfTables[key].columnId === "executor_id" ||
            columnsOfTables[key].columnId === "status" ||
            columnsOfTables[key].columnId === "end" ||
            columnsOfTables[key].columnId === "task_load" ||
            columnsOfTables[key].columnId === "priority_id" ? (
            <HeaderItemName
              key={columnsOfTables[key].columnId}
              style={columnsOfTables[key].style}
              onClick={() => {
                setClicked({
                  col: columnsOfTables[key].columnId,
                  count:
                    Clicked.col === columnsOfTables[key].columnId
                      ? Clicked.count === 2
                        ? 1
                        : Clicked.count + 1
                      : 1,
                });
              }}
            >
              <span>
                {columnsOfTables[key].showingText}
              </span>
            </HeaderItemName>
          ) : null;
        })}
      </BlockStyled>
    </WrapperTasksTableHead>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeColumns: state.projectsPage.activeColumns,
    tableFilter: state.projectsPage.tableFilter,
    projectId: state.projectsPage.selectedProject?.id || null,
  };
};

const mapDispatchToProps = {
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAllTableMiniHead);
