import React from "react";

const AccordionIcon2 = () => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.607422 12L6.60742 6L0.607422 0L0.607422 12Z"
        fill="#292A34"
        fill-opacity="0.5"
      />
    </svg>
  );
};

export default AccordionIcon2;
