import moment from "moment";
import "moment/locale/ru";
import { Scheduler, SchedulerStatic } from "dhtmlx-scheduler";
import { fetchData } from "../../utils/fetchData";
import { CreateNotif } from "../../utils/createNotification";
import { convertDate, convertDateToUTC } from "./helpers";

type Colors = "orange" | "green" | "blue" | "violet" | "red";

export const defaultScrollHour = 8;

export const initConfig = (scheduler: SchedulerStatic) => {
  scheduler.clearAll();
  scheduler.config.skin = "material";
  scheduler.config.details_on_create = false;
  scheduler.config.details_on_dblclick = false;
  scheduler.config.lightbox = false;
  scheduler.config.mark_now = true;
  scheduler.config.multi_day = true;
  scheduler.config.all_timed = true;
  scheduler.config.dblclick_create = false;
  scheduler.config.drag_create = false;
  scheduler.config.edit_on_create = false; // выключает inline textarea в ячейке после d&d из таскбара задач
  scheduler.plugins({
    minical: true,
    limit: true,
    all_timed: true,
    tooltip: true,
  });
  scheduler.config.icons_select = [
    "icon_edit",
    "icon_delete"
 ];
  scheduler.i18n.setLocale("ru");
  scheduler.config.hour_size_px = 76;
  scheduler.xy.scale_width = 100;
  scheduler.xy.min_event_height = (15 * 76) / 60; // минимальное время * максимальный размер ячейки / 60 минут
  scheduler.config.scroll_hour = defaultScrollHour; // начальная позиция скрола
  scheduler.config.time_step = 15; // минимальный шаг ивента в минутах
  scheduler.config.day_date =
    "<div class='scale_bar__cell'><div class='scale_bar__label'><span class='scale_label__day'>%D</span> <span class='scale_label__num'>%j</span></div></div>";
  scheduler.templates.day_scale_date = function (date) {
    let dayName = moment(date).locale("ru").format("dd");
    dayName = dayName[0].toUpperCase() + dayName.slice(1);
    const dayNumber = moment(date).locale("ru").format("D");
    return `<div class='scale_bar__cell scale_bar__cell_day'><div class='scale_bar__label'><span class='scale_label__day'>${dayName}</span> <span class='scale_label__num'>${dayNumber}</span></div></div>`;
  };
  
};

export const toSchedulerHeaderColor = (color: Colors) => {
  switch (color) {
    case "orange":
      return "#d14b02";
    case "blue":
      return "#0288d1";
    case "green":
      return "#02d14b";
    case "violet":
      return "#d10288";
    case "red":
      return "#d10220";
    default:
      return "#0288d1";
  }
};

const toSchedulerColor = (
  color: Colors,
  scheduler: SchedulerStatic,
  id: number
) => {
  switch (color) {
    case "orange":
      scheduler.getEvent(id).color = "#d14b02";
      break;
    case "blue":
      scheduler.getEvent(id).color = "#0288d1";
      break;
    case "green":
      scheduler.getEvent(id).color = "#02d14b";
      break;
    case "violet":
      scheduler.getEvent(id).color = "#d10288";
      break;
    case "red":
      scheduler.getEvent(id).color = "#d10220";
      break;
  }
};

export const initSchedulerEvents = (scheduler: SchedulerStatic, id: string) => {
  const logSchedulerEvent = (action: string, event: any, id: number) => {};
  const domainMeet = process.env.REACT_APP_PUBLIC_MEETING;

  const deleteEventId = (id: number, type: string) => {
    if (!id || id.toString().includes('not-exists-on-server-')) {
        return false;
    }
    if (type === "EVENT_TYPE") {
      fetchData
        .delete(`/api/v1/day/events/${id}`)
        .then((ans) => {
        })
        .catch((error) => CreateNotif(error));
    } else if (type === "EVENT_TASK_TYPE") {
      fetchData
        .delete(`/api/v1/day/event-tasks/${id}`)
        .then((ans) => {
        })
        .catch((error) => CreateNotif(error));
    } else if (type === "MEETING_TYPE") {
      fetchData
        .delete(`/api/v1/meetings/${id}`, {}, domainMeet)
        .then((ans) => {
        })
        .catch((error) => CreateNotif(error));
    }
  };

  const eventOriginalQueue = new Map();
  const addLoader = (event) => {
    event['isLoading'] = true;
    scheduler.updateEvent(event.id);
  }

  const removeLoader = (event) => {
    event['isLoading'] = false;
    scheduler.updateEvent(event.id);
    eventOriginalQueue.delete(event.id);
  }

  const cancelChanges = (event) => {
    const originalEvent = eventOriginalQueue.get(event.id);
    if (originalEvent) {
        for (let key in originalEvent) {
            event[key] = originalEvent[key];
        }
    }
    scheduler.updateEvent(event.id);
  }

  const updateEvent = (ev: any, type: string) => {
    addLoader(ev);
    if (type === "EVENT_TYPE") {
      fetchData
        .put(
          `/api/v1/day/events/${ev?.entity?.id}`,
          JSON.stringify({
            title: ev.text,
            description: ev.meta.entityData.description,
            color: ev.meta.entityData.color,
            start_at: convertDateToUTC(ev?.start_date),
            end_at: convertDateToUTC(ev?.end_date),
          })
        )
        .then((ans) => {
          if (!ans) {
            cancelChanges(ev);
          }
        })
        .catch((error) => CreateNotif(error))
        .finally(() => {
          removeLoader(ev);
        });
    } else if (type === "EVENT_TASK_TYPE") {
      fetchData
        .put(
          `/api/v1/day/event-tasks/${ev?.entity?.id}`,
          JSON.stringify({
            owner_id: id,
            task_id: ev.meta.task_id,
            start_at: convertDateToUTC(ev?.start_date),
            end_at: convertDateToUTC(ev?.end_date),
          })
        )
        .then((ans) => {
          if (!ans) {
            cancelChanges(ev);
          }
        })
        .catch((error) => CreateNotif(error))
        .finally(() => {
            removeLoader(ev);
        });
    } else if (type === "MEETING_TYPE") {
      fetchData
        .patch(
          `/api/v1/meetings/${ev?.entity?.id}`,
          JSON.stringify({
            start_at: convertDateToUTC(ev?.start_date),
          }),
          domainMeet
        )
        .then((ans) => {
          if (!ans) {
            cancelChanges(ev);
          }
        })
        .catch((error) => CreateNotif(error))
        .finally(() => {
            removeLoader(ev);
        });
    } else if (type === "TASK_TYPE") {
      fetchData
        .patch(
          `/api/v1/tasks/${ev?.entity?.id}`,
          JSON.stringify({
            end: convertDateToUTC(ev?.end_date)
          })
        )
        .then((ans) => {
          if (!ans) {
            cancelChanges(ev);
          }
        })
        .catch((error) => CreateNotif(error))
        .finally(() => {
          removeLoader(ev);
        });
    }
  };

  const onEventAddedEvent = scheduler.attachEvent(
    "onEventAdded",
    (id: number, ev: any) => {
      const date = moment(ev.start_date).format("dddd");
      if (date === "Saturday" || date === "Sunday") {
        scheduler.deleteEvent(id);
      } else {
        toSchedulerColor(ev.color, scheduler, id);
        logSchedulerEvent("create", ev, id);
      }
    },
    { settings: true }
  );

  const onBeforeEventChanged = scheduler.attachEvent(
      "onBeforeEventChanged",
      (ev, e, is_new, original) => {
          if (original && original.hasOwnProperty('id')) {
              eventOriginalQueue.set(original.id, original);
          }
          return true;
      },
      {settings: true}
  );

  const onEventChangedEvent = scheduler.attachEvent(
    "onEventChanged",
    (id: number, ev: any) => {
      updateEvent(ev, ev?.entity?.type);
    },
    { settings: true }
  );

  const onEventChangedColorEvent = scheduler.attachEvent(
    "onEventChanged",
    (id: number, ev: any) => {
      toSchedulerColor(ev.color, scheduler, id);
      logSchedulerEvent("update", ev, id);
    },
    { settings: true }
  );

  const onEventDeletedEvent = scheduler.attachEvent(
    "onEventDeleted",
    (id: number, ev: any) => {
      deleteEventId(ev?.entity?.id, ev?.entity?.type);
    },
    { settings: true }
  );

  // Выключает inline editor при двойном клике на ячейку
  const onDblClickEvent = scheduler.attachEvent(
    "onDblClick",
    function (id, e) {
      return false;
    },
    { settings: true }
  );

    // Изменяем курсор при перемещении
    let isDragActive = false;
    const onMoveStart = scheduler.attachEvent(
    "onEventDrag",
    function (id, mode, e) {
        if (!isDragActive) {
            if (mode === 'move') {
                document.body.classList.add('day-cursor__move');
            } else if (mode === 'resize') {
                document.body.classList.add('day-cursor__resize');
            }
            isDragActive = true;
        }
        return true;
    },
    { settings: true }
  );
  const onMoveEnd = scheduler.attachEvent(
    "onDragEnd",
    function (id, mode, e) {
        if (mode === 'move') {
            document.body.classList.remove('day-cursor__move');
        } else if (mode === 'resize') {
            document.body.classList.remove('day-cursor__resize');
        }
        isDragActive = false;
        return true;
    },
    { settings: true }
  );

  return {
      events: [
          onEventAddedEvent,
          onBeforeEventChanged,
          onEventChangedEvent,
          onEventChangedColorEvent,
          onEventDeletedEvent,
          onDblClickEvent,
          onMoveStart,
          onMoveEnd,
      ]
  };
};
