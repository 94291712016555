import React from "react";
import {
  useCallback,
} from "react";
//import Button from "@material-ui/core/Button";

import axios from "axios";

import Approved from "../../Icons/ImageWithColor/Approved";
import In_progress from "../../Icons/ImageWithColor/In_progress";
import Finished from "../../Icons/ImageWithColor/Finished";
import Canceled from "../../Icons/ImageWithColor/Canceled";
import { cookieMaster } from "../../../../../utils/CookieMaster";

import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core";

let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;

interface Props {

  openStatus: boolean;
  setOpenStatus(v: boolean): void;
  changeMeeting: boolean;
  setChangeMeeting(v: boolean): void;
  meetingId: number;
  userId: number;
  anchorEl1: null | HTMLElement;
  setAnchorEl1: (mode: null | HTMLElement) => void;
}

const SpanNameStatus = styled.div`
  margin-left: 10px;
`;

const  MeetingStatus: React.FC<Props> = (props: Props) => {
  const {

    openStatus, 
    setOpenStatus,
    changeMeeting,
    setChangeMeeting,

    meetingId,
    userId,
    anchorEl1,
    setAnchorEl1,
  } = props;

  let idMeetingString = localStorage.getItem("meetingId");
  let meetingStatusString = localStorage.getItem("meetingStatus");

  let idMeeting = idMeetingString ? +idMeetingString : 0;
  let meetingStatus = meetingStatusString ? +meetingStatusString : 0;

  const approvedClick = useCallback(
    (idMeeting) => {
      try {
        axios
          .post(
            `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${idMeeting}/agreed`,
            {
              maxBodyLength: Infinity,
            },
            {
              headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: `${cookieMaster.getCookie("access_token")}`,
              },
            }
          )
          .then((res) => {
            setChangeMeeting(!changeMeeting);
          });
      } catch (err) {
        console.log(err);
      }

      console.log("approvedClick");
      setOpenStatus(false);
    },
    [idMeeting]
  );

  const in_progressClick = (idMeeting) => {
    try {
      axios
        .post(
          `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${idMeeting}/run`,
          {
            maxBodyLength: Infinity,
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: `${cookieMaster.getCookie("access_token")}`,
            },
          }
        )
        .then((res) => {
          setChangeMeeting(!changeMeeting);
        });
    } catch (err) {
      console.log(err);
    }

    console.log("in_progressClick");
    setOpenStatus(false);
  };

  const finishedClick = (idMeeting) => {
    try {
      axios
        .post(
          `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${idMeeting}/completed`,
          {
            maxBodyLength: Infinity,
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: `${cookieMaster.getCookie("access_token")}`,
            },
          }
        )
        .then((res) => {
          setChangeMeeting(!changeMeeting);
        });
    } catch (err) {
      console.log(err);
    }

    console.log("finishedClick");
    setOpenStatus(false);
  };

  const canceledClick = (idMeeting) => {
    try {
      axios
        .post(
          `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${idMeeting}/canceled`,
          {
            maxBodyLength: Infinity,
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: `${cookieMaster.getCookie("access_token")}`,
            },
          }
        )
        .then((res) => {
          setChangeMeeting(!changeMeeting);
        });
    } catch (err) {
      console.log(err);
    }

    console.log("canceledClick");
    setOpenStatus(false);
  };



  const closeHandler = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl1(null);
  }, []);

  return (
    <>
      
      <Menu
        anchorEl={anchorEl1}
        keepMounted
        open={Boolean(anchorEl1)}
        onClose={closeHandler}
        style={{marginTop: "55px"}}
      >

        { meetingStatus==0 && (
        <MenuItem
          onClick={(e) => {
           // e.stopPropagation();
            approvedClick(idMeeting);
            closeHandler(e);
          }}
        >
          <Approved props={{}} /> <SpanNameStatus> Cогласованно </SpanNameStatus>
        </MenuItem>   )}


        { meetingStatus==1 && (    
        <MenuItem
          onClick={(e) => {
            //e.stopPropagation();
            in_progressClick(idMeeting);
            closeHandler(e);
          }}
        >
          <In_progress props={{}} /> <SpanNameStatus> В процессе </SpanNameStatus>
        </MenuItem>  )}


        { meetingStatus==2 && (      
        <MenuItem
          onClick={(e) => {
            // e.stopPropagation();
            finishedClick(idMeeting);
            closeHandler(e);
          }}
        >
          <Finished props={{}} /> <SpanNameStatus> Состоялось </SpanNameStatus>
        </MenuItem>  )}

        {/* meetingStatus==3 && (      
        <MenuItem
        >
          <Finished props={{}} /> <SpanNameStatus> Статус состоявшиегося совещания нельзя изменить </SpanNameStatus>
        </MenuItem>  )*/}

        { (meetingStatus==0 || meetingStatus==1 ||  meetingStatus==2) &&    (                 
        <MenuItem
          onClick={(e) => {
            // e.stopPropagation();
            canceledClick(idMeeting);
            closeHandler(e);
          }}
        >
          <Canceled props={{}} /> <SpanNameStatus> Отменено </SpanNameStatus>
        </MenuItem>  )}

        {/* (meetingStatus==4 ) &&    (                 
        <MenuItem
        >
          <Canceled props={{}} /> <SpanNameStatus> Статус отмененного совещания нельзя изменить </SpanNameStatus>
        </MenuItem>  )*/}


      </Menu>
    </>
  );
};

export default MeetingStatus;


