import React from "react";

const NameIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0938 4.6875H3.90625C3.47478 4.6875 3.125 5.03728 3.125 5.46875V19.5312C3.125 19.9627 3.47478 20.3125 3.90625 20.3125H21.0938C21.5252 20.3125 21.875 19.9627 21.875 19.5312V5.46875C21.875 5.03728 21.5252 4.6875 21.0938 4.6875Z"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.42188 9.375H17.5781"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.42188 12.5H17.5781"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.42188 15.625H17.5781"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NameIcon