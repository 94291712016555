// ********************************************************************
// Главное окно для добавления новой задачи или редактирования имеющейся
// (смотреть не в NewTask а тут код описан)
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  setExecutorSchedule,
  fetchProjectSectionsTaskPlate,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setTaskPrivate,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  updateChecklist,
  updateCommentsList,
  clearCyclicTaskToChange,
  setCommentText,
  setLabelsData,
  setTaskBegin,
  setTaskEnd,
} from "./actions";
import { Task, User, Comment, Schedule } from "../../common/types";
import { State } from "../../rootReducer";
import "./styles.sass";
import { updateTask } from "../../common/actions";
import styled from "styled-components";
import Icon from "../../common/components/Icon";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { useStateContext } from "../../useStateContext";
import MainAddTask from "./components/Main";
import { fetchData } from "../../utils/fetchData";
import {timeZone} from "../../common/format";

type Props = {
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  checkList: any[];
  taskInfoActiveTab: string;
  selectedUserId: number;
  taskName: string;
  users: User[];
  commentList: Comment[];
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  setTaskInfoActiveTab: (activeTab: string) => void;
  setProjectUsers: (users: User[]) => void;
  setComentList: (commentList: Comment[]) => void;
  updateTask: (id: number, params: any) => void;
  setExecutorSchedule: (schedule: Schedule) => void;
  fetchProjectSectionsTaskPlate: (projectId: number) => void;
  // changeCheckboxItem: (orderNumber: string | null, text: string | null, checkboxState: boolean) => void,
  rewriteWholeTaskList: (response: any) => void;
  currentUserId: number | undefined;
  currentUserTopRole: number | undefined;
  selectTask: (val: Task | null) => void;
  setTaskName: (name: string) => void;
  setDefaultValues: () => void;
  flagForActionCyclycTask: string;
  rememberObjectCyclicTaskToChange: {};
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setCyclicTaskToChange: (val: {}) => void;
  setFlagRepeatTaskToChange: (val: string) => void;
  clearCyclicTaskToChange: () => void;
  labelsData: {
    comments_count: number;
    files_count: number;
  };
  setLabelsData: (comments_count?: number, files_count?: number) => void;
};

const WrapperTaskInfoPlate = styled.div`
  position: fixed;
  top: 76px;
  right: ${({ isShownTaskInfoPlate }) =>
    isShownTaskInfoPlate ? "0" : "-600px"};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 584px;
  height: calc(100vh - 76px);
`;

const TaskInfoPlate: React.FC<Props> = (props) => {
  const {
    setShownTaskInfoPlate,
    selectedUserId,
    setDefaultValues,
    isShownTaskInfoPlate,
    selectedTask,
    users,
  } = props;

  const {
    checkedPersonalTask,
    setCheckedPersonalTask,
    setCheckedTaskBegin,
    setCheckedTaskEnd,
  } = useContext(useStateContext);

  let plate2 = useRef<HTMLDivElement>(null);
  const [isShowAddObject, setIsShowAddObject] = useState<boolean>(false);

  const [showContextDropDown, setShowContextDropDown] = useState(false);

  // Закрытие окна карточки задачи при клике вне окна
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let Element = e.target as Element;
      if (
        isShownTaskInfoPlate &&
        document.contains(e.target as Node) &&
        !Element.closest(".task_info_plate") &&
        (e.target as HTMLInputElement).type !== "text" &&
        (e.target as HTMLInputElement).type !== "textarea" &&
        (e.target as HTMLInputElement).name !== "search-input" &&
        (e.target as HTMLButtonElement).textContent !== "Добавить" &&
        (e.target as HTMLButtonElement).textContent !== "Сохранить" &&
        (e.target as HTMLButtonElement).textContent !== "Отмена" &&
        (e.target as HTMLButtonElement).textContent !== "OK" &&
        !Element.closest(".MuiButton-label") &&
        !Element.closest(".MuiPickersClock-squareMask") &&
        !Element.closest(".MuiTypography-root") &&
        !Element.closest(".MuiSvgIcon-root") &&
        !Element.closest(".MuiTab-wrapper") &&
        !Element.closest(".MuiButtonBase-root") &&
        !Element.closest(".MuiIconButton-root") &&
        !Element.closest(".MuiPickersCalendarHeader-iconButton") &&
        !Element.closest(".Mui-disabled") &&
        !Element.closest(".dependencies") &&
        !Element.closest("#dependencies") &&
        !Element.closest(".sc-fBxRkM") &&
        !Element.closest(".herfWb") &&
        !Element.closest(".sc-kUbhmq") &&
        !Element.closest(".wCEhK") &&
        !Element.closest(".sc-jtHMlw") &&
        !(e.target as HTMLElement).classList.contains("date") &&
        !(e.target as HTMLElement).classList.contains("time") &&
        !(e.target as HTMLElement).classList.contains("dateTimePicker1") &&
        !(e.target as HTMLElement).classList.contains("dateTimePicker2") &&
        !(e.target as HTMLElement).classList.contains(
          "MuiPickersClock-container"
        ) &&
        !(e.target as HTMLElement).classList.contains("MuiDialog-container") &&
        !(e.target as HTMLElement).classList.contains(
          "MuiDialog-scrollPaper"
        ) &&
        !(e.target as HTMLElement).classList.contains(
          "MuiDialogActions-root"
        ) &&
        !(e.target as HTMLElement).classList.contains(
          "MuiPickersModal-withAdditionalAction"
        ) &&
        !(e.target as HTMLElement).classList.contains(
          "MuiDialogActions-spacing"
        ) &&
        (e.target as HTMLDivElement).textContent?.indexOf("Раздел") === -1 &&
        (e.target as HTMLImageElement).alt !== "delete"
      ) {
        setDefaultValues();
        setShowContextDropDown(false);
        setShownTaskInfoPlate(false);
        if (history.location.pathname.includes("tasks"))
          history.replace(`${selectedUserId}`);
      }
    },
    [isShownTaskInfoPlate]
  );

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  // self-close for window

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes("tasks"))
      document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  return (
    <WrapperTaskInfoPlate
      ref={plate2}
      isShownTaskInfoPlate={isShownTaskInfoPlate}
      className={`task_info_plate`}
    >
      
      <MainAddTask
        isShowAddObject={isShowAddObject}
        setIsShowAddObject={setIsShowAddObject}
      />
    </WrapperTaskInfoPlate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserId: state.commonInfo.currentUserInfo?.id,
    checkList: state.taskInfoPlate.checkList,
    selectedUserId: state.tasksPage.selectedUserId,
    currentUserTopRole:
      state.commonInfo.currentUserInfo?.roles &&
      state.commonInfo.currentUserInfo?.roles[0],
    selectedTask: state.taskInfoPlate.selectedTask,
    taskName: state.taskInfoPlate.name,
    users: state.commonInfo.users,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    commentList: state.taskInfoPlate.commentList,
    labelsData: state.taskInfoPlate.labelsData,
  };
};

const mapDispatchToProps = {
  setTaskPrivate,
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  updateTask,
  setExecutorSchedule,
  fetchProjectSectionsTaskPlate,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  clearCyclicTaskToChange,
  setLabelsData,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TaskInfoPlate);
