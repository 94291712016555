import React from "react";

const Finished = () => {
  return (
<svg 
width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#E0E0E0"/>
<path d="M28.25 14.75L17.75 25.2495L12.5 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default Finished;