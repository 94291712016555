import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainAddTask from "../../../../TaskInfoPlate/components/Main";
import "../../style.sass";
import { Item, Task, User } from "../../../../../common/types";
import { State } from "../../../../../rootReducer";
import { fetchData } from "../../../../../utils/fetchData";
import { selectTask, setProjectUsers } from "../../../../TaskInfoPlate/actions";
import { connect } from "react-redux";
import axios from "axios";
import { CreateNotif } from "../../../../../utils/createNotification";
import { cookieMaster } from "../../../../../utils/CookieMaster";

type Props = {
  selectedTask: Task | null;
  setProjectUsers: (users: User[]) => void;
  users: User[];
  isShowAddObject: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  cellData: any;
  setCellData: (mode: any) => void;
  addEventHandler: (event: any, ans: any) => void;
  deleteSelectedEvent: (id)=>void;
  // selectTask: (task: Task) => void;

};
const scheduler = window.scheduler;
const BlokTask = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  taskInfoPlateBottomRowButtons {
    display: flex;
  }
`;
const AddTaskForm: React.FC<Props> = ({
  selectedTask,
  setProjectUsers,
  users,
  isShowAddObject,
  setIsShowAddObject,
  cellData,
  setCellData,
  addEventHandler,
  deleteSelectedEvent,
}) => {
  const [visibleForm, setVisibleForm] = useState(false);
  let token = cookieMaster.getCookie("access_token");

  useEffect(() => {
    setVisibleForm(true);
  }, [setVisibleForm]);


  return (
    <BlokTask className={"task_info"}>
      <MainAddTask
        visibleForm={visibleForm}
        isShowAddObject={isShowAddObject}
        setIsShowAddObject={setIsShowAddObject}
        cellData={cellData}
        setCellData={setCellData}
        addEventHandler={addEventHandler}
        deleteSelectedEvent={deleteSelectedEvent}
      />
    </BlokTask>
  );
};
const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    users: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  setProjectUsers,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AddTaskForm);
