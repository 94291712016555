import React from "react";

interface Props {
  setIsTaskView: (mode: boolean) => void;
  setIsShowAddObject: (mode: boolean) => void;
}

const MenuHead: React.FC<Props> = ({ setIsTaskView, setIsShowAddObject }) => {
  return (
    <div className="menu__head-buttons">
      <button className="menu__head-button" onClick={() => setIsTaskView(true)}>
        Список задач
      </button>
      <button
        className="menu__head-button"
        onClick={() => setIsShowAddObject(true)}
      >
        Добавить объект
      </button>
    </div>
  );
};

export default MenuHead;
