// Профиль пользователя
import React, {Dispatch, FC, SetStateAction} from "react";
import styled from "styled-components";
import UserSurname from "./UserSurname";
import UserName from "./UserName";
import UserPatronymic from "./UserPatronymic";
import Avatar from "./Avatar";
import UserBirthDay from "./UserBirthDay";
import UserEmail from "./UserEmail";
import UserChangePassword from "./UserChangePasswordButton";
import RoleSelect from "./RoleSelect";
import PositionSelect from "./PositionSelect";
import DepartmentSelect from "./DepartmentSelect";
import DaysProcessing from "./DaysProcessing";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import Button from "../../../common/components/newComponent/Buttons/Button";

import { changeDataUserType, Verification } from "../index";

const TopInformation = styled.div`
  display: flex;
`;

type Props = {
  setChangeDataUser: Dispatch<SetStateAction<changeDataUserType>>;
  userId: number;
  currentUser: number;
  isAddUserInCompany: boolean;
  verification: Verification;
  sendVerificationRequest: () => void;
  changeDataUser: changeDataUserType;
};

const ProfileSection: FC<Props> = ({
    setChangeDataUser,
    userId,
    currentUser,
    isAddUserInCompany,
    verification,
    sendVerificationRequest,
    changeDataUser,
}) => {
  return (
    <>
      <TopInformation>
        <div
          style={{
            width: "100%",
            marginRight: ".5rem",
            marginBottom: "-8px",
          }}
        >
          <UserSurname setChangeDataUser={setChangeDataUser} />
          <UserName setChangeDataUser={setChangeDataUser} />
          <UserPatronymic setChangeDataUser={setChangeDataUser} />
        </div>
        <Avatar setChangeDataUser={setChangeDataUser} />
      </TopInformation>
      <UserBirthDay setChangeDataUser={setChangeDataUser} />
      <UserEmail />
      {/* {userId === 0 && <UserPassword />} */}
      {/* Если текущий пользователь - выводим кнопку смены пароля */}
      {userId === currentUser && <UserChangePassword />}
      <RoleSelect
        title="Роль"
        isAddUserInCompany={isAddUserInCompany}
        setChangeDataUser={setChangeDataUser}
      />
      <PositionSelect
        title="Должность"
        isAddUserInCompany={isAddUserInCompany}
        setChangeDataUser={setChangeDataUser}
      />
      <DepartmentSelect
        title="Отдел"
        isAddUserInCompany={isAddUserInCompany}
        setChangeDataUser={setChangeDataUser}
      />
      <div style={{display:"none"}}>
      <DaysProcessing
        title='Допустимая переработка в день, часов'
        setChangeDataUser={setChangeDataUser}
        changeDataUser={changeDataUser}
        placeholder='Допустимая переработка в день, часов'
        type='hours'
      />
      <DaysProcessing
        title='Допустимое количество дней работы с перегрузкой подряд'
        setChangeDataUser={setChangeDataUser}
        changeDataUser={changeDataUser}
        placeholder='Допустимое количество дней работы с перегрузкой подряд'
        type='days'
      />
      </div>
      {!!!verification.email_verified && (
        <NameWithInput title="Верификация" iconName="check">
          <Button
            title="Отправить повторно"
            design="outline"
            disabled={verification.disabled}
            onClick={sendVerificationRequest}
            big
          />
        </NameWithInput>
      )}
    </>
  );
};

export default ProfileSection;
