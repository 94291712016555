import styled from "styled-components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import Icon from "../../../../common/components/Icon";
import NameIcon from "../../../../common/components/Icon/imgs/name.svg";
import CalendarIcon from "../../../../common/components/Icon/imgs/calendar_blank.svg";
import FormatIcon from "../../../../common/components/Icon/imgs/format.svg";
import QuestionIcon from "../../../../common/components/Icon/imgs/questions.svg";
import CancelIcon from "../../../../common/components/Icon/imgs/cancel.svg";
import UsersIcon from "../../../../common/components/Icon/imgs/users.svg";
import UserIcon from "../../../../common/components/Icon/imgs/filterUser.svg";
import addIcon from "../../../../common/components/Icon/imgs/add.svg";

import { Button, FormControl, Input, Select } from "@material-ui/core";
import axios from "axios";
import { cookieMaster } from "../../../../utils/CookieMaster";
import { nanoid } from "nanoid";
import { AddPanelRequired } from "../../AddPanelRequired";
import { format as Fr } from "date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { CreateNotif } from "../../../../utils/createNotification";
import { convertDate, convertDateToUTC } from "../../../Day/helpers";
import { State } from "../../../../rootReducer";
import { setAddedMeeting } from "../../../../common/actions";
import { connect } from "react-redux";
import { formatToStringUTC } from "../../../../common/format";

// import DatePicker from "../../../../common/components/DatePickers/DateTimePiker";

interface Props {
  addMeeting: (prop: any) => any;
  isShowAddMeeting: boolean;
  setIsShowAddMeeting: (mode: boolean) => void;
  activeMeeting?: any;
  editMeeting: (id: string, props: any) => any;
  isEdit: boolean;
  setIsEdit: (mode: boolean) => void;
  setActiveMeeting: (mode: any) => void;
  users: any;
  meetWithCalendar?: boolean;
  cellData?: any;
  setCellData?: (mode: any) => void;
  addEventHandler?: (event: any, ans: any, type: string) => void;
  isAdded?: boolean;
  setIsShowAddObject?: (mode: boolean) => void;
  addedMeeting: any;
  setAddedMeeting: (mode: any) => void;
}

let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;

const WrapperNewMeeting = styled.div`
  padding: 1rem;
  background: red;
  margin: auto;
  border-radius: 12px;
  transition: transform 1.3s ease;
  transform: ${({ isVisible }) => {
    return isVisible ? "translateY(0vh)" : "translateY(-100vh)";
  }};
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Item = styled.div`
  margin-top: 15px;
`;

const ItemHeader = styled.div`
  display: flex;
  gap: 20px;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #292a34;
`;
const InputBox = styled.div`
  margin: 18px 38px 18px 18px;
  display: flex;
  gap: 20px;
  Input {
    width: 100%;
    padding: 20px 8px 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(41, 42, 52, 0.87);
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px 4px 0px 0px;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  margin-top: 25px;
  padding-left: 100px;
  display: flex;
  gap: 32px;
  Button {
    color: white;
    width: 163px;
    height: 50px;
    background: #01b0e9;
    border: 1px solid #01b0e9;
    borderradius: 4px;
    transition: all 0.3s easy 0s;
  }
  Button:hover {
    color: gray;
  }
`;
const AddUserButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 18px 38px 18px 18px;
  gap: 12px;
  color: rgb(1, 176, 233);
`;
const scheduler = window.scheduler;

const MeetingAddPanel: React.FC<Props> = ({
  addMeeting,
  isShowAddMeeting,
  setIsShowAddMeeting,
  activeMeeting,
  setActiveMeeting,
  editMeeting,
  isEdit,
  setIsEdit,
  users,
  meetWithCalendar,
  cellData,
  addEventHandler,
  setCellData,
  isAdded,
  setIsShowAddObject,
  addedMeeting,
  setAddedMeeting,
}) => {
  let company_id = localStorage.getItem("company_id") || "";
  let token = cookieMaster.getCookie("access_token");

  const [isRequired, setIsRequired] = useState(false);
  const [name, setName] = useState("");
  const [start_at, setStartAt] = useState<any>();
  const [format, setFormat] = useState(0);
  const [location, setLocation] = useState("");
  const [chairman, setChairman] = useState({
    meeting_id: nanoid(),
    value: "",
    user_id: "",
  });
  const [secretary, setSecretary] = useState({
    meeting_id: nanoid(),
    value: "",
    user_id: "",
  });
  const [firstMember, setFirstMember] = useState({
    meeting_id: nanoid(),
    value: "",
    user_id: "",
  });
  const [members, setMembers] = useState<any>([]);
  useEffect(() => {
    const momentLocalFormat = "YYYY-MM-DDTHH:mm:ss";

    if (cellData) {
      if (!cellData.isChange) {
        setStartAt(moment(cellData.start_at).format(momentLocalFormat));
      } else {
        if (cellData.type == "MEETING_TYPE") {
          axios
            .get(
              `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${cellData.meeting_id}`,
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((res) => {
              setActiveMeeting(res.data.data);
              setIsEdit(true);
            })
            .catch((err) => {
              CreateNotif(err);
            });
        }
      }
    }
  }, [cellData]);
  useMemo(() => {
    if (isEdit) {
      const chman = users?.find(
        (item) => item.id == activeMeeting?.chairman?.user_id
      );
      const scrtr = users?.find(
        (item) => item.id == activeMeeting?.secretary?.user_id
      );
      const date = new Date(activeMeeting?.start_at);

      setName(activeMeeting?.name);
      setStartAt(Fr(new Date(activeMeeting?.start_at), "yyyy-MM-dd'T'HH:mm"));
      setFormat(activeMeeting?.format);
      setLocation(activeMeeting?.location ?? "");
      setChairman({
        ...activeMeeting?.chairman,
        value: `${chman?.id} ${chman?.name} ${chman?.surname}`,
      });
      setSecretary({
        ...activeMeeting?.secretary,
        value: `${scrtr?.id} ${scrtr?.name} ${scrtr?.surname}`,
      });
      if (activeMeeting?.members.length) {
        setFirstMember(() => {
          const memberUser = users?.find(
            (item) => item.id == activeMeeting?.members[0]?.user_id
          );

          return {
            ...activeMeeting?.members[0],
            value: `${memberUser?.id} ${memberUser?.name} ${memberUser?.surname}`,
          };
        });
      }
      setMembers([
        ...activeMeeting?.members
          ?.filter((item, i) => i !== 0)
          ?.map((member) => {
            const memberUser = users?.find((item) => item.id == member.user_id);

            return {
              ...member,
              value: `${memberUser?.id} ${memberUser?.name} ${memberUser?.surname}`,
            };
          }),
      ]);
    }
  }, [activeMeeting, isShowAddMeeting, isEdit, cellData]);

  const inputValueChairman = (value) => {
    let userId = value.split(" ").shift();
    setChairman((chairman) => {
      return { ...chairman, value: value, user_id: userId };
    });
  };

  const inputValueFirstMember = (value) => {
    let userId = value.split(" ").shift();
    setFirstMember((member) => {
      return { ...member, value: value, user_id: userId };
    });
  };

  const inputValueSecretary = (value) => {
    let userId = value.split(" ").shift();

    setSecretary((secretary) => {
      return { ...secretary, value: value, user_id: userId };
    });
  };
  
  const closeHandler = () => {
    setIsEdit(false);
    setIsShowAddMeeting(false);
    setActiveMeeting(null);
    if (setCellData) {
      scheduler.deleteEvent(cellData?.newEvent?.id);
      setCellData(null);
    }
  };
  const meetingEventAddHandler = (prop, res) => {
    const end_at = moment(prop.start_at).add();

    if (addEventHandler) {
      if (cellData !== null) {
        addEventHandler(
          {
            name: prop.name,
            start_at: prop.start_at,
            deadline_at: end_at,
            id: res.id,
          },
          cellData.newEvent,
          "MEETING_TYPE"
        );
      } else {
        addEventHandler(
          {
            name: prop.name,
            start_at: prop.start_at,
            deadline_at: end_at,
            id: res.id,
          },
          null,
          "MEETING_TYPE"
        );
      }

      if (setIsShowAddObject) {
        setIsShowAddObject(false);
      }
    }
  };
  const meetingAdd = useCallback(async (prop) => {
    const data = await axios
      .post(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/${company_id}/meetings`,
        {
          ...prop,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        meetingEventAddHandler(prop, res.data.data);
        CreateNotif(`Совещание успешно добавлено!`, "success");
        setIsShowAddMeeting(false);
      })
      .catch((e) => {
        CreateNotif(
          `Ошибка при добавлении совещания! Проверьте введенные данные `,
          "error"
        );
      });
  }, []); // Вызывается при добавлении совещания

  const meetingAddHandler = (props) => {
    if (addEventHandler) {
      meetingAdd(props);
    } else {
      addMeeting(props);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let userId = event.target.value.split(" ").shift();
    const newMembers = [...members];
    newMembers[index].value = event.target.value;
    newMembers[index].user_id = Number(userId);
    setMembers(newMembers);
  };

  const handleDeleteMember = (member, i) => {
    setMembers((prev) => [...prev.filter((item, ind) => i !== ind)]);
  };

  useMemo(() => {
    const props = {
      name,
      start_at,
      format,
      location,
      chairman: chairman.user_id,
      secretary: secretary.user_id,
      members: firstMember.user_id,
    };
    const isReq = new AddPanelRequired(props);

    setIsRequired(isReq.getResult());
  }, [
    name,
    start_at,
    format,
    location,
    chairman,
    secretary,
    members,
    firstMember,
  ]);

  const ChangeEvent = () => {
    const currentEvent = scheduler.getEvent(cellData.event_id);
    currentEvent.text = name;
    currentEvent.start_date = new Date(start_at);
    currentEvent.end_date = new Date(
      scheduler.date.add(start_at, 60, "minute")
    );
    scheduler.updateEvent(cellData.event_id);
    scheduler.callEvent("onEventChanged", [
      cellData.event_id,
      scheduler.getEvent(cellData.event_id),
    ]);
    if (setIsShowAddObject) {
      setIsShowAddObject(false);
    }
    if (setCellData) {
      setIsEdit(false);
      setIsShowAddMeeting(false);
      setActiveMeeting(null);
      setCellData(null);
    }
  };

  return (
    <>
      <Item>
        <ItemHeader>
          <img src={NameIcon} alt="" />
          <Title>Название*</Title>
        </ItemHeader>
        <InputBox>
          <Input
            placeholder="Название"
            type="text"
            name={"name"}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Input>
        </InputBox>
      </Item>
      <Item>
        <ItemHeader>
          <img src={CalendarIcon} alt="" />
          <Title>Дата и время начала*</Title>
        </ItemHeader>
        <InputBox>
          <Input
            type="datetime-local"
            fullWidth
            value={start_at}
            onChange={(e) => setStartAt(e.target.value)}
          ></Input>
        </InputBox>
      </Item>
      <Item>
        <ItemHeader>
          <img src={FormatIcon} alt="" />
          <Title>Формат и место*</Title>
        </ItemHeader>
        <InputBox>
          <Select
            placeholder="формат"
            defaultValue={0}
            style={{ width: "224px" }}
            name={"format"}
            value={format}
            onChange={(e) => setFormat(e.target.value as number)}
          >
            <MenuItem value={0} defaultChecked>
              Онлайн
            </MenuItem>
            <MenuItem value={1}>Офлайн</MenuItem>
          </Select>
          <Input
            placeholder="Введите место совещания или ссылку"
            type="text"
            fullWidth
            name={"place"}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          ></Input>
        </InputBox>
      </Item>
      <Item>
        <ItemHeader>
          <img src={UserIcon} alt="" />
          <Title>Председатель*</Title>
        </ItemHeader>
        <InputBox>
          <FormControl style={{ width: "100%", marginLeft: "45px" }}>
            <input
              list="chairmans"
              onChange={(e) => {
                inputValueChairman(e.target.value);
              }}
              value={chairman.value}
              style={{ margin: "0", width: "100%" }}
              className="input-form "
              id="demo-simple-select-filled"
            />
            <datalist id="chairmans">
              {users?.map((chairmans) => (
                <option key={chairmans.id}>
                  {`${chairmans.id}  ${chairmans.name} ${chairmans.surname}`}
                </option>
              ))}
            </datalist>
          </FormControl>
          <Input
            disabled
            placeholder="Председатель*"
            type="text"
            style={{ width: "220px" }}
            name={"chairman"}
          ></Input>
        </InputBox>
      </Item>
      <Item>
        <ItemHeader>
          <img src={UserIcon} alt="" />
          <Title>Секретарь*</Title>
        </ItemHeader>
        <InputBox>
          <FormControl style={{ width: "100%", marginLeft: "45px" }}>
            <input
              list="secretary"
              onChange={(e) => {
                inputValueSecretary(e.target.value);
              }}
              value={secretary.value}
              style={{ margin: "0", width: "100%" }}
              className="input-form "
              id="demo-simple-select-filled"
            />
            <datalist id="secretary">
              {users?.map((secretary) => (
                <option key={secretary.id}>
                  {`${secretary.id}  ${secretary.name} ${secretary.surname}`}
                </option>
              ))}
            </datalist>
          </FormControl>
          <Input
            disabled
            placeholder="Секретарь*"
            type="text"
            style={{ width: "220px" }}
            name={"secretary"}
          ></Input>
        </InputBox>
      </Item>
      <Item>
        <ItemHeader>
          <img src={UsersIcon} alt="" />
          <Title>Участники*</Title>
        </ItemHeader>
        <InputBox>
          <FormControl style={{ width: "100%", marginLeft: "45px" }}>
            <input
              list="firstmembers"
              onChange={(e) => {
                inputValueFirstMember(e.target.value);
              }}
              value={firstMember.value}
              style={{ margin: "0", width: "100%" }}
              className="input-form "
              id="demo-simple-select-filled"
            />
            <datalist id="firstmembers">
              {users?.map((member) => (
                <option key={member.id}>
                  {`${member.id}  ${member.name} ${member.surname}`}
                </option>
              ))}
            </datalist>
          </FormControl>
          <Input
            disabled
            placeholder="Участник*"
            type="text"
            style={{ width: "220px" }}
            name={"member"}
          ></Input>
        </InputBox>
        {members?.map((member: any, i) => {
          return (
            <InputBox key={member?.user_id}>
              <img
                src={CancelIcon}
                alt=""
                onClick={() => {
                  handleDeleteMember(member, i);
                }}
              />
              <input
                list="members"
                onChange={(e) => {
                  handleChange(e, i);
                }}
                value={member.value}
                style={{ margin: "0", width: "100%" }}
                className="input-form "
                id="demo-simple-select-filled"
              />
              <datalist id="members">
                {users?.map((memb) => (
                  <option key={memb.id}>
                    {`${memb.id}  ${memb.name} ${memb.surname}`}
                  </option>
                ))}
              </datalist>
              <Input
                disabled
                placeholder="Участник*"
                type="text"
                style={{ width: "220px" }}
              ></Input>
            </InputBox>
          );
        })}
        <AddUserButton
          onClick={() => {
            setMembers((prev) => [...prev, { user_id: "", value: "" }]);
          }}
        >
          <img src={addIcon} alt="" />
          <span>Добавить участника</span>
        </AddUserButton>
      </Item>

      <hr
        style={{
          border: "1px solid #E1E1E1",
          boxShadow: "0px -1px 3px rgba(0, 0, 0, 0.15)",
          marginTop: "58px",
          marginBottom: "37px",
        }}
      />

      <ButtonBox>
        {isEdit ? (
          <Button
          style={{
            marginLeft: "-100px",
            width: "163px",
            height: "50px",
            background: "#01B0E9",
            border: "1px solid #01B0E9",
            borderRadius: "4px",
            color: isRequired ? "#E0E0E0" : "#FFFFFF",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
          }}
            disabled={isRequired}
            onClick={() => {
              editMeeting(activeMeeting?.id, {
                name,
                start_at,
                format,
                location,
                chairman: chairman.user_id,
                secretary: secretary.user_id,
                members: [
                  ...members?.map((memb) => memb?.user_id),
                  firstMember?.user_id,
                ],
              }).then((res) => {
                if (cellData) {
                  ChangeEvent();
                }
              });
            }}
          >
            Сохранить
          </Button>
        ) : (
          <Button
            style={{
              marginLeft: "-100px",
              width: "163px",
              height: "50px",
              background: "#01B0E9",
              border: "1px solid #01B0E9",
              borderRadius: "4px",
              color: isRequired ? "#E0E0E0" : "#FFFFFF",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
            }}
            disabled={isRequired}
            onClick={() => {
              const props = {
                name,
                start_at: formatToStringUTC(new Date(start_at)),
                format,
                location,
                chairman: Number(chairman.user_id),
                secretary: Number(secretary.user_id),
                members: [
                  ...members?.map((memb) => memb?.user_id),
                  firstMember?.user_id,
                ],
              };

              meetingAddHandler(props);
            }}
          >
            Добавить
          </Button>
        )}
        <Button
          style={{
            marginLeft: "20px",
            background: "white",
            color: "#292A34",
            width: "64px",
            height: "24px",
            display: "flex",
            top: "12px",
            border: "none",
            position: "relative",
            fontWeight: 400,
          }}
          onClick={() => {
            closeHandler();
          }}
        >
          Отмена
        </Button>
      </ButtonBox>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    addedMeeting: state.commonInfo.addedMeeting,
  };
};

const mapDispatchToProps = {
  setAddedMeeting,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MeetingAddPanel);
