import React, { useState } from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../../rootReducer';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Input from '../../../common/components/Inputs/Input';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import ScheduleDay from '../../NewUser/components/ScheduleDay';
import Button from '../../../common/components/newComponent/Buttons/Button';
// import {setChangePasswordMode} from '../actions'
import styled from 'styled-components';
import palette from '../../../common/components/palette';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import { nanoid } from 'nanoid';
import {
  duplicateTask,
  make_as_subtask,
  make_subtask,
  selectTask,
  settingDependencies,
} from '../actions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type Props = {
  setShowContextDropDown: (boolean) => void;
  duplicateTask: () => void;
  make_subtask: () => void;
  make_as_subtask: () => void;
};

const WindowItem = styled.div`
  height: 32px;
  padding: 8px;
  size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #f3fafe;
  }
`;

const Window = styled.div`
  position: absolute;
  width: 184px;
  right: 0px;
  top: 45px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  padding: 4px;
  z-index: 99;
`;



const ContextDropdownMenu: React.FC<Props> = ({
  setShowContextDropDown,
  duplicateTask,
  make_subtask,
  make_as_subtask,
}) => {

  

const handleClickAway = () => {
  setShowContextDropDown(false);
}

  const dispatch = useDispatch();
  let listItems = [
    'Настроить зависимость',
    'Дублировать задачу',
    'Создать подзадачу',
    'Сделать подзадачей',
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
    <Window className={'ThreeDotsDropdownMenu'}>
      {listItems.map((item) => {
        return (
          <WindowItem
            key={nanoid()}
            onClick={() => {
              switch (item) {
                case listItems[0]:
                  dispatch(settingDependencies(true));
                  break;
                case listItems[1]:
                  duplicateTask();
                  break;
                case listItems[2]:
                  make_subtask();
                  break;
                case listItems[3]:
                  make_as_subtask();
                  break;
              }
            }}
          >
            {item}
          </WindowItem>
        );
      })}
    </Window>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: State) => {
  return {
    // password: state.newUser.password
  };
};

const mapDispatchToProps = {
  duplicateTask,
  make_subtask,
  make_as_subtask,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextDropdownMenu);

// export default ContextDropdownMenu;
