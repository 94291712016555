import axios from "axios";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { StatusContext } from "../../context/status";
import "./ChatMenu.scss";
import { nanoid } from "nanoid";
import DateMaster from "../../../../../utils/DateMaster";
import ReplyIcon from "@material-ui/icons/Reply";
import CloseIcon from "@material-ui/icons/Close";
import { urlApp } from "../../../../../App";
import { throttle } from "../../../../../utils/throttle";
import { TextareaAutosize } from "@material-ui/core";
let fileTranslate;
let periodUpdate = 5000;

const Chat = ({ businessProcess }) => {
  const {
    bearer,
    userId,
    company_id,
    users,
    selectedBp,
    businessProcessCurrentGlobal,
  } = useContext(StatusContext);

  const refInputCheck = useRef(null);
  const ref = useRef(null);

  const [message, setMessage] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [fetchComments, setFetchComments] = useState([]);
  const [timerFetchData, setTimerFetchData] = useState(0);
  const [checkOtvet, setCheckOtvet] = useState(false);
  const [checkOtvetId, setCheckOtvetId] = useState(null);
  const [checkOtvetText, setCheckOtvetText] = useState({
    name: "",
    surname: "",
    text: "",
  });
  const [pageNum, setPageNum] = useState(1);
  const [pageNumNew, setPageNumNew] = useState(1);
  const [appealWindow, setAppealWindow] = useState(false);

  const user = users.find((user) => user.id === userId);
  const members = businessProcess.members?.filter((num) => num !== userId);
  const arr = [];
  const a = (item) => {
    for (let i = 0; i < item?.length; i++) {
      for (let j = 0; j < users.length; j++) {
        if (item[i] === users[j]?.id) {
          arr.push(users[j]);
        }
      }
    }
    return arr;
  };
  a(members);

  console.log("members",members);
  console.log("user",user);
  console.log("businessProcess",businessProcess);  
  console.log("arr",arr);  

  
  const changeInputValue = (InputValue) => {
    setMessage(InputValue);
  };

  const clickFormButton = () => {
    sendMessage();
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  //таймер для запросов
  useEffect(() => {
    setInterval(() => {
      setTimerFetchData((timerFetchData) => timerFetchData + 1);
    }, periodUpdate);
  }, []);

  //получение всех комментов
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND}/api/v1/bp/${selectedBp}/comments`,
        {
          headers: {
            // Accept: "application/json",
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            Authorization: bearer,
            "company-id": company_id,
          },
        }
      )
      .then((r) => setFetchComments(r.data.data))
      .then(() => {
        ref.current.scrollTo(0, ref.current.scrollHeight);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [businessProcessCurrentGlobal]);

  //ответ на сообщение
  const replyMessage = (id, autorId, text) => {
    let infoUser = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_API}/users/${autorId}`,
      headers: {
        Accept: "application/json",
        Authorization: bearer,
        "company-id": company_id,
      },
    };

    axios(infoUser)
      .then((res) =>
        setCheckOtvetText({
          ...checkOtvetText,
          name: res.data.data.name,
          surname: res.data.data.surname,
          text: text,
        })
      )

      .catch((e) => {
        console.log(e);
      });

    setCheckOtvet(true);
    setCheckOtvetId(id);
    refInputCheck.current.focus();
  };

  //отмена 'ответить на сообщение'
  const closeOtvetInInput = () => {
    setCheckOtvetId("");
    setCheckOtvet(false);
  };

  // tasks/9/comments/40
  const deletedMessage = (id) => {
    let deletedCommentConfig = {
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND}/api/v1/comment/${id}`,
      headers: {
        Accept: "application/json",
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        "company-id": company_id,
      },
    };
    axios(deletedCommentConfig)
      .then((res) => console.log(res))
      .catch((e) => {
        console.log(e);
      });
    setFetchComments(fetchComments.filter((item) => item.id !== id));
    setCheckOtvet(false);
    setCheckOtvetId("");
  };

  const sendRequest = (body) => {
    let sendCommentAndOtvetConfig = {
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND}/api/v1/bp/${selectedBp}/comment`,
      data: body,
      headers: {
        Accept: "application/json",
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        "company-id": company_id,
        Authorization: bearer,
      },
    };
    axios(sendCommentAndOtvetConfig)
      .then(newLoad)
      .catch((e) => {
        console.log(e);
      });
    setAllFiles([]);
    setMessage("");
    setCheckOtvetId("");
    setCheckOtvet(false);
  };

  const sendMessage = () => {
    //если есть файл то отправь с файлом
    if (allFiles.length && !message.length) {
      if (!checkOtvet) {
        //просто отправить сообщение
        const body = {
          // text: message,
          author_id: userId,
          business_process_id: selectedBp,
          // comment_id: 0,
          files: allFiles,
        };
        sendRequest(body);
      } else {
        //ответ на сообщение
        const body = {
          text: message,
          author_id: userId,
          business_process_id: selectedBp,
          comment_id: checkOtvetId,
          files: allFiles,
        };
        sendRequest(body);
      }
    } //если нет файла то отправь без него
    else {
      if (!checkOtvet) {
        //просто отправить сообщение
        const body = {
          text: message,
          author_id: userId,
          business_process_id: selectedBp,
          // comment_id: 0,
          files: allFiles,
        };
        sendRequest(body);
      } else {
        //ответ на сообщение
        const body = {
          text: message,
          author_id: userId,
          business_process_id: selectedBp,
          comment_id: checkOtvetId,
          files: allFiles,
        };
        sendRequest(body);
      }
    }
  };

  //добавление файлов
  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  //Добавляет файлы
  const sendFile = (e) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      setAllFiles([
        ...allFiles,
        {
          data: fileTranslate,
          id: nanoid(),
          name: file.name.match(/(.*)\.[^.]+$/)[1],
          ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
          tag: "ChatBp",
        },
      ]);
    }, [500]);
  };

  // удаляет файлы
  const onDeletedFile = (keyId) => {
    setAllFiles(allFiles.filter((file) => file.id !== keyId));
  };

  const scrollHandler = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight - 5 <= scrollTop + clientHeight &&
        fetchComments.length === 20 * pageNumNew
      ) {
        // throttle - чтобы вызывать функцию не чаще, чем через заданный минимальный интервал.
        let f1000 = throttle(() => setPageNumNew(pageNumNew + 1), 1500);
        f1000();
      }
      if (scrollTop === 0 && pageNum) {
        // throttle - чтобы вызывать функцию не чаще, чем через заданный минимальный интервал.
        let f1000 = throttle(() => setPageNum(pageNum + 1), 1500);
        f1000();
      }
      // scrollTop = scrollHeight;
    },
    [pageNum, fetchComments, pageNumNew]
  );

  // на подгрузку старых комментов
  useEffect(() => {
    if (fetchComments.length > 10) {
      axios
        .get(
          `${
            process.env.REACT_APP_BACKEND
          }/api/v1/bp/${selectedBp}/comments/oldfrom/${
            fetchComments.length >= 5 && pageNum && fetchComments[0]?.id
          }`,
          {
            headers: {
              // Accept: "application/json",
              "secret-token": document.cookie.replace(
                /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
              "company-id": company_id,
              Authorization: bearer,
            },
          }
        )
        .then((r) => setFetchComments([...r.data.data, ...fetchComments]))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [pageNum, selectedBp]);

  const newLoad = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_BACKEND
        }/api/v1/bp/${selectedBp}/comments/newfrom/${
          fetchComments.length && fetchComments[fetchComments.length - 1]?.id
        }`,
        {
          headers: {
            // Accept: "application/json",
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            "company-id": company_id,
            Authorization: bearer,
          },
        }
      )
      .then((r) => setFetchComments([...fetchComments, ...r.data.data]))
      .then(() => {
        ref.current.scrollTo(0, ref.current.scrollHeight);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // для подгрузки новых
  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BACKEND
        }/api/v1/bp/${selectedBp}/comments/newfrom/${
          fetchComments?.length && fetchComments[fetchComments.length - 1]?.id
        }`,
        {
          headers: {
            // Accept: "application/json",
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            "company-id": company_id,
            Authorization: bearer,
          },
        }
      )
      .then((r) => setFetchComments([...fetchComments, ...r.data.data]))
      // .then(() => console.log("комментраий получен по пагинации вниз"))
      .then(() => {
        // ref.current.scrollTo(0, ref.current.scrollHeight);
      })
      .then(() => console.log("fetchComments",fetchComments))
      .catch((e) => {
        console.log(e);
      });
  }, [pageNumNew, timerFetchData, selectedBp]);

  const ast = message.split(" ");

  useEffect(() => {
    ast.forEach((item) => {
      if (item === "@") {
        setAppealWindow(true);
      } else {
        setAppealWindow(false);
      }
    });
  }, [message]);

  const autoAppeal = (item) => {
    return setMessage(message + item.name);
  };

  return (
    <div id="chat">
      {businessProcess.template_id ? (
        <span style={{ marginBottom: "5px" }}>
          Чат типового БП: {businessProcess.name}
        </span>
      ) : (
        <span style={{ marginBottom: "5px" }}>
          Чат БП: {businessProcess.name}
        </span>
      )}

      <div className="chat-content" onScroll={scrollHandler} ref={ref}>
        <div className="chat-content-scroll">
          {fetchComments?.map((comment) => (
            <>
              {comment.is_log && (
                <div
                  key={comment.id}
                  className="chat-message chat-message_text chat-message-system"
                >
                  <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                  <span
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      paddingLeft: "5px",
                    }}
                  >
                    {new Date(comment.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    <span> </span>
                    {new Date(comment.created_at).toLocaleDateString()}
                  </span>
                </div>
              )}

              {comment.user_id === userId && (
                <div
                  key={comment.id}
                  className=" chat-message chat-message_text chat-message-my"
                >
                  <h4 className="head_meassage">
                    {user?.name + " " + user?.surname}
                    <span
                      onClick={() =>
                        replyMessage(comment.id, comment.user_id, comment.text)
                      }
                      className="chat-message__answer"
                    >
                      Ответить
                    </span>
                    <span
                      onClick={() => deletedMessage(comment.id)}
                      className="chat-message__deleted"
                    >
                      удалить
                    </span>
                  </h4>
                  <span className="chat-message__spanData">
                    {(() => {
                      const date = new Date(comment.created_at);
                      const dateMaster = new DateMaster(date);
                      return dateMaster.commentDate;
                    })()}
                  </span>
                  {!!comment.comment_id && (
                    <div className="chat-message__div-Otev">
                      {fetchComments?.map((com) => {
                        if (comment.comment_id === com.id) {
                          return (
                            <p
                              style={{ whiteSpace: "pre-wrap" }}
                              key={comment.id}
                            >
                              {com.text}
                            </p>
                          );
                        }
                      })}
                    </div>
                  )}
                  <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                  {comment.files.list?.map((file) => (
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${file.url}`}
                      target="_blank"
                    >
                      <div
                        key={file.id}
                        className="chat-message__file-download  chat-message__file-download_in-message"
                      >
                        <img
                          style={{ height: "22px" }}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                          alt="#"
                        />
                        <span style={{ color: "black" }}>{file.name}</span>
                      </div>
                    </a>
                  ))}
                </div>
              )}
              {comment.user_id !== userId && !comment.is_log && (
                <div className="chat-message ">
                  <div className="chat-message_img">
                    {(() => {
                      const userAcc = users.find(
                        (user) => user.id === comment.user_id
                      );
                      return userAcc.name[0] + userAcc.surname[0];
                    })()}
                  </div>
                  <div className="chat-message_text">
                    <h4>
                      {(() => {
                        const userAcc = users.find(
                          (user) => user.id === comment.user_id
                        );
                        return userAcc.name + " " + userAcc.surname;
                      })()}
                      <span
                        className="chat-message__answer"
                        onClick={() =>
                          replyMessage(
                            comment.id,
                            comment.user_id,
                            comment.text
                          )
                        }
                      >
                        Ответить
                      </span>
                    </h4>
                    <span className="chat-message__spanData">
                      {(() => {
                        const date = new Date(comment.created_at);
                        const dateMaster = new DateMaster(date);
                        return dateMaster.commentDate;
                      })()}
                    </span>
                    {!!comment.comment_id && (
                      <div className="chat-message__div-Otev">
                        {fetchComments?.map((com) => {
                          if (comment.comment_id === com.id) {
                            return (
                              <p
                                style={{ whiteSpace: "pre-wrap" }}
                                key={comment.id}
                              >
                                {com.text}
                              </p>
                            );
                          }
                        })}
                      </div>
                    )}
                    <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                    {comment.files.list?.map((file) => (
                      <a
                        href={`${process.env.REACT_APP_BACKEND}${file.url}`}
                        target="_blank"
                      >
                        <div
                          key={file.id}
                          className="chat-message__file-download  chat-message__file-download_in-message"
                        >
                          <img
                            style={{ height: "22px" }}
                            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                            alt="#"
                          />
                          <span style={{ color: "black" }}>{file.name}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>

      <div className="chat-bottom">
        {!!allFiles.length && (
          <div className="chat-bottom__files-wrapper">
            {allFiles?.map((file) => (
              <div key={file.id} className="chat-message__file-download">
                <span
                  onClick={() => onDeletedFile(file.id)}
                  className="chat-message__file-download_deletedBtn"
                >
                  ×
                </span>
                <img
                  style={{ height: "22px" }}
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                  alt="#"
                />

                <p>{file?.name}</p>
              </div>
            ))}
          </div>
        )}
        <input id={1232131232131221} type="file" onChange={sendFile} />
        <button
          className={`chat-btn ${
            allFiles.length > 0 ? "chat-btn__file-action" : ""
          } `}
          style={{
            marginRight: "8px",
          }}
        >
          <label htmlFor={1232131232131221} className="chat-bottom_label">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
            />
          </label>
        </button>
        {/* <button className="chat-btn">
          <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/@.svg`} />
        </button> */}
        {checkOtvet && (
          <div className=" chat-bottom__input_otvet">
            <div className=" chat-bottom__input_otvet_icon">
              <ReplyIcon />
            </div>
            <div className=" chat-bottom__input_otvet_text">
              <h5>{`${checkOtvetText.name}  ${checkOtvetText.surname}`}</h5>
              <p>{checkOtvetText.text}</p>
            </div>
            <div
              onClick={closeOtvetInInput}
              className=" chat-bottom__input_otvet_iconClose"
            >
              <CloseIcon />
            </div>
          </div>
        )}{" "}
        {appealWindow && (
          <div className="window_appeal">
            {arr
              // .filter((item) => {
              //   if (message && appealWindow) {
              //     if (
              //       item.name.toLowerCase() >=
              //         0 ||
              //       item.surname.toLowerCase() >=
              //         0
              //     ) {
              //       return true;
              //     } else {
              //       return false;
              //     }
              //   } else {
              //     return true;
              //   }
              // })
              .map((item) => (
                <div className="item_appeal" onClick={() => autoAppeal(item)}>
                  {item.name + " " + item.surname}
                </div>
              ))}
          </div>
        )}
        <TextareaAutosize
          aria-label=""
          ref={refInputCheck}
          className="input-form"
          id={"rightPlate_name_textarea"}
          style={{
            width: "70%",
            marginLeft: "17px",
            marginRight: "25px",
            lineHeight: "20px",
          }}
          placeholder="Введите текст"
          value={message}
          onChange={(e) => changeInputValue(e.currentTarget.value)}
        />
        {/* <input
          ref={refInputCheck}
          className="input-form"
          style={{
            width: 70 + "%",
            marginLeft: 17 + "px",
            marginRight: 25 + "px",
          }}
          type="text"
          placeholder="Введите текст"
          value={message}
          onChange={(e) => changeInputValue(e.target.value)}
          onKeyPress={keyPressHandler}
        /> */}
        {!message.length && !allFiles.length ? (
          <button
            style={{ background: "#ececec", cursor: "default" }}
            className="chat-btn"
            id="send-message"
            onClick={clickFormButton}
          >
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/send-message.svg`}
            />
          </button>
        ) : (
          <button
            className="chat-btn"
            id="send-message"
            onClick={clickFormButton}
          >
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/send-message.svg`}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Chat;
