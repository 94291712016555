import React, { useState } from "react";

const Meet: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 3H16C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8V16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21H16C15.3434 21 14.6932 20.8707 14.0866 20.6194C13.4799 20.3681 12.9288 19.9998 12.4645 19.5355C12.0002 19.0712 11.6319 18.5201 11.3806 17.9134C11.1293 17.3068 11 16.6566 11 16V7.99999C11 6.67391 11.5268 5.40214 12.4645 4.46446C13.4021 3.52678 14.6739 3 16 3V3Z"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 25V29"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.9451 17C24.6989 19.2002 23.6505 21.2324 22.0003 22.7082C20.3501 24.1841 18.2139 25 16 25C13.7861 25 11.6499 24.1841 9.9997 22.7082C8.34951 21.2324 7.30112 19.2001 7.05493 17"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Meet;
