import { takeEvery, call, put } from "redux-saga/effects";
import {
  types,
  fetchTasksAction,
  fetchTasksVisualizationAction,
  readNotificationByTaskAction,
  timeSheetActionsAction,
  uploadFileAction,
  deleteFileAction,
  fetchTaskDependenceAction,
} from "./action_types";

import { fetchData } from "../../utils/fetchData";
import {
  setLoadingTasks,
  changeTaskList,
  changeVisualisationList,
  LocalTimeSheetRecord,
  timeSheetActions,
  timesheetCacheRecord,
  fetchVisualizationList,
  closingDay,
  closingDayHidingSignal,
} from "./actions";
import { store } from "../../App";
import {
  addCommentsList,
  clearCommentField,
  selectTask,
  setCommentFiles,
  setDependencies,
  setIsSendingDelegate,
  setIsSendongComment,
  setShownTaskInfoPlate,
} from "../TaskInfoPlate/actions";
import {
  CollectAllFilters,
  sorting,
  table_filter,
} from "../../common/shared_data";
import {
  change_remote_notifications,
  changeWorkSchedule,
  updateTask,
} from "../../common/actions";
import {
  delegateConfirmAction,
  uploadCommentFileAction,
} from "../TaskInfoPlate/action_types";
import { CreateNotif } from "../../utils/createNotification";
import { cookieMaster } from "../../utils/CookieMaster";
import { Dependences, File, Item, Task } from "../../common/types";
import {
    begin,
    dateFormatUTC,
    milisecondDateFromOurFormat,
    parseStringToDate,
} from '../TaskInfoPlate/reducer';
import {formatStringDate} from "../../common/format";
import {timeZone} from "../../common/format";

export function* watchFetchTasks() {
  yield takeEvery(types.FETCH_TASKS, fetchTasks);
}

export function* watchFetchTasksVisualization() {
  yield takeEvery(types.FETCH_TASKS_VISUALIZATION, fetchTasksVisualization);
}

export function* watchFetchTaskDependence() {
  yield takeEvery(types.FETCH_TASK_DEPENDENCE, fetchTaskDependence);
}

export function* watchLoadSpecificTask() {
  yield takeEvery(types.LOAD_SPECIFIC_TASK, loadSpecificTask);
}

export function* watchSetStatusFilterTasks() {
  yield takeEvery(types.SET_FILTERS, apply_filters_orders);
}

export function* watchFiltersActualToggle() {
  yield takeEvery(types.SET_ALL_OR_ACTUAL, apply_filters_orders);
}

export function* watchSetTableOrderTasks() {
  yield takeEvery(types.SET_TABLE_ORDER, apply_filters_orders);
}

export function* watchReadTaskNotification() {
  yield takeEvery(types.READ_NOTIFICATION_BY_TASK, readTaskNotification);
}

export function* watcherUploadFile() {
  yield takeEvery(types.UPLOAD_FILE_TASKS, uploadFile);
}

export function* watcherDeleteFile() {
  yield takeEvery(types.DELETE_FILE, deleteFile);
}

export function* watchTimeSheetActions() {
  yield takeEvery(types.TIME_SHEET_ACTIONS, timeSheetActs);
}

export function* watchSendCommentTasks() {
  yield takeEvery(types.SEND_COMMENT, sendComment);
}

export function* watchClosingDayDispatchAll() {
  yield takeEvery(types.CLOSING_DAY_DISPATCH_ALL, closingDayDispatchAll);
}

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function* closingDayDispatchAll() {
  const TimeSheetCache = store.getState().tasksPage.TimeSheetCache;
  const timeSheet = store.getState().tasksPage.timeSheet;
  const current_user = store.getState().commonInfo.current_user;

  // тасклоадсум берется из этого массива (передается измененное из "закрытие дня")
  for (let k = 0; k < TimeSheetCache.length; k++) {
    let task_load_sum = TimeSheetCache[k].task_load_sum;

    // Тут должно измениться значение в задаче
    if (task_load_sum)
      yield put(
        timeSheetActions("post", TimeSheetCache[k].task_id, task_load_sum) // посылается по очереди на сервер каждая измененная задача
      );
  }

  // статус, коммент текст и файлы отсюда
  for (let i = 0; i < timeSheet.length; i++) {
    let task_Id = timeSheet[i].task_id;
    let commentText = timeSheet[i].comment_text;

    if (timeSheet[i].comment_text && timeSheet[i].comment_text !== "")
      yield sendComment({ task_Id, commentText });
    if (timeSheet[i].status_id)
      yield put(
        updateTask(timeSheet[i].task_id, { status_id: timeSheet[i].status_id })
      );
  }

  yield put(closingDayHidingSignal(true));

  yield put(LocalTimeSheetRecord("clear_whole", 0));
  yield put(timesheetCacheRecord("clear"));
  yield put(fetchVisualizationList(current_user));

  yield call(delay, 800);
  yield put(closingDay(false));
}

function* sendComment({ task_Id, commentText }: any) {
  const foundItem = store
    .getState()
    .tasksPage.timeSheet.find((item) => item.task_id === task_Id);
  const author_id = store.getState().commonInfo.current_user;

  const body = {
    text: commentText,
    author_id,
    task_id: task_Id,
    parent_id: 0,
  };

  if (foundItem?.comment_files?.length) {
    body["files"] = foundItem?.comment_files.map((file) => file.id);
  }

  const comment_done = yield call(
    fetchData.post,
    `/api/v1/tasks/${task_Id}/comments`,
    JSON.stringify(body)
  );

  if (comment_done) {
    LocalTimeSheetRecord("clear_whole", task_Id);
  }
}

function* uploadFile({ file }: uploadFileAction) {
  const formData = new FormData();
  // @ts-ignore
  formData.append("file", file);

  const data = yield call(fetchData.post, "/api/v1/files", formData, {
    Authorization: cookieMaster.getCookie("access_token"),
  });

  if (data) {
    let taskId = store.getState().tasksPage.taskDoneConfirmation.taskId;
    let timeSheet = store.getState().tasksPage.timeSheet;
    let found_timesheet = timeSheet.find((task) => task.task_id === taskId);

    yield put(
      LocalTimeSheetRecord(
        "add",
        taskId,
        null,
        null,
        null,
        "",
        found_timesheet && found_timesheet.comment_files
          ? [...(found_timesheet.comment_files as File[]), data]
          : [data]
      )
    );
  }
}

function* deleteFile({ attachmentId, taskId }: deleteFileAction) {
  let data = yield call(fetchData.delete, `/api/v1/files/${attachmentId}`);
  if (data) {
    let tSheet = store.getState().tasksPage.timeSheet;

    let sheetCommentFiles = tSheet.find(
      (record) => record.task_id === taskId
    )?.comment_files;

    if (sheetCommentFiles)
      sheetCommentFiles.splice(
        sheetCommentFiles.findIndex((rec) => rec.id === attachmentId),
        1
      );

    yield put(
      LocalTimeSheetRecord(
        "add",
        taskId as number,
        null,
        null,
        null,
        "",
        sheetCommentFiles
      )
    );
  }
}

function* timeSheetActs({
  actionType,
  taskId,
  value,
  timesheetId,
}: timeSheetActionsAction) {
  switch (actionType) {
    case "get":
      let response = yield call(
        fetchData.get,
        `/api/v1/tasks/${taskId}/timesheets`
      );
      break;
    case "post":
      let task = yield call(
        fetchData.post,
        `/api/v1/tasks/${taskId}/timesheets`,
        JSON.stringify({
          spent_time: value,
          write_off_date: store.getState().taskInfoPlate.begin.split(" ")[0],
        })
      );
      break;
    case "patch":
      let respons21e = yield call(
        fetchData.patch,
        `/api/v1/tasks/${taskId}/timesheets/${timesheetId}`,
        JSON.stringify({
          spent_time: value,
          write_off_date: store.getState().taskInfoPlate.begin.split(" ")[0],
        })
      );
      break;
    case "delete":
      let respons1e = yield call(
        fetchData.delete,
        `/api/v1/tasks/${taskId}/timesheets/${timesheetId}`
      );
      break;
  }
}

function* readTaskNotification({ id_task }: readNotificationByTaskAction) {
  const current_user_id = store.getState().commonInfo.currentUserInfo?.id;

  const { remote_notifications } = store.getState().commonInfo;
  let new_data_as_read: any = [...remote_notifications.read];
  let new_data_as_unread = [...remote_notifications.unread];
  let nitification: any[] = [];
  remote_notifications.unread.forEach((notification: any) => {
    if (notification?.data?.task_id === id_task) {
      nitification.push(notification.id);

      new_data_as_unread.forEach(
        (el: any, i) =>
          el.data.task_id === id_task && new_data_as_unread.splice(i, 1)
      );
      new_data_as_read.unshift(notification);
    }
  });
  for (let i = 0; i < nitification.length; i++) {
    yield call(
      fetchData.patch,
      `/api/v1/users/${current_user_id}/read-notification?id=${nitification[i]}`,
      {}
    );
  }
  yield put(change_remote_notifications(new_data_as_unread, new_data_as_read));
}


function* loadSpecificTask(data) {
    const response = yield call(fetchData.get, '/api/v1/tasks/' + data.value);
    if (response) { 
       response.forEach((item, index, array) => {item.begin =  formatStringDate(item.begin); item.end = formatStringDate(item.end); });
        yield put(selectTask(response));
        yield put(setShownTaskInfoPlate(true));
    }
}

function* apply_filters_orders() {
  const { selectedUserId, tasksOrder, tableFilter, privateToggle } =
    store.getState().tasksPage;

  const { current_set } = store.getState().commonInfo.filters;

  let сopyed_obj = {
    date_from: "",
    date_to: "",
    executors: [] as Item[],
    authors: [] as Item[],
    statuses: [] as number[],
    actual: true,
  };
  Object.keys(current_set).forEach((key) => {
    let keyType = typeof current_set[key];
    if (keyType === "object") сopyed_obj[key] = [...current_set[key]];
    else сopyed_obj[key] = current_set[key];
  });

  // проверяю, существует ли наличие статуса "просрочено"
  let outdated = false;
  let in_work_native = false;
  if (сopyed_obj.statuses.includes(14)) {
    if (сopyed_obj.statuses.includes(10)) {
      in_work_native = true;
    }
    outdated = true;
  }

  //*************** */
  let response;

  if (privateToggle === true) {
    response = yield call(
      fetchData.get,
      `/api/v1/tasks?page=1` +
        ActualOrAll(сopyed_obj, selectedUserId, CollectAllFilters(сopyed_obj)) +
        sorting(tasksOrder) +
        table_filter(tableFilter) +
        "&private=true"
    );
  } else {
    response = yield call(
      fetchData.get,
      `/api/v1/tasks?page=1` +
        ActualOrAll(сopyed_obj, selectedUserId, CollectAllFilters(сopyed_obj)) +
        sorting(tasksOrder)
    );
    }
    if (response) {
      response.forEach((item, index, array) => {
        item.begin = formatStringDate(item.begin);
        item.end = formatStringDate(item.end);
      });
      if (outdated) {
        //
        if (in_work_native) {
          // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
          yield put(changeTaskList([...response]));
        } else {
          let filtered = response.filter((respItem) => {
            if (respItem.status_id === 10) {
              if (
                new Date(Date.now()) >
                new Date(milisecondDateFromOurFormat(respItem.end))
              )
                return respItem;
            }
          });
          yield put(
            changeTaskList([
              ...response.filter((item) => item.status_id !== 10),
              ...filtered,
            ])
          );
        }
      } else {
        yield put(changeTaskList([...response]));
      }
    }
  }
function* fetchTaskDependence({ id }: fetchTaskDependenceAction) {
  if (id) {
    let dependencies: Dependences = {
      child: [],
      parent: null,
      next: null,
      prev: null,
    };
    const task = yield call(fetchData.get, `/api/v1/tasks/${id}`);
    let begin = task.begin;
    let end = task.end;
    task.begin = formatStringDate(begin);
    task.end = formatStringDate(end);
    if (task.parent_id !== null) {
      let parent = yield call(fetchData.get, `/api/v1/tasks/${task.parent_id}`);
      let begin = parent.begin;
      let end = parent.end;
      parent.begin = formatStringDate(begin);
      parent.end = formatStringDate(end);
      dependencies.parent = parent;
    }
    if (task.next_id !== null) {
      yield fetchData
        .get(`/api/v1/tasks/${task.next_id}`)
        .then((data: Task) => {
          let begin = data.begin;
          let end = data.end;
          data.begin = formatStringDate(begin);
          data.end = formatStringDate(end);
          dependencies.next = data;
        });
    }
    if (task.prev_id !== null) {
      yield fetchData
        .get(`/api/v1/tasks/${task.prev_id}`)
        .then((data: Task) => {
          let begin = data.begin;
          let end = data.end;
          data.begin = formatStringDate(begin);
          data.end = formatStringDate(end);

          dependencies.prev = data;
        });
    }
    if (task?.child_tasks?.length > 0) {
      for (let i = 0; i < task?.child_tasks?.length; i++) {
        let child = yield call(
          fetchData.get,
          `/api/v1/tasks/${task.child_tasks[i]}`
        );
        let begin = child.begin;
        let end = child.end;
        child.begin = formatStringDate(begin);
        child.end = formatStringDate(end);
        dependencies.child.push(child);
      }
    }
    yield put(setDependencies(dependencies));
  }
}


function* fetchTasks({pageNum}: fetchTasksAction) {
    const {
        selectedUserId,
        tasks: taskList,
        tableFilter,
        isLoading,
        tasksOrder,
        privateToggle
    } = store.getState().tasksPage;

    const {current_set} = store.getState().commonInfo.filters;

    // делаю копию объекта стэйта, чтобы оригинальный стэйт не изменился
    let Copyed_obj = {
        date_from: '',
        date_to: '',
        executors: [] as Item[],
        authors: [] as Item[],
        statuses: [] as number[],
        actual: true,
    };
    Object.keys(current_set).forEach((key) => {
        let keyType = typeof current_set[key];
        if (keyType === 'object') Copyed_obj[key] = [...current_set[key]];
        else Copyed_obj[key] = current_set[key];
    });

    // проверяю, существует ли наличие статуса "просрочено"
    let outdated = false;
    let in_work_native = false;
    if (Copyed_obj.statuses.includes(14)) {
        if (Copyed_obj.statuses.includes(10)) {
            // статус "в работе" тоже есть. Тогда статус "просрочено" удаляем, и оставляем признак outdated
            let index = Copyed_obj.statuses.findIndex((it) => it === 14);
            Copyed_obj.statuses.splice(index, 1);
            in_work_native = true;
        } else {
            // статуса "в работе" нет, тогда меняем 14 статус на 10
            Copyed_obj.statuses[Copyed_obj.statuses.indexOf(14)] = 10;
        }
        outdated = true;
    }
    outdated = true;
  

  if (!isLoading && pageNum === 1) yield put(setLoadingTasks(true));

  let response;

  if (privateToggle === true) {
    response = yield call(
      fetchData.get,
      `/api/v1/tasks?page=${pageNum}` +
        ActualOrAll(Copyed_obj, selectedUserId, CollectAllFilters(Copyed_obj)) +
        sorting(tasksOrder) +
        table_filter(tableFilter) +
        "&private=true"
    );
  }else {
    response = yield call(
      fetchData.get,
      `/api/v1/tasks?page=${pageNum}` +
        ActualOrAll(Copyed_obj, selectedUserId, CollectAllFilters(Copyed_obj)) +
        sorting(tasksOrder) +
        table_filter(tableFilter)
    );
  }
  if (response) {
    if (outdated) {
      if (in_work_native) {
        // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
        yield put(changeTaskList([...taskList, ...response]));
      } else {
        let filtered = response.filter((respItem) => {
          if (respItem.status_id === 10) {
            if (
              new Date(Date.now()) >
              new Date(milisecondDateFromOurFormat(respItem.end))
            )
              return respItem;
          }
        });
        yield put(
          changeTaskList([
            ...taskList,
            ...filtered,
            ...response.filter((item) => item.status_id !== 10),
          ])
        );
      }
    } else {
    
        response =  yield call(
            fetchData.get,
            `/api/v1/tasks?page=${pageNum}` +
            ActualOrAll(Copyed_obj, selectedUserId, CollectAllFilters(Copyed_obj)) +
            sorting(tasksOrder) +
            table_filter(tableFilter)
        );
    
        }
    if (response) {   
        response.forEach((item, index, array) => {item.begin =  formatStringDate(item.begin);item.end = formatStringDate(item.end); });
          if (outdated) {
            if (in_work_native) {
                // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
                yield put(changeTaskList([...taskList, ...response]));
            } else {
                let filtered = response.filter((respItem) => {
                    if (respItem.status_id === 10) {
                        if (
                            new Date(Date.now()) >
                            new Date(milisecondDateFromOurFormat(respItem.end))
                        )
                            return respItem;
                    }
                });
                yield put(
                    changeTaskList([
                        ...taskList,
                        ...filtered,
                        ...response.filter((item) => item.status_id !== 10),
                    ])
                );
            }
        } else {
            yield put(changeTaskList([...taskList, ...response]));
        }
    }
  }

    let time_Zone = timeZone();

    const workSchedule = yield call(
        fetchData.get,
        `/api/v1/users/${selectedUserId}/schedule?tz=${time_Zone}`
    );

  if (workSchedule) yield put(changeWorkSchedule(workSchedule.schedule));
  yield put(setLoadingTasks(false));
}

function* fetchTasksVisualization({
  selectedUserId,
}: fetchTasksVisualizationAction) {
  const visualisationRequest = yield call(
    fetchData.get,
    "/api/v1/tasks?executor_id=" +
      selectedUserId +
      `&order=visual&orderType=deadline`
  );
  if (visualisationRequest && visualisationRequest.length > 70) {
    visualisationRequest.splice(70);
    yield put(changeVisualisationList(visualisationRequest));
  } else if (visualisationRequest) {
    yield put(changeVisualisationList(visualisationRequest));
  }
}

function ActualOrAll(filters, selectedUserId, collectedFilters) {
  let prefix = "";

  if (filters.actual) {
    if (filters.authors.length + filters.executors.length === 1) {
    }
  }

  return prefix + collectedFilters;
}
