import React, { useEffect, useState } from "react";
import MenuHead from "../Menu/MenuHead";
import SchedulerDate from "./SchedulerDate";
import SchedulerViewSwitcher from "./SchedulerView";
import UsersSelect from "./UsersSelect";

interface Props {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  view:"day" | "week" ;
  setView:(mode:"day" | "week" )=>void
  setIsTaskView: (mode: boolean) => void;
  setIsShowAddObject: (mode: boolean) => void;
  setCurrentUser: (id: number|string)=>void
}

const scheduler = window.scheduler;

const Header = ({ selectedDate, setSelectedDate, view, setView,setIsTaskView,setIsShowAddObject, setCurrentUser }) => {
  const [date, setDate] = useState<Date>(new Date());

  const handleViewChange = (newView: "day" | "week") => {
    setView(newView);
    scheduler.setCurrentView(selectedDate, newView);
  };
  const dayPrevClick = () => {
    scheduler.setCurrentView(scheduler.date.add(selectedDate, -1, "day"));
    setSelectedDate(() => scheduler.getState().date);
  };

  const dayNextClick = () => {
    scheduler.setCurrentView(scheduler.date.add(selectedDate, 1, "day"));
    setSelectedDate(() => scheduler.getState().date);
  };

  const handleWeekPrevClick = () => {
    scheduler.setCurrentView(scheduler.date.add(selectedDate, -7, "day"));
    setSelectedDate(() => scheduler.getState().date);
  };

  const handleWeekNextClick = () => {
    scheduler.setCurrentView(scheduler.date.add(selectedDate, 7, "day"));
    setSelectedDate(() => scheduler.getState().date);
  };

 

  return (
    <div className="header">
      <SchedulerViewSwitcher view={view} onViewChange={handleViewChange} />
      <SchedulerDate
        date={selectedDate}
        view={view}
        dayPrevClick={dayPrevClick}
        dayNextClick={dayNextClick}
        weekPrevClick={handleWeekPrevClick}
        weekNextClick={handleWeekNextClick}
       
      />
      <div className="users_select-container">
      <div className="users_select-title">Для сотрудника</div>
      <UsersSelect setCurrentUser={setCurrentUser} />
      </div>
      <>
        <MenuHead setIsTaskView={setIsTaskView}
        setIsShowAddObject={setIsShowAddObject}/>
      </>
    </div>
  );
};

export default Header;
