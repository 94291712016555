import React, { useContext } from "react";
import { StatusContext } from "../../context/status.js";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { CreateNotif } from "../../../../../utils/createNotification";

let Power;

const BpSampleItem = ({ el }) => {
  const {
    setBpListChange,
    apiBp,
    createBpStatus,
    createBpSampleStatus,
    bearer,
    openMenuTasks,
    openMenuBp,
    setBpListSamples,
    setSelectedBp,
    setBp,
    setCreateBpFromSample,
    userId,
    users,
    company_id,
    projects,
    settingsOfFields,
  } = useContext(StatusContext);

  const [depId, setDepId] = useState();
  const [headOfDep, setHeadOfDep] = useState();

  useEffect(() => {
    Power = require("../../../public/assets/status/Power.svg");
  }, []);

  useEffect(() => {
    if (userId) {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/users/${userId}`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
          },
        })
        .then((res) => {
          // setUsers([...users, ...res.data.data]);
          setDepId(res.data.data.departments);
          // console.log(res.data.data.departments);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (depId) {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/departments/${depId[0]}`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            // Информация берется из Cookies, вместо localStorage
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("res", res);

          let result;

          if (res && res.data && res.data.data) {
            result = res.data.data.head_id;
          }

          // console.log("result", result);

          if (result) {
            setHeadOfDep(result);
            // console.log("result", result.head_id);
          } else {
            setHeadOfDep(userId);
          }
        })
        .catch((e) => {
          setHeadOfDep(userId);
          console.log("ошибка сравнения ", e);
        });
    }
  }, [depId]);
  // функция поиска исполнителя по наименованию должности. второй параметр не обязателен, третий параметр - нужно без этого параметра
  const findExecutor = (
    valueForSearch,
    secondValue = null,
    withoutValue = null
  ) => {
    if (!withoutValue) {
      const executor = users.find((user) => {
        let userNameArr = user.name.toLowerCase().split(" ");
        if (
          userNameArr.includes(valueForSearch.toLowerCase()) ||
          (secondValue &&
            userNameArr.includes(
              `${valueForSearch.toLowerCase()} ${secondValue.toLowerCase()} `
            ))
        ) {
          return user;
        }
      });
      // console.log(executor);
      return executor.id;
    } else {
      const executor = users.filter((user) => {
        let userNameArr = user.name.toLowerCase().split(" ");

        // console.log(userNameArr);
        if (
          userNameArr.includes(valueForSearch.toLowerCase()) &&
          userNameArr.includes(secondValue.toLowerCase()) &&
          !userNameArr.includes(withoutValue.toLowerCase())
        ) {
          return true;
        }
      });
      console.log(executor);
      return executor[0].id;
    }
  };

  // console.log(depId, headOfDep);

  // console.log("Инициирование увольнения", headOfDep);
  // console.log(
  //   "Первичное оформление документов",
  //   findExecutor("Исполнительный", "директор")
  // );
  // console.log("Подписание документов", findExecutor("Главный", "бухгалтер"));
  // console.log(findExecutor("специалист", "казначейства", "старший"));
  // console.log(users);
  // console.log(findExecutor("Исполнительный", "директор"));
  // console.log(findExecutor("экономист", "расчетам"));
  // console.log(findExecutor("внутреннего", "контроля", "руководитель"));
  // console.log(findExecutor("Генерального"));

  useEffect(() => {
    console.log(settingsOfFields);
  }, [settingsOfFields]);

  // console.log(createBpSampleForm);
  // console.log(projects);

  // Создание бизнес-процесса по шаблону "Договоры"
  //************************************************************************* */
  const saveBpSample1 = () => {
    let name = "Типовой бизнес-процесс: Договор № ";
    let project_id = projects[0] ? projects[0].id : 1;
    let project_section_id =
      projects[0] && projects[0].sections[0] ? projects[0].sections[0].id : 1;
    let begin = new Date(Date.now());
    let deadline = new Date(Date.now() + 131.5 * 60 * 60 * 1000);

    // Информация о типовой задаче (включает информацию о шаблоне договора)

    let templates = JSON.stringify({
      company_id: company_id,
      name: name,
      initiator_id: userId,
      project_id: project_id,
      project_section_id: project_section_id,
      deadline: deadline,

      template: {
        name: "treaty",
        data: {
          name: "Договор",
          tasks: {
            items: {
              init: {
                //нумерация полей соответствует документу "Виды_полей_по_бизнес-процесса_Договора_от_10_06_22"
                name: "Инициация договора",
                description: "Сбор данных договора",
                executor_id: userId,
                extra: {
                  a1: null,
                  a1_1: null,
                  a1_2: null,
                  a1_2_1: null,
                  a1_3: null,
                  a1_4: null,
                  a1_5: null,
                  a1_6: null,
                  a1_7: null,
                  a1_8: null,
                  a1_8_1: null,
                  a1_9: null,
                  a1_9_1: null,
                  a1_10: null,
                  a1_11: null,
                  a1_12: null,
                  a1_12_1: null,
                  a1_13: null,
                  a1_14: null,
                  a1_15: null,
                  a1_16: null,
                  a1_17: null,
                  a1_18: null,
                  a1_18_1: null,
                  a1_18_2: null,
                  a1_18_3: null,
                  a1_19: null,
                  a1_19_1: null,
                  a1_20: null,
                  a1_21: null,
                  a1_22: null,
                  a1_23: null,
                },
                begin: begin,
                end: new Date(Date.now() + 1 * 60 * 60 * 1000),
              },
              checkOfHeadOfDepartment: {
                name: "Проверка руководителем подразделения",
                description: "Проверка актуальности",
                executor_id: headOfDep,
                extra: {
                  b2: null,
                  b2_1: null,
                  b2_2: null,
                  b2_2_1: null,
                  b2_3: null,
                  b2_3_1: null,
                },
                begin: new Date(Date.now() + 1 * 60 * 60 * 1000),
                end: new Date(Date.now() + 10 * 60 * 60 * 1000),
              },
              checkOfJurist: {
                name: "Проверка юристом",
                description: "Нужно проверить юридическую валидность договора",
                executor_id: findExecutor("Юрист"),
                extra: {
                  c3: null, //файл спарк
                  c31_1: null, //не применимо
                  c31_2: null, //Текстовое поле если не применимо
                  c3_1_1: null, //Выбор нескольких вариантов из списка
                  c3_1_2: null, //текстовое поле
                  c3_2: null, //да/ (нет Договор возвращается на инициатора.)
                  c3_2_1: null, // yes прикрепить файл
                  c3_3: null, //да/нет
                  c3_3_1: null, // yes текст
                  c3_3_2: null, // yes файл
                  c3_4: null, //Имеются/Отсутствуют
                  c3_4_1: null, //если имеются
                },
                begin: new Date(Date.now() + 10 * 60 * 60 * 1000),
                end: new Date(Date.now() + 19 * 60 * 60 * 1000),
              },
              checkOfChiefAccountant: {
                name: "Проверка главным бухгалтером",
                description: "Проверка главным бухгалтером",
                executor_id: findExecutor("Главный", "бухгалтер"),
                extra: {
                  d4: null,
                  d4_1: null,
                  d4_2: null,
                  d4_3: null,
                  d4_4: null,
                  d4_5: null,
                  d4_6: null,
                  d4_7: null,
                  d4_8: null,
                  d4_9: null,
                  d4_10: null,
                  d4_11: null,
                  d4_12: null,
                  d4_13: null,
                  d4_14: null,
                },
                begin: new Date(Date.now() + 19 * 60 * 60 * 1000),
                end: new Date(Date.now() + 28 * 60 * 60 * 1000),
              },
              checkOfExecutiveDirector: {
                name: "Проверка исполнительным директором",
                description: "Проверка исполнительным директором",
                executor_id: findExecutor("Исполнительный", "директор"),
                extra: {
                  e5: null,
                  e5_1: null,
                  e5_2: null,
                  e5_3: null,
                  e5_4: null,
                  e5_5: null,
                  e5_5_1: null,
                },
                begin: new Date(Date.now() + 28 * 60 * 60 * 1000),
                end: new Date(Date.now() + 37 * 60 * 60 * 1000),
              },
              checkOfFinanceDirector: {
                name: "Проверка финансовым директором",
                description: "Проверка финансовым директором",
                executor_id: findExecutor("Финансовый", "директор"),
                extra: {
                  f6: null,
                  f6_1: null,
                  f6_2: null,
                  f6_3: null,
                },
                begin: new Date(Date.now() + 37 * 60 * 60 * 1000),
                end: new Date(Date.now() + 46 * 60 * 60 * 1000),
              },
              checkFinalOfJurist: {
                name: "Финальная проверка юристом",
                description: "Финальная проверка юристом",
                executor_id: findExecutor("Юрист"),
                extra: {
                  g7_1_1: null,
                  g7_1_2: null,
                  g7_1_3: null,
                  g7_2_1: null,
                  g7_2_2: null,
                  g7_2_3: null,
                },
                begin: new Date(Date.now() + 46 * 60 * 60 * 1000),
                end: new Date(Date.now() + 55 * 60 * 60 * 1000),
              },
              signingOfTreaty: {
                name: "Подписание договора",
                description: "Подписание договора",
                executor_id: findExecutor("Секретарь"),
                extra: {
                  h8: null,
                  h8_1: null,
                  h8_1_1: null,
                  h8_1_2: null,
                  h8_2: null,
                  h8_2_1: null,
                  h8_3: null,
                  h8_4: null,
                  h8_5: null,
                  h8_5_1: null,
                  h8_6: null,
                  h8_6_1: null,
                  h8_6_2: null,
                  h8_7: null,
                  h8_7_1: null,
                  h8_8: null,
                  h8_8_1: null,
                  h8_9: null,
                  h8_9_1: null,
                },
                begin: new Date(Date.now() + 55 * 60 * 60 * 1000),
                end: new Date(Date.now() + 118 * 60 * 60 * 1000),
              },
              enteringDataInto1CUT: {
                name: "Внесение данных в 1С УТ",
                description: "Внесение данных в 1С УТ",
                executor_id: findExecutor("Программист", "1С", "Старший"),
                extra: {
                  i9: null,
                  i9_1: null,
                },
                begin: new Date(Date.now() + 118 * 60 * 60 * 1000),
                end: new Date(Date.now() + 122.5 * 60 * 60 * 1000),
              },
              finalRegistrationByJurist: {
                name: "Финальная регистрация юристом",
                description: "Финальная регистрация юристом",
                executor_id: findExecutor("Юрист"),
                extra: {
                  j10: null,
                  j10_1: null,
                },
                begin: new Date(Date.now() + 122.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 127 * 60 * 60 * 1000),
              },
              finalFinalStage: {
                name: "Внесение сведений в реестр договоров, сканирование, сохранение на диске",
                executor_id: findExecutor("Руководитель", "Юр"),
                description:
                  "Внесение сведений в реестр договоров, сканирование, сохранение на диске",
                begin: new Date(Date.now() + 127 * 60 * 60 * 1000),
                end: new Date(Date.now() + 131.5 * 60 * 60 * 1000),
              },
            },
          },
        },
      },
    });

    let config = {
      method: "post",
      url: `${apiBp}/bp-template-options`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": localStorage.getItem("access_token_jwt"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: templates,
    };

    axios(config)
      .then((res) => {
        // console.log("res", res);

        setBpListChange((prev) => !prev);

        CreateNotif(`${name} создан`, "success");

        setSelectedBp(+res.data.data.id);

        // console.log("res.data.data.id", res.data.data);

        setBp(res.data.data);
        setCreateBpFromSample(true);

        // console.log("Типовая задача: ", res.data.data);

        axios
          .patch(
            `${apiBp}/bp/${res.data.data.id}`,
            { name: name + res.data.data.id },
            {
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": localStorage.getItem("access_token_jwt"),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )

          .catch((e) => {
            console.log("ошибка изменения имени", e);
          });
      })

      .catch((err) => {
        CreateNotif(
          "Ошибка в посылке данных бизнес-процесса на сервер",
          "error"
        );
        console.log("Ошибка при создании бизнес-процесса: ", err);
      });
  };
  //************************************************************************* */

  // Создание бизнес-процесса по шаблону "Прием на работу"
  //************************************************************************* */
  const saveBpSample2 = () => {
    let name2 = "Типовой бизнес-процесс: Прием на работу";
    let project_id = projects[0] ? projects[0].id : 1;
    let project_section_id =
      projects[0] && projects[0].sections[0] ? projects[0].sections[0].id : 1;
    let begin = new Date(Date.now());
    let deadline = new Date(Date.now() + 725.5 * 60 * 60 * 1000);

    // Информация о типовой задаче (включает информацию о шаблоне договора)

    let templates = JSON.stringify({
      company_id: company_id,
      name: name2,
      initiator_id: userId,
      project_id: project_id,
      project_section_id: project_section_id,
      deadline: deadline,

      template: {
        name: "work",
        data: {
          name: "Приём на работу",
          tasks: {
            items: {
              initial: {
                //нумерация полей соответствует документу "Виды_полей_по_бизнес-процесса_Договора_от_10_06_22"
                name: "Инициирование",
                description: "Сбор данных сотрудника",
                executor_id: headOfDep,
                extra: {
                  a1: null,
                  a1_1: null,
                  a1_2: null,
                  a1_3: null,
                  a1_4: null,
                  a1_5: null,
                  a1_6: null,
                  a1_7: null,
                  a1_7_1: null,
                  a1_8: null,
                },
                begin: begin,
                end: new Date(Date.now() + 1 * 60 * 60 * 1000),
              },
              checkSecurity: {
                name: "Проверка кандидата СБ",
                description: "Формирование отчета",
                executor_id: findExecutor("Исполнительный", "директор"),
                extra: {
                  b2: null, // прикрепление файла
                  b2_1: null, // Выпадающий список Отчет положительный Отчет отрицательный
                  b2_2: null,
                },
                begin: new Date(Date.now() + 1 * 60 * 60 * 1000),
                end: new Date(Date.now() + 19 * 60 * 60 * 1000),
              },
              acceptByHeadOfDep: {
                name: "Подтверждение/не подтверждение о приеме сотрудника Руководителем",
                description: "Принятие решения о приеме сотрудника на работу",
                executor_id: findExecutor("Генеральный", "менеджер"),
                extra: {
                  c3: null, //Выпадающий список Подтверждаю Не подтверждаю
                  c3_1: null, //Выпадающий список Принимаю Не согласен
                },
                begin: new Date(Date.now() + 19 * 60 * 60 * 1000),
                end: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
              },
              agreementByDeputyGenManager: {
                name: "Согласование ЗГМ",
                description: "Согласование Зам.Генерального Менеджера",
                executor_id: findExecutor("Генерального"),
                extra: {
                  d4: null, //Выпадающий список Принимаю Не согласен
                  d4_1: null, //Автозаполнение Если ЗГМ кликает «принимаю», то организация назначается из исходных данных по БП Договора Инициирование. Формат взаимоотношений, и задача переходит на следующий этап.
                  d4_1_1: null, //текстовое поле без ограничения символов
                  d4_2: null, //текстовое поле без ограничения символовА
                },
                begin: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
              },
              forkedStage_SMZ_IP: {
                name: "Этап согласования при приёме по СМЗ/ИП/вне штата",
                description: "Этап согласования при приёме по СМЗ/ИП/вне штата",
                executor_id: findExecutor("Главный", "бухгалтер"),
                extra: {
                  e5: null,
                  e5_1: null,
                  e5_2: null,
                  e5_3: null,
                  e5_4: null,
                  e5_5: null,
                  e5_6: null,
                },
                begin: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 46 * 60 * 60 * 1000),
              },
              forkedStage_GPH_TD: {
                name: "Этап согласования при приёме по ГПХ-ТД",
                description: "Этап согласования при приёме по ГПХ-ТД",
                executor_id: findExecutor("Главный", "бухгалтер"),
                extra: {
                  f6: null,
                  f6_1: null,
                  f6_2: null,
                },
                begin: new Date(Date.now() + 46 * 60 * 60 * 1000),
                end: new Date(Date.now() + 50.5 * 60 * 60 * 1000),
              },
              signing: {
                name: "Подписание и прикрепление договора",
                description: "Подписание и прикрепление договора",
                executor_id: userId,
                extra: {
                  g7: null,
                  g7_1: null,
                },
                begin: new Date(Date.now() + 50.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 68.5 * 60 * 60 * 1000),
              },
              completion_3monthTimer: {
                name: "Завершение «таймера на 3 месяца»",
                description: "Завершение «таймера на 3 месяца»",
                executor_id: userId,
                extra: {
                  h8: null, // Прикрепление файла
                  h8_1: null, // Прикрепление файла
                },
                begin: new Date(Date.now() + 68.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 635.5 * 60 * 60 * 1000),
              },
              acceptByDeputyGenManager: {
                name: "Подтверждение Зам. Генерального Менеджера",
                description: "Подтверждение Зам. Генерального Менеджера",
                executor_id: findExecutor("Генерального"),
                extra: {
                  i9: null,
                  i9_1: null,
                },
                begin: new Date(Date.now() + 635.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 698.5 * 60 * 60 * 1000),
              },
              employeeRegistration: {
                name: "Оформление сотрудника",
                description: "Оформление сотрудника",
                executor_id: findExecutor("Главный", "бухгалтер"),
                extra: {
                  j10: null,
                  j10_1: null,
                },
                begin: new Date(Date.now() + 698.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 716.5 * 60 * 60 * 1000),
              },
              processTerminating: {
                name: "Завершение процесса",
                description: "Завершение процесса",
                executor_id: userId,
                extra: {
                  k11: null,
                  k11_1: null,
                },
                begin: new Date(Date.now() + 716.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 725.5 * 60 * 60 * 1000),
              },
            },
          },
        },
      },
    });

    let config = {
      method: "post",
      url: `${apiBp}/bp-template-options`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": localStorage.getItem("access_token_jwt"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: templates,
    };

    axios(config)
      .then((res) => {
        // console.log("res", res);

        setBpListChange((prev) => !prev);

        CreateNotif(`${name2} создан`, "success");

        // setIdBp(+res.data.data.id);
        setSelectedBp(+res.data.data.id);
        setBp(res.data.data);
        setCreateBpFromSample(true);

        // console.log("bp***: ", bp);
        // console.log("Типовая задача: ", res.data.data);
      })
      .catch((err) => {
        CreateNotif(
          "Ошибка в посылке данных бизнес-процессса на сервер",
          "error"
        );
        console.log("Ошибка при создании бизнес-процесса: ", err);
      });
  };
  //************************************************************************* */

  // Создание бизнес-процесса по шаблону "Увольнение"
  //************************************************************************* */
  const saveBpSample3 = () => {
    let name = "Типовой бизнес-процесс: Увольнение";
    let project_id = projects[0] ? projects[0].id : 1;
    let project_section_id =
      projects[0] && projects[0].sections[0] ? projects[0].sections[0].id : 1;
    let begin = new Date(Date.now());
    let deadline = new Date(Date.now() + 46 * 60 * 60 * 1000);

    // Информация о типовой задаче (включает информацию о шаблоне "Увольнение")

    let templates = JSON.stringify({
      company_id: company_id,
      name: name,
      initiator_id: userId,
      project_id: project_id,
      project_section_id: project_section_id,
      deadline: deadline,
      template: {
        name: "dismissal",
        data: {
          name: "Увольнение",
          tasks: {
            items: {
              initDismissal: {
                name: "Инициирование увольнения",
                description: "Сбор данных договора",
                executor_id: headOfDep,
                extra: {
                  a1: null, //Инициирование увольнения выпадающий список     (Сотрудник)
                  a1_1: null, //Текстовое поле без ограничения числа символов  (Работодатель)
                  a1_2: null, //Выпадающий список   (Формат взаимоотношений)
                  a1_3: null, //Выпадающий список   (Основание для увольнения)
                  a1_4: null, //Прикрепление файлов (Заявление на увольнение)
                  a1_5: null, //друж / не друж      (Соглашение сторон )
                  a1_6: null, //друж / не друж      (Сокращение)
                  a1_7: null, //Прикрепление файлов (Статья)
                  a1_8: null, //Прикрепление файлов (Соглашение о расторжении договора ГПХ)
                  a1_9: null, //Прикрепление файлов (Соглашение о расторжении ДОУ)
                  a1_10: null, //Прикрепление файлов (Внутренний обходной лист, подписанный..)
                  a1_11: null, //Прикрепление файлов (дата последнего рабочего дня)
                  a1_12: null, //Прикрепление файлов (время последнего рабочего дня)
                  a1_13: null, //Текстовое поле без ограничения числа символов  (Комментарии)
                },
                begin: begin,
                end: new Date(Date.now() + 1 * 60 * 60 * 1000),
              },
              initialProcessingOfDocuments: {
                name: "Первичное оформление документов",
                description: "Первичное оформление документов",
                executor_id: findExecutor("Главный", "бухгалтер"),
                extra: {
                  d4: null, // Заявление на увольнение  (Основание для увольнения)
                  d4_1: null, // Кнопка                   (Подтверждение ознакомления)
                  d4_2: null, // Прикрепление файлов      (Подписание)
                  d4_3: null, // Прикрепление файлов
                  d4_4: null, // Прикрепление файлов
                  d4_5: null, // Текстовое поле           (Комментарий)
                  d4_6: null, // timer                    (Установка таймера)
                },
                begin: new Date(Date.now() + 1 * 60 * 60 * 1000),
                end: new Date(Date.now() + 5.5 * 60 * 60 * 1000),
              },

              signingOfTreatyDismissal: {
                name: "Подписание документов",
                description: "Подписание документов",
                executor_id: findExecutor("Главный", "бухгалтер"),
                extra: {
                  c3: null, // Прикрепление файлов
                  c3_1: null, // Текстовое поле     (Номер задачи)
                  c3_2: null, // соглас . не соглас (Согласование)
                },
                begin: new Date(Date.now() + 5.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 14.5 * 60 * 60 * 1000),
              },
              confirmationOfPaymentOfAccountingCalculation: {
                name: "Подтверждение оплаты бухгалтерского расчета",
                description: "Подтверждение оплаты бухгалтерского расчета",
                executor_id: findExecutor(
                  "специалист",
                  "казначейства",
                  "старший"
                ),
                extra: {
                  d4: null, // Кнопка (Подтверждение выплаты)
                },
                begin: new Date(Date.now() + 14.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 19 * 60 * 60 * 1000),
              },
              employeeAccountActions: {
                name: "Действия с учетной записью сотрудника ",
                description: "Действия с учетной записью сотрудника ",
                executor_id: findExecutor("Исполнительный", "директор"),
                extra: {
                  e5_1: null, // Выпадающий список + текстовое поле (Действие)
                  e5_1_1: null, //
                  e5_2: null, // Кнопка (Выполнено)
                },
                begin: new Date(Date.now() + 19 * 60 * 60 * 1000),
                end: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
              },
              preparationAndApprovalFinalPaymentForUT: {
                name: "Подготовка и согласование итоговой выплаты по УТ",
                description: "Подготовка и согласование итоговой выплаты по УТ",
                executor_id: findExecutor("экономист", "расчетам"),
                extra: {
                  f6: null, // Кнопка   (расчет подготовлен)
                  f6_1: null, // Булево (согл / не согл)
                  f6_2: null, // Булево (согл / не согл)
                  f6_3: null, // input number (Число)
                  f6_4: null, // Текстовое поле (Номер заявки в 1С)
                  f6_5: null, // Булево (согл / не согл)
                },
                begin: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 32.5 * 60 * 60 * 1000),
              },
              finalSettlementWithEmployee: {
                name: "Итоговый расчет с сотрудником",
                description: "Итоговый расчет с сотрудником",
                executor_id: findExecutor(
                  "внутреннего",
                  "контроля",
                  "руководитель"
                ),
                extra: {
                  g7: null, // input number (число)
                  g7_1: null, // Прикрепление файлов
                },
                begin: new Date(Date.now() + 32.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
              },
              completionOfBP: {
                name: "Завершение бизнес-процесса",
                description: "Завершение бизнес-процесса",
                executor_id: userId,
                extra: {
                  h8: null, // Чекбокс (Подтверждение закрытия)
                },
                begin: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
                end: new Date(Date.now() + 46 * 60 * 60 * 1000),
              },
            },
          },
        },
      },
    });

    let config = {
      method: "post",
      url: `${apiBp}/bp-template-options`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": localStorage.getItem("access_token_jwt"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: templates,
    };

    axios(config)
      .then((res) => {
        // console.log("res", res);

        setBpListChange((prev) => !prev);

        CreateNotif(`${name} создана`, "success");

        // setIdBp(+res.data.data.id);
        setSelectedBp(+res.data.data.id);
        setBp(res.data.data);
        setCreateBpFromSample(true);

        // console.log("bp***: ", bp);
        // console.log("Типовая задача: ", res.data.data);
      })
      .catch((err) => {
        CreateNotif(
          "Ошибка в посылке данных бизнес-процесса на сервер",
          "error"
        );
        console.log("Ошибка при создании бизнес-процесса: ", err);
      });
  };
  //************************************************************************* */

  return (
    <div
      className={
        createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
          ? "business__main-content__list-block__item business__main-content__list-block__item-active"
          : "business__main-content__list-block__item"
      }
    >
      <div
        className="business__main-content__list-block__item__message business__main-content__list-block__item__message-active"
        id={"business-item-btn-" + el.id}
        onClick={() => {
          if (el.id == "1") {
            saveBpSample1();
            setBpListSamples(false);

            /* setIdCall(el.id);
            setOpenMenuBp(true);
            setOpenMenuTasks(false);*/
            /* CreateNotif(
              "Данная функция еще в процессе разработки!",
              "warning"
            );  */
          } else if (el.id == "2") {
            saveBpSample2();
            setBpListSamples(false);

            /* setIdCall(el.id);
            setOpenMenuBp(true);
            setOpenMenuTasks(false);*/
            /* CreateNotif(
              "Данная функция еще в процессе разработки!",
              "warning"
            );  */
          } else if (el.id == "3") {
            saveBpSample3();
            setBpListSamples(false);

            /* setIdCall(el.id);
            setOpenMenuBp(true);
            setOpenMenuTasks(false);*/
            /* CreateNotif(
              "Данная функция еще в процессе разработки!",
              "warning"
            );  */
          }
        }}
      >
        <div className="business__main-content__list-block__item__name template-name">
          <p className="p-black">{el.name}</p>
        </div>

        <div
          className="business__main-content__list-block__item__name start_sample"
          // style={{ marginLeft: "-50px" }}
        >
          <img src={Power} style={{ marginRight: "10px" }} />
          <p className="p-black">Запустить</p>
        </div>

        <div className="business__main-content__list-block__item__name">
          <p className="p-black"></p>
        </div>
      </div>
    </div>
  );
};

export default BpSampleItem;
