import React from "react";

const Approved = ({props}) => {
  return (
<svg 
{...props}
width={props ? props : "40"} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#436EA0"/>
<path d="M11.75 28.25V12.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.75 23.7522C17.75 19.2522 22.25 28.2522 28.25 23.7522V12.5022C22.25 17.0022 17.75 8.00216 11.75 12.5022" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default Approved;