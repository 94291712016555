import React from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import LoginPlate from "./pages/Login/index";
import Notifier from "./common/components/Notifier/Notifier";
import Main from "./common/components/Main";
import "./sass/main.sass";
import { useStateContext } from "./useStateContext";
import { useState } from "react";
import Questionnaire from "./pages/Login/components/Questionnaire";
import generatedGitInfo from "./generatedGitInfo.json";
import axios from "axios";
import {
  responseHandler,
} from "./responseHandler";
import { cookieMaster } from "./utils/CookieMaster";
import { setVerified } from "./common/actions";

const composeEnhancers =
  (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

const sagaMiddleware = createSagaMiddleware();

export const urlApp: string = process.env.REACT_APP_ENV
  ? `${process.env.REACT_APP_ENV}`
  : "https://way.easy-task.ru"; // глобальная переменная урла, чтобы использовать везе и менять в одном месте
export const timeUpdate = !!process.env.REACT_APP_ENV_TIME
  ? +process.env.REACT_APP_ENV_TIME
  : 60000;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

window["version"] = () => {
  return generatedGitInfo.gitCommitHash;
};

function App() {
  // Тут вносятся данные, общие для всей программы

  // Показывать или нет Профиль пользователя / компании
  const [show1, setshow1] = useState<boolean>(false);

  // индикатор - если меняется - (не важно - true или false) - заходим на страницу tasks
  const [openPageTasks, setOpenPageTasks] = useState(1);

  // Время, через которое после входа на сайт пользователь принимает решение зарегистироваться в системе (в секундах).
  // Вычисляется с момента входа на сайт до момента нажатия на кнопку "Продолжить" при регистрации.
  const [startOnboarding_time, setStartOnboarding_time] = useState<Date>(
    new Date()
  );

  // Время, затраченное на постановку задачи в системе (в секундах).
  // Вычисляется путём вычисления времени от начала создания в системе задачи до момента нажатия на кнопку "Сохранить"
  const [startTaskTime, setStartTaskTime] = useState<Date>(new Date());

  // Переключатель между рабочими и личными задачами
  const [tableOrTreeToggler, setTableOrTreeToggler] = useState<any>("");

  const [checkedPersonalTask, setCheckedPersonalTask] =
    useState<boolean>(false);

  const [checkedTaskBegin, setCheckedTaskBegin] = useState<boolean>(false);

  const [checkedTaskEnd, setCheckedTaskEnd] = useState<boolean>(false);

  const [warningWindow, setWarningWindow] = useState<boolean>(false);

  const [allEmployee, setAllEmployee] = useState<boolean>(false); // Выбранны или нет все сотрудники в фильтре

  const [anketa_send, setAnketa_send] = useState<boolean>(false); // Анкета отправлена в данном сеансе

  const [isChangeProject, setIsChangeProject] = useState<boolean>(false); // Признак изменения проекта

  const [isMeetingTask, setIsMeetingTask] = useState<boolean>(false); // задача создана из Easy Meet

  const [idQuestion, setIdQuestion] = useState<number>(0); // id повестки
  const [projectIdCurrent, setProjectIdCurrent] = useState<number>(0); //  id проекта 

  const [projectSectionCurrent, setProjectSectionCurrent] = useState<number>(0); //  id секции проекта 

  const [meetingId, setMeetingId] = useState<number>(0); //  id совещания

  // Тут надо повторить данные, общие для всей программы
  let context = {
    show1,
    setshow1, // Показывать или нет Профиль пользователя / компании
    openPageTasks,
    setOpenPageTasks, // индикатор - если меняется - (не важно - true или false) - заходим на страницу tasks
    startOnboarding_time, //  Время, через которое после входа на сайт пользователь принимает решение зарегистироваться в системе (в секундах).
    setStartOnboarding_time, // Вычисляется с момента входа на сайт до момента нажатия на кнопку "Продолжить" при регистрации.
    checkedPersonalTask,
    setCheckedPersonalTask,
    checkedTaskBegin,
    setCheckedTaskBegin,
    checkedTaskEnd,
    setCheckedTaskEnd,
    warningWindow,
    setWarningWindow,
    startTaskTime, // Время, затраченное на постановку задачи в системе (в секундах).
    setStartTaskTime, // Вычисляется путём вычисления времени от начала создания в системе задачи до момента нажатия на кнопку "Сохранить"
    allEmployee,
    setAllEmployee, // Выбранны или нет все сотрудники в фильтре
    tableOrTreeToggler, // Переключатель между рабочими и личными задачами
    setTableOrTreeToggler,
    anketa_send,
    setAnketa_send, // Анкета отправлена в данном сеансе
    isChangeProject,
    setIsChangeProject, // Признак изменения проекта
    isMeetingTask,
    setIsMeetingTask,
    idQuestion,
    setIdQuestion,
    meetingId,
    setMeetingId,
    projectIdCurrent,
    setProjectIdCurrent,
    projectSectionCurrent,
    setProjectSectionCurrent
  };

  // перехватчик axios запросов
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.config.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        cookieMaster.deleteCookie("access_token");
        window.location.pathname = "/login";
        localStorage.removeItem("access_token");
      } else if (error.config.status === 403) {
        store.dispatch(setVerified(false));
      }
      responseHandler(error.response.data, error.config.url);
      return Promise.reject(error);
    }
  );
  return (
    <Provider store={store}>
      <useStateContext.Provider value={context}>
        <SnackbarProvider>
          <BrowserRouter>
            <Notifier />
            <Switch>
              <Route
                path={[
                  "/login",
                  "/register",
                  "/company",
                  "/company-request",
                  "/forgot-password",
                  "/password/recovery",
                  "/password-recovery-from-user",
                  "/password-recovery-from-user2",
                  // '/email',
                  "/verify_email",
                  "/unique_link/",
                ]}
                component={LoginPlate}
              />
              <Route path="/questionnaire" component={Questionnaire} />
              <Route exact path="/" render={() => <Redirect to="/tasks" />} />
              <Route path="/" component={Main} />
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      </useStateContext.Provider>
    </Provider>
  );
}

export default App;
