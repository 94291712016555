import React, { useEffect, useContext } from "react";
import axios from "axios";
import BpItem from "../bp-item/BpItem.jsx";
import SortBtn from "../ui/sort-btn/SortBtn.jsx";
import { StatusContext } from "../../context/status.js";
import { useHistory, useLocation } from "react-router-dom";

const BusinessMainList = () => {
  const {
    filter,
    setFilter,
    createBpStatus,
    createBpSampleStatus,
    apiBp,
    bpList,
    setBpList,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createTaskSampleFormStatus,
    setCreateTaskStatus,
    setCreateTaskSampleFormStatus,
    setCreateBpSampleStatus,
    tasks,
    bpListChange,
    setBpListChange,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    setOpenMenuTasks,
    setOpenMenuBp,
    bearer,
    company_id,
    setTaskId,
    relatedBp,
    setRelatedBp,
    setRelatedTaskId,
    setSelectedBp,
    openFilter,
    setOpenFilter,
    bpDarfts,
    bpWorks,
    bpFilter,
    textBpFilter,
    selectColumns,
    paginationMeta,
    setPaginationMeta,
  } = useContext(StatusContext);

  const location = useLocation();
  const history = useHistory();
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  // Запрос списка БП
  // Фильтры по БП : по выбору, "В работе" или "Черновики". Если нет- запрос БП без вильтра
  useEffect(() => {
    // Если выбран фильтр по БП- "в работе"
    if (bpWorks === true) {
      axios
        .get(`${apiBp}/bp?filter[status][]=2`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
          setPaginationMeta(response.data.meta);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если выбран фильтр по БП- "Черновики"
    else if (bpDarfts === true) {
      axios
        .get(`${apiBp}/bp?filter[status][]=0`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
          setPaginationMeta(response.data.meta);
        })

        .catch((e) => {
          console.log(e);
        });
    }

    // Если выбран фильтр по БП- "Произвольный"
    else if (bpFilter === true) {
      axios
        .get(`${apiBp}/bp?${textBpFilter}`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
          setPaginationMeta(response.data.meta);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если не было выбрано никакого фильтра
    else if (bpWorks === false && bpDarfts === false && bpFilter === false) {
      axios
        .get(`${apiBp}/bp`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data); //.sort((a, b) => b.id - a.id)
          } else {
            setBpList(null);
          }
          setPaginationMeta(response.data.meta);
          console.log(response.data.meta)

        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [
    setBpList,
    filter,
    createTaskStatus,
    createTaskSampleFormStatus,
    setCreateTaskStatus,
    setCreateTaskSampleFormStatus,
    createBpSampleStatus,
    setCreateBpSampleStatus,
    tasks,
    bpListChange,
    setBpListChange,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    openMenuTasks,
    setOpenMenuTasks,
    openMenuBp,
    setOpenMenuBp,
    openFilter,
    setOpenFilter,
    bpDarfts,
    bpWorks,
    bpFilter,
  ]);

  const sortFunc = (e) => {
    setFilter(e.dataset.sort);
  };
  // переход из вкладки задач в бп
  const taskIdInLocation = +location.search.slice(1);
  useEffect(() => {
    if (bpList) {
      bpList.forEach((bp) => {
        let tasks = bp.tasks;
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].id === taskIdInLocation) {
            setRelatedBp((prev) => bp);
            setRelatedTaskId((prev) => taskIdInLocation);
          }
        }
      });
    }
  }, [bpList]);

  return (
    <div className="business__main-content__list-block">
      <div
        className={
          createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
            ? "business__main-content__list-block__title business__main-content__list-block__title-active"
            : "business__main-content__list-block__title"
        }
      >
        <div className="status_head">
          <div
            style={{
              display: "flex",
              marginLeft: "50px",
              // justifyContent: "space-between",
              // width: "100%",
            }}
          >
            {selectColumns.includes("id1") && (
              <button
                className="p-grey sort"
                data-sort="id1"
                onClick={(e) => sortFunc(e.target)}
                style={{  width: "40px" }}
              >
                №{filter === "id1" ? <SortBtn name={"id1"} /> : <></>}
              </button>
            )}
            {selectColumns.includes("name") && (
              <button
                className="p-grey sort"
                data-sort="name"
                onClick={(e) => sortFunc(e.target)}
                style={{  marginLeft: "50px" }}
              >
                Наименование
                {filter === "name" ? <SortBtn name={"Наименование"} /> : <></>}
              </button>
            )}
          </div>
        </div>
        <div className="status_header">
          {selectColumns.includes("author") && (
            <button
              className="p-grey sort"
              data-sort="author"
              style={{ cursor: "default", width: "56px" }}
              onClick={(e) => sortFunc(e.target)}
            >
              Автор
            </button>
          )}

          {selectColumns.includes("initiator_id") && (
            <button
              className="p-grey sort"
              data-sort="initiator_id"
              style={{ cursor: "default", width: "129px" }}
              onClick={(e) => sortFunc(e.target)}
            >
              Исполнитель
              {filter === "initiator_id" ? <SortBtn /> : <></>}
            </button>
          )}
          {selectColumns.includes("status") && (
            <button
              className="p-grey sort"
              data-sort="status"
              onClick={(e) => sortFunc(e.target)}
              style={{ marginLeft: "", width: "163px" }}
            >
              Статус
              {filter === "status" ? <SortBtn name={"Статус"} /> : <></>}
            </button>
          )}

          {selectColumns.includes("deadline") && (
            <button
              className="p-grey sort"
              data-sort="deadline"
              onClick={(e) => sortFunc(e.target)}
              style={{ width: "103px" }}
            >
              Дедлайн
              {filter === "deadline" ? <SortBtn name={"Дедлайн"} /> : <></>}
            </button>
          )}
          {selectColumns.includes("duration") && (
            <button
              className="p-grey sort"
              data-sort="duration"
              style={{ cursor: "default", width: "106px" }}
              onClick={(e) => sortFunc(e.target)}
            >
              Длительность
            </button>
          )}
          {selectColumns.includes("project_id") && (
            <button
              className="p-grey sort"
              data-sort="project_id"
              style={{ cursor: "default", width: "151px" }}
              // style={{marginLeft: "100px"}}
              onClick={(e) => sortFunc(e.target)}
            >
              Проект/Раздел
              {filter === "project_id" ? <SortBtn /> : <></>}
            </button>
          )}
        </div>
      </div>
      {/* Переход к общему списку БП и обнуление данных */}
      {relatedBp && (
        <div className="redirect-to-general-list">
          <span
            onClick={() => {
              setRelatedBp();
              setSelectedBp();
              setTaskId();
              setOpenMenuTasks();
              setOpenMenuBp();
              history.push("/bp");
            }}
          >
            Вернуться к общему списку БП
          </span>
        </div>
      )}
      <div className="business__main-content__list-block__container">
        <div>
          {bpList && relatedBp ? (
            <BpItem
              el={relatedBp}
              key={relatedBp.id}
              redirect={!!taskIdInLocation}
            />
          ) : (
            bpList.map((bpItem) => <BpItem el={bpItem} key={bpItem.id} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessMainList;
