import React, { useMemo, useState } from "react";
import styled from "styled-components";
import "../../style.sass";
import NameWithInput from "../../../../../common/components/newComponent/Inputs/NameWithInput";
import { Input, Button } from "@material-ui/core";
import NameIcon from "../../../../../common/components/Icon/imgs/name.svg";
import DescriptionIcon from "../../../../../common/components/Icon/imgs/description.svg";
import CalendarIcon from "../../../../../common/components/Icon/imgs/calendar_blank.svg";
import { fetchData } from "../../../../../utils/fetchData";
import { CreateNotif } from "../../../../../utils/createNotification";
import { useEffect } from "react";
import moment from "moment";
import { convertDateToUTC } from "../../../helpers";

interface Props {
  isShowAddObject: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  cellData: any;
  setCellData: (mode: any) => void;
  addEventHandler: (event: any, ans?: any) => void;
}
const EventForm = styled.div``;

const InputBox = styled.div`
  margin: 18px 38px 18px 18px;
  display: flex;
  gap: 20px;
  Input {
    width: 100%;
    padding: 20px 8px 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(41, 42, 52, 0.87);
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px 4px 0px 0px;
  }
`;

const InputBoxTime = styled.div`
  margin: 18px 38px 18px 18px;
  display: flex;
  align-items: center;
  gap: 20px;
  Input {
    width: 100%;
    padding: 20px 8px 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(41, 42, 52, 0.87);
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px 4px 0px 0px;
  }
`;

const Header = styled.div`
  margin-bottom: 30px;
  padding-top: 13px;
`;

const ButtonsDiv = styled.div`
  grid-column: 1/5;
  display: flex;
  justify-content: flex-end;
  margin-top 16px;
`;

const Color = styled.div`
  diplay: flex;
  align-items: center;
  margin-top: 10px;
  Input {
    width: 78px;
    padding: 20px 8px 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(41, 42, 52, 0.87);
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px 4px 0px 0px;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  gap: 10px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #292a34;
`;

const ButtonBox = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  gap: 32px;
  Button {
    color: white;
    width: 163px;
    height: 50px;
    background: #01b0e9;
    // border: 1px solid #01b0e9;
    borderradius: 4px;
    transition: all 0.3s easy 0s;
  }
  Button:hover {
    color: gray;
  }
`;
const scheduler = window.scheduler;
const AddEventForm: React.FC<Props> = ({
  isShowAddObject,
  setIsShowAddObject,
  setCellData,
  cellData,
  addEventHandler,
}) => {
  const currentTime = moment().format("YYYY-MM-DDTHH:mm");

  const [name, setName] = useState<string>("");
  const [description, setDesciption] = useState<string>("");
  const [color, setColor] = useState<string>("#AECBEC");
  const [start_at, setStartAt] = useState<any>();
  const [deadline_at, setDeadlineAt] = useState<any>();
  const [isValid, setIsValid] = useState<boolean>(false);

  function setDateTimeTo2359(selectedDate) {
    const formattedDate = moment(selectedDate, "YYYY-MM-DDTHH:mm");
    const newDateTime = formattedDate.set({ hour: 23, minute: 59 });

    return moment(newDateTime).format("YYYY-MM-DDTHH:mm");
  }

  function add15MinutesToDateTime(selectedDate) {
    const formattedDate = moment(selectedDate, "YYYY-MM-DDTHH:mm");
    const newDateTime = formattedDate.add(15, "minutes");

    return newDateTime.format("YYYY-MM-DDTHH:mm");
  }

  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setStartAt(value);
    if (!moment(value, "YYYY-MM-DD").isSameOrAfter(deadline_at, "day")) {
      setDeadlineAt(setDateTimeTo2359(value));
    } else if (deadline_at < value) {
      setDeadlineAt(add15MinutesToDateTime(value));
    }
  };

  const handleEndDateChange = (event) => {
    const { value } = event.target;
    if (!moment(value, "YYYY-MM-DD").isSameOrAfter(start_at, "day")) {
      setDeadlineAt(add15MinutesToDateTime(start_at));
    } else if (!moment(start_at, "YYYY-MM-DD").isSameOrAfter(value, "day")) {
      setDeadlineAt(setDateTimeTo2359(start_at));
    } else {
      setDeadlineAt(value);
    }
  };

  const AddEvent = async () => {
    fetchData
      .post(
        `/api/v1/day/events`,
        JSON.stringify({
          title: name,
          description: description,
          color: color,
          start_at: convertDateToUTC(new Date(start_at)),
          end_at: convertDateToUTC(new Date(deadline_at)),
        })
      )
      .then((ans) => {
        if (cellData === null) {
          addEventHandler(
            { name, start_at, deadline_at, description, color, id: ans.id },
            null
          );
        } else {
          addEventHandler(
            { name, start_at, deadline_at, description, color, id: ans.id },
            cellData.newEvent
          );
        }
        setIsShowAddObject(false);
        setCellData(null);
        setName("");
        setDesciption("");
        setColor("#0079ff");
        setStartAt("");
        setDeadlineAt("");
        setCellData(null);
      })
      .catch((error) => CreateNotif(error));
  };

  function deleteSelectedEvent() {
    scheduler.deleteEvent(cellData?.newEvent?.id);
    setIsShowAddObject(false);
    setCellData(null);
  }

  const ChangeEvent = () => {
    const currentEvent = scheduler.getEvent(cellData.event_id);
    currentEvent.text = name;
    currentEvent.meta.entityData.description = description;
    currentEvent.meta.entityData.color = color;
    currentEvent.start_date = new Date(start_at);
    currentEvent.end_date = new Date(deadline_at);
    scheduler.updateEvent(cellData.event_id);
    scheduler.callEvent("onEventChanged", [
      cellData.event_id,
      scheduler.getEvent(cellData.event_id),
    ]);
    setIsShowAddObject(false);
    setCellData(null);
  };

  useEffect(() => {
    const momentLocalFormat = "YYYY-MM-DDTHH:mm:ss";
    if (!cellData) {
      setStartAt(moment().format("YYYY-MM-DDTHH:mm"));
      setDeadlineAt(moment().add(1, "hours").format("YYYY-MM-DDTHH:mm"));
    } else if (cellData !== null) {
      if (!cellData.isChange) {
        setStartAt(moment(cellData.start_at).format(momentLocalFormat));
        setDeadlineAt(moment(cellData.end_at).format(momentLocalFormat));
      } else {
        if (cellData.type == "EVENT_TYPE") {
          setName(cellData.title);
          setDesciption(cellData.description);
          setColor(cellData.color);
          setStartAt(moment(cellData.start_at).format(momentLocalFormat));
          setDeadlineAt(moment(cellData.end_at).format(momentLocalFormat));
        }
      }
    }
  }, [cellData]);

  useMemo(() => {
    if (name && color && currentTime <= start_at && start_at <= deadline_at) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [name, color, start_at, deadline_at]);

  const handleBlurStart = () => {
    const inputTime = moment(start_at, "YYYY-MM-DDTHH:mm");
    if (inputTime.isBefore(currentTime)) {
      setStartAt(currentTime);
    }
  };

  const handleBlurEnd = () => {
  const inputTimeEnd = moment(deadline_at, "YYYY-MM-DDTHH:mm");
    if (
      inputTimeEnd.isBefore(start_at)
    ) {
      setDeadlineAt(moment(start_at).add(15, "minutes").format("YYYY-MM-DDTHH:mm"));
    }
  };
  return (
    <EventForm>
      <Header>* - обязательные для заполнения поля</Header>
      <ItemHeader>
        <img src={NameIcon} alt="" />
        <Title>Название*</Title>
      </ItemHeader>
      <InputBox>
        <Input
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Введите название*"
        />
      </InputBox>
      <ItemHeader>
        <img src={DescriptionIcon} alt="" />
        <Title>Описание</Title>
      </ItemHeader>
      <InputBox>
        <Input
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDesciption(e.target.value)}
          placeholder="Введите описание"
        />
      </InputBox>
      <ItemHeader>
        <img src={NameIcon} alt="" />
        <Title>Цвет в календаре*</Title>
      </ItemHeader>{" "}
      <Color>
        <InputBox>
          <Input
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <input
            style={{ width: "56px", height: "56px", padding: "0" }}
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </InputBox>
      </Color>
      <ItemHeader>
        <img src={CalendarIcon} alt="" />
        <Title>Дата и время начала*</Title>
      </ItemHeader>
      <InputBoxTime>
        <Input
          type="datetime-local"
          fullWidth
          inputProps={{
            min: currentTime,
          }}
          value={start_at}
          onChange={(e) => handleStartDateChange(e)}
          onBlur={handleBlurStart}
        ></Input>
      </InputBoxTime>
      <ItemHeader>
        <img src={CalendarIcon} alt="" />
        <Title>Дата и время окончания*</Title>
      </ItemHeader>
      <InputBoxTime>
        <Input
          type="datetime-local"
          fullWidth
          value={deadline_at}
          onChange={(e) => handleEndDateChange(e)}
          inputProps={{
            min: start_at ? start_at : "",
            max: start_at ? setDateTimeTo2359(start_at) : "",
          }}
          onBlur={handleBlurEnd}
        ></Input>
      </InputBoxTime>
      <hr
        style={{
          border: "1px solid #E1E1E1",
          boxShadow: "0px -1px 3px rgba(0, 0, 0, 0.15)",
          marginTop: "58px",
          marginBottom: "37px",
        }}
      />
      <ButtonBox>
        {cellData && cellData.type == "EVENT_TYPE" && cellData.isChange ? (
          <Button
            disabled={!isValid}
            style={{
              marginLeft: "0px",
              width: "163px",
              height: "50px",
              background: "#01B0E9",
              border: "1px solid #01B0E9",
              borderRadius: "4px",
              color: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
            }}
            onClick={() => ChangeEvent()}
          >
            Сохранить
          </Button>
        ) : (
          <Button
            disabled={!isValid}
            style={{
              marginLeft: "0px",
              width: "163px",
              height: "50px",
              background: "#01B0E9",
              border: "1px solid #01B0E9",
              borderRadius: "4px",
              color: !isValid ? "#E0E0E0" : "#FFFFFF",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
            }}
            onClick={() => AddEvent()}
          >
            Добавить
          </Button>
        )}
        <Button
          style={{
            marginLeft: "20px",
            background: "white",
            color: "#292A34",
            width: "64px",
            height: "24px",
            display: "flex",
            top: "12px",
            border: "none",
            position: "relative",
            fontWeight: 400,
          }}
          onClick={() => deleteSelectedEvent()}
        >
          Отмена
        </Button>
      </ButtonBox>
    </EventForm>
  );
};

export default AddEventForm;
