import React from "react";

const RefusalMembers = ({ props }) => {
  return (
    <svg
      width={props ? props : "40"}
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F44336" />
      <path
        d="M24.9556 16.3906L28.5649 20L24.9556 23.6094"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9375 20H28.5625"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3125 27.5625H14.125C13.9427 27.5625 13.7678 27.4901 13.6389 27.3611C13.5099 27.2322 13.4375 27.0573 13.4375 26.875V13.125C13.4375 12.9427 13.5099 12.7678 13.6389 12.6389C13.7678 12.5099 13.9427 12.4375 14.125 12.4375H20.3125"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RefusalMembers;
