import {Action} from 'redux';
import {
    Position,
    Department,
    Role,
    Status,
    User,
    Project,
    Priority,
    Workgroup,
    Section,
    Notification,
    Schedule,
} from './types';

export enum types {
    FETCH_COMMON_INFO = '@@COMMON/FETCH_COMMON_INFO',
    ADD_COMMON_INFO = '@@COMMON/ADD_COMMON_INFO',
    ENQUEUE_SNACKBAR = '@@COMMON/ENQUEUE_SNACKBAR',
    CLOSE_SNACKBAR = '@@COMMON/CLOSE_SNACKBAR',
    REMOVE_SNACKBAR = '@@COMMON/REMOVE_SNACKBAR',
    SET_USERS = '@@COMMON/SET_USERS',
    CHANGE_WORK_SCHEDULE = '@@COMMON/CHANGE_WORK_SCHEDULE',
    SET_DEPARTMENTS = '@@COMMON/SET_DEPARTMENTS',
    SET_WORKGROUPS = '@@COMMON/SET_WORKGROUPS',
    SET_PROJECTS = '@@COMMON/SET_PROJECTS',
    SET_SECTIONS = '@@COMMON/SET_SECTIONS',
    SET_POSITIONS = '@@COMMON/SET_POSITIONS',
    UPDATE_TASK = '@@COMMON/UPDATE_TASK',
    SET_VERIFYED = '@@COMMON/SET_VERIFYED',
    SET_SEARCH_MEETINGS = '@@COMMON/SET_SEARCH_MEETINGS',
    FETCH_EXISTING_USER_DATA = '@@COMMON/FETCH_EXISTING_USER_DATA',
    SET_SHOW_NOTIF_WINDOW = '@@COMMON/SET_SHOW_NOTIF_WINDOW',
    SET_SHOW_COMPANY_WINDOW = '@@COMMON/SET_SHOW_COMPANY_WINDOW',
    SET_CURRENT_USER_INFO = '@@COMMON/SET_CURRENT_USER_INFO',
    SET_NOTIFICATION_READ = '@@COMMON/SET_NOTIFICATION_READ',
    SET_REMOTE_NOTIFICATIONS = '@@COMMON/SET_REMOTE_NOTIFICATIONS',
    SET_LOAD_NEXTPAGE_NOTIFICATIONS = '@@COMMON/SET_LOAD_NEXTPAGE_NOTIFICATIONS',
    SET_ADD_NOTIFICATIONS_REDUCER = '@@COMMON/SET_ADD_NOTIFICATIONS_REDUCER',
    CLEAR_NOTIFICATIONS = '@@COMMON/CLEAR_NOTIFICATIONS',
    UPDATE_TIMESTAMP = '@@COMMON/UPDATE_TIMESTAMP',
    UPDATE_NEW_NOTOFICATION = '@@COMMON/UPDATE_NEW_NOTOFICATION',
    SET_FILTERS = '@@COMMON/SET_FILTERS',
    SET_FILTER_TEMPLATES = '@@COMMON/SET_FILTER_TEMPLATES',
    SET_DEFAULT_FILTER_TEMPLATES = '@@COMMON/SET_DEFAULT_FILTER_TEMPLATES',
    SHOW_FILTERS_WINDOW = '@@COMMON/SHOW_FILTERS_WINDOW',
    SET_ANIMATE_SHOW_COMPANY_WINDOW = '@@COMMON/SET_ANIMATE_SHOW_COMPANY_WINDOW',
    SET_ADDED_MEETING = '@@SET_ADDED_MEETING',
  SET_CREATED_TASK_INFO = "@@SET_CREATED_TASK_INFO"


}

export interface fetchCommonInfoAction
    extends Action<types.FETCH_COMMON_INFO> {
}

export interface changeWorkScheduleAction
    extends Action<types.CHANGE_WORK_SCHEDULE> {
    schedule: Schedule[];
}

export interface addCommonInfoAction extends Action<types.ADD_COMMON_INFO> {
    projects: Project[];
    users: User[];
    statuses: Status[];
    roles: Role[];
    positions: Position[];
    departments: Department[];
    priorities: Priority[];
    workgroups: Workgroup[];
    sections: Section[];
    unread_notifications: Notification[];
    read_notifications: Notification[];
    load_next: string;
    end: boolean;
    company: any;
}

export interface enqueueSnackbarAction extends Action<types.ENQUEUE_SNACKBAR> {
    notification: any;
}
export interface setCreatedTaskInfoAction extends Action<types.SET_CREATED_TASK_INFO> {
    task: any;
  }
export interface setAddedMeetingAction extends Action<types.SET_ADDED_MEETING> {
    meeting: any;
}


export interface closeSnackbarAction extends Action<types.CLOSE_SNACKBAR> {
    key: number;
}

export interface removeSnackbarAction extends Action<types.REMOVE_SNACKBAR> {
    key: number;
}

export interface setUsersAction extends Action<types.SET_USERS> {
    users: User[];
}

export interface setSearchMeetingsAction extends Action<types.SET_SEARCH_MEETINGS>{
    search: string | null
}

export interface setDepartmentsAction extends Action<types.SET_DEPARTMENTS> {
    departments: Department[];
}

export interface setWorkgroupsAction extends Action<types.SET_WORKGROUPS> {
    workgroups: Workgroup[];
}

export interface setProjectsAction extends Action<types.SET_PROJECTS> {
    projects: Project[];
}

export interface setSectionsAction extends Action<types.SET_SECTIONS> {
    sections: Section[];
}

export interface setPositionsAction extends Action<types.SET_POSITIONS> {
    positions: Position[];
}

export interface updateTaskAction extends Action<types.UPDATE_TASK> {
    taskId: number;
    params: any;
    withNotif: boolean;
}

export interface setVerifiedAction extends Action<types.SET_VERIFYED> {
    verified: boolean;
}

export interface fetchExistingUserDataAction
    extends Action<types.FETCH_EXISTING_USER_DATA> {
    id: number;
}

export interface setCurrentUserInfoAction
    extends Action<types.SET_CURRENT_USER_INFO> {
    user: User;
}

export interface setShowNotifWindow
    extends Action<types.SET_SHOW_NOTIF_WINDOW> {
    val: boolean;
}

export interface setShowAnimateCompanyWindow
    extends Action<types.SET_ANIMATE_SHOW_COMPANY_WINDOW> {
    value: boolean;
}

export interface setShowCompanyWindow
    extends Action<types.SET_SHOW_COMPANY_WINDOW> {
    value: boolean;
}

export interface setMarkNotificationRead
    extends Action<types.SET_NOTIFICATION_READ> {
    id_notification: string;
    id_current_user: number;
    readonly?: boolean;
}

export interface setChangeRemoteNotifs
    extends Action<types.SET_REMOTE_NOTIFICATIONS> {
    unread: object;
    read: object;
    load_next?: string;
    end?: boolean;
    offset?: number;
}

export interface setLoadNextPageNotifications
    extends Action<types.SET_LOAD_NEXTPAGE_NOTIFICATIONS> {
    context: string;
}

export interface clearNotificationsAction
    extends Action<types.CLEAR_NOTIFICATIONS> {
}

export interface updateTimestampAction extends Action<types.UPDATE_TIMESTAMP> {
    timestamp: string;
}


export interface setFiltersAction extends Action<types.SET_FILTERS> {
    date_from?: string,
    date_to?: string,
    executors?: [],
    authors?: [],
    statuses?: [],
    actual?: boolean
}


export interface filterTemplateAction extends Action<types.SET_FILTER_TEMPLATES> {
    data: {}
}

export interface defaultfilterTemplateAction extends Action<types.SET_DEFAULT_FILTER_TEMPLATES> {
    data: {}
}


export interface ShowFiltersWindowAction extends Action<types.SHOW_FILTERS_WINDOW> {

}


export interface updateNewNotificationAction
    extends Action<types.UPDATE_NEW_NOTOFICATION> {
    newNotifications: any[];
}

export type CommonAction =
    | fetchCommonInfoAction
    | addCommonInfoAction
    | enqueueSnackbarAction
    | closeSnackbarAction
    | removeSnackbarAction
    | setUsersAction
    | setDepartmentsAction
    | setWorkgroupsAction
    | setProjectsAction
    | setSectionsAction
    | setPositionsAction
    | updateTaskAction
    | setVerifiedAction
    | fetchExistingUserDataAction
    | setCurrentUserInfoAction
    | setShowNotifWindow
    | setMarkNotificationRead
    | setChangeRemoteNotifs
    | setLoadNextPageNotifications
    | changeWorkScheduleAction
    | clearNotificationsAction
    | updateTimestampAction
    | updateNewNotificationAction
    | setFiltersAction
    | filterTemplateAction
    | ShowFiltersWindowAction
    | defaultfilterTemplateAction
    | setShowAnimateCompanyWindow
    | setShowCompanyWindow
    | setAddedMeetingAction
    | setCreatedTaskInfoAction
    | setSearchMeetingsAction;
