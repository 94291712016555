// таблица задач в проекте

import React, { useCallback, useEffect, useRef, useState } from "react";
import ProjectTableHead from "./ProjectAllTableHead";
import ProjectTableContent from "./ProjectAllTableContent";
import { State } from "../../../rootReducer";
import { connect, useSelector } from "react-redux";
import { Task, Project } from "../../../common/types";
import { useParams } from "react-router-dom";
import { changeTaskList, fetchTasks } from "../actions";
import { columnsOfTables } from "../../../common/shared_data";

import styled from "styled-components";
import ProjectTaskTr from "./ProjectAllTaskTr";

type Props = {
  isFetchingTasks: boolean;
  tasks: Task[];
  projects: Project[];
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (id: number, pageNum, abortController?: AbortController) => void;
  activeColumns: string[];
};

// const WrapperTasksTable = styled.div`
//   height: calc(100vh - 214px);
//   overflow-y: auto;
//   overflow-x: hidden;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   background-color: #f4f4f4;
// `;

const WrapperTasks = styled.div`
  overflow-y: scroll;
  /* height: calc(100vh - 215px); */
  height: ${(userRole)=> {
    return userRole.userRole.isAdmin?"calc(100vh - 250px)":"calc(100vh - 200px)"}};

  width: 100%;
  /* padding: 0px 40px; */
`;
const WrapperTasksTable = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  position: relative;
  background-color: #f4f4f4;
`;

const Preloader = styled.div`
  &:before {
        content: '';
        background: #fff;
        z-index: 3;
        width:100%;
        height: 100%;
        position:absolute;
        top:-5px;
        right:0;
  }
      &:after {
        content: '';
        border-radius: 50%;
        border: 3px solid #1BAAF0;
        z-index: 3;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin .5s linear infinite;
        transform: translateX(-50%);
        position:absolute;
        top:250px;
        left:50%;
        height:16px;
        width:16px;
  `;

const ProjectAllTable: React.FC<Props> = ({
  activeColumns,
  isFetchingTasks,
  tasks,
  projects,
  changeTaskList,
  fetchTasks,
}) => {
  const userRole = useSelector((state: State) => state.commonInfo!.currentUserInfo);

  const abortController = new AbortController();
  const { id }: any = useParams();
  const [pageNum, setPageNum] = useState(1);
  useEffect(() => {
    changeTaskList([]);
  }, [id, changeTaskList]);
  useEffect(() => {
    fetchTasks(id, 1, abortController);
  }, [fetchTasks, id]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight <= scrollTop + clientHeight &&
        projects?.length === 20 * pageNum
      )
        setPageNum(pageNum + 1);
    },
    [pageNum, projects.length]
  );

  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (pageNum !== 1) fetchTasks(id, pageNum);
  }, [fetchTasks, id, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [id]);

  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;

    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);

  return (
    <WrapperTasksTable isFetchingTasks={isFetchingTasks} >
      <>
      {(isFetchingTasks || projects.length === 0) ? <Preloader/> :
      <>
       {projects.length === 0 ? (
        <div style={{ paddingLeft: 24 }} className="main_title">
          Проекты отсутствуют
        </div>
      ) : (
        <>
          <ProjectTableHead setPageNum={setPageNum} isScroll={isScroll} />
          <div onScroll={scrollHandler} ref={ref}>
            <WrapperTasks userRole= {userRole}>
              {projects.map((project: Project) => (
                <ProjectTaskTr key={project.id} project={project} />
              ))}
            </WrapperTasks>
          </div>
        </>
      )}
      </>
      }
      </>
    </WrapperTasksTable>
  );
};

const mapStateToProps = (state: State) => ({
  activeColumns: state.projectsPage.activeColumns,
  isFetchingTasks: state.projectsPage.isFetchingTasks,
  tasks: state.projectsPage.tasks,
  projects: state.commonInfo.projects,
});

const mapDispatchToProps = {
  changeTaskList,
  fetchTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAllTable);
