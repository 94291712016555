//  Easy Meet
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MeetingListItem } from "./types";
import MeetingList from "./components/MeetingCard/MeetingList";
import MeetingHeader from "./components/Header/MeetingHeader";
import MeetingTableHeader from "./components/MeetingTableHeader";
import MeetingChangeFilter from "./components/Filter/MeetingChangeFilter";
import MeetingAddButton from "./components/Header/MeetingAddButton";
import MeetingAddPanel from "./components/Sidebar/MeetingAddPanel";
import { State } from "../../rootReducer";
import { cookieMaster } from "../../utils/CookieMaster";
import { fetchData } from "../../utils/fetchData"; // Получение данных с backend
import MeetingFilterButton from "./components/Header/MeetingFilterButton";
import Pagination from "./components/Pagination/Pagination";
import axios from "axios";
import { Filter } from "./Filter";
import { CreateNotif } from "../../utils/createNotification";
import Icon from "../../common/components/Icon";
import { setAddedMeeting } from "../../common/actions";
import { formatToStringUTC } from "../../common/format";

let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;

type Props = {
  currentUser: "string";
  addedMeeting: any;
  setAddedMeeting: (mode: any) => void
};

const MeetingMainBlock = styled.div`
  background-color: #f4f4f4;
`;

const MeetingAddPanelMain = styled.div`
  position: fixed;
  top: 76px;
  right: ${({ isVisible }) => (isVisible ? "0" : "-100%")};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 25px 25px 25px 53px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 750px;
  height: calc(100vh - 76px);
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Meetings: React.FC<Props> = ({  addedMeeting,
  setAddedMeeting}) => {
  // Данные из главного модуля Easy Task
  // Тут получается текуший пользователь. Тип User | null
  const { currentUserInfo: currentUser } = useSelector(
    (state: State) => state.commonInfo
  );
  const { searchMeetings } = useSelector((state: State) => state.commonInfo);

  // Токен easy task
  let token = cookieMaster.getCookie("access_token");

  // company id
  let company_id = localStorage.getItem("company_id") || "";

  // Текущий пользователь- админ или нет
  let isAdmin = currentUser ? currentUser.isAdmin : false;

  // state компонента Easy Meet
  const [users, setUsers] = useState<any>();
  const [isShowAddMeeting, setIsShowAddMeeting] = useState(false); //для отображения окна добавления совещаний
  const [isShowFilterMeeting, setIsShowFilterMeeting] = useState(false); //для отображения окна фильтрации совещаний
  const [sort, setSort] = useState(); //sort parametrs
  const filterParam = new Filter(
    JSON.parse(localStorage.getItem("meeting_filter_params") as string) ?? {}
  );
  const [filter, setFilter] = useState(filterParam); // filter parametrs
  const [paginationMeta, setPaginationMeta] = useState({});
  const [Clicked, setClicked] = useState({ col: "id", count: 2 }); // Сортировка: имя поля (col) и count: возрастание (2) /убывание (1)
  const [isEdit, setIsEdit] = useState(false);
  const [activeMeeting, setActiveMeeting] = useState(null); // Вызывается при изменении совещания
  let pageNumber = Number(localStorage.getItem("pagination_meeting_page") ?? 1);
  const [page, setPage] = useState(pageNumber);
  const [changeMeeting, setChangeMeeting] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [statusDataMember, setStatusDataMember] = useState(false);
  const [someState, setSomeState] = useState();
  const modalBtnRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  // Запоминает сортировку совещаний
  const firstUpdate = useRef(false);

  useLayoutEffect(() => {
    // при инициализации это не должно отрабатывать. для этого сделано условие с useRef
    if (firstUpdate.current) {
      localStorage.setItem(
        "cols_order_meeting",
        JSON.stringify({
          name: Clicked.col,
          order_direct: Clicked.count === 1,
        })
      );
      return;
    } else {
      if (localStorage.getItem("cols_order_meeting")) {
        let parsed_obj = JSON.parse(
          localStorage.getItem("cols_order_meeting") as string
        );
        setClicked({
          col: parsed_obj.name,
          count: parsed_obj.order_direct ? 1 : 2,
        });
      }
    }
    firstUpdate.current = true;
  }, [Clicked]);

  useLayoutEffect(() => {
    let pageNumber = page.toString();
    localStorage.setItem("pagination_meeting_page", pageNumber);
  }, [page]);

  useLayoutEffect(() => {
    localStorage.setItem("meeting_filter_params", JSON.stringify(filter));
    setPage(1);
  }, [filter]);

  const [listMeeting, setListMeeting] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/v1/users?page=1&order=id&ordertype=desc&limit=500`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setUsers(res.data.data);
      });
  }, []);

  const getMeetings = useCallback(async () => {
    const filt = filter.getUrl();

    let sortColumn = Clicked.col; // Сортировка списка совещаний по выбранной колонке

    if (Clicked.count == 1) sortColumn = "-" + sortColumn;
    //  } // Если сортировка по убыванию- добавляем минус к направлению сортировки

    let queryParam: string[] = [];
    queryParam.push(`page=${page}`);

    if (sortColumn !== "id" && sortColumn !== "-id")
      queryParam.push(`sort=${sortColumn}`);

    if (filt.length) {
      queryParam.push(filt);
    }
    try {
      const list = await axios.get(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/${company_id}/meetings?${queryParam.join(
          "&"
        )}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setPaginationMeta(list.data.meta);
      setListMeeting(list.data); 
    } catch (err) {
      console.log(err);
    }
  }, [Clicked, page, filter, changeMeeting, statusDataMember, searchMeetings]);

  useEffect(() => {
    if (searchMeetings !== null) {
      setListMeeting([]);
      setFilter(new Filter({ name: searchMeetings }));
    }
  }, [searchMeetings]);
  //Функция для удаления совещания
  const deleteMeeting = useCallback(async (id) => {
    axios
      .delete(`${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        getMeetings();
        CreateNotif(`Совещание удалено!`, "error");
      })
      .catch((e) => {
        CreateNotif(
          `Ошибка удаления совещания! Почистите сначала комментарии в нем!`,
          "warning"
        );
      });
  }, []);

  useEffect(() => {
    getMeetings();
  }, [Clicked, page, filter, changeMeeting, statusDataMember]);

  const addMeeting = useCallback(async (prop) => {
    const data = await axios
      .post(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/${company_id}/meetings`,
        {
          ...prop,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setAddedMeeting(res.data.data)
        getMeetings();
        CreateNotif(`Совещание успешно добавлено!`, "success");
        setIsShowAddMeeting(false);
      }
      )
      .catch((e) => {
        CreateNotif(
          `Ошибка при добавлении совещания! Проверьте введенные данные `,
          "error"
        );
      });
  }, []); // Вызывается при добавлении совещания

  useEffect(() => {
    if (isEdit) {
      setIsShowAddMeeting(true);
    }
  }, [isEdit]);

  const editMeeting = useCallback(async (id, props) => {
    try {
      const data = await axios.patch(
        `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${id}`,
        {
          chairman: props?.chairman,
          format: props?.format,
          location: props?.location,
          members: props?.members,
          name: props?.name,
          secretary: props?.secretary,
          start_at: formatToStringUTC(new Date(props.start_at)),
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
        }
      );
      CreateNotif(`Совещание успешно изменено!`, "success");
      getMeetings();
      setIsShowAddMeeting(false);
      setIsEdit(false);
    } catch (err) {
      CreateNotif(
        `Ошибка при изменении совещания! Возможно председатель и секретарь - один сотрудник, а должны быть разные`,
        "error"
      );
    }
  }, []); // Вызывается при редактировании совещания

  const updateList = useCallback(() => {}, []); // Обновляет список

  const changeFilter = useCallback(() => {}, []); // Вызывается при установке новых значений фильтра

  const dispatch = useDispatch();

  const closeHandler = () => {
    setIsEdit(false);
    setIsShowAddMeeting(false);
    setActiveMeeting(null);
  };

  return (
    <>
      <MeetingMainBlock>
        {/* Блок с фильтрами и кнопкой добавления */}
        <MeetingHeader>
          <div ref={modalBtnRef}>
            <MeetingFilterButton
              setIsShowFilterMeeting={setIsShowFilterMeeting}
            />
          </div>
          <MeetingAddButton
            setIsShowAddMeeting={setIsShowAddMeeting}
            currentUser={currentUser}
          />
        </MeetingHeader>
        {/* Блок с фильтрами и кнопкой добавления */}

        {/* Блок заголовка  */}
        <MeetingTableHeader Clicked={Clicked} setClicked={setClicked} />
        {/* Блок заголовка */}

        {/* Блок вывода списка совещаний */}
        <MeetingList
          changeMeeting={changeMeeting}
          setChangeMeeting={setChangeMeeting}
          listMeeting={listMeeting}
          deleteMeeting={deleteMeeting}
          statusDataMember={statusDataMember}
          setStatusDataMember={setStatusDataMember}
          setActiveMeeting={setActiveMeeting}
          setIsEdit={setIsEdit}
        />
        {/* Блок вывода списка совещаний */}

        {/* Блок меню для выбора фильтров */}
        {isShowFilterMeeting && (
          <MeetingChangeFilter
            changeFilter={changeFilter}
            isShowFilterMeeting={isShowFilterMeeting}
            setIsShowFilterMeeting={setIsShowFilterMeeting}
            setFilter={setFilter}
            filter={filter}
            users={users}
            ref={modalRef}
          />
        )}
        {/* Блок меню для выбора фильтров */}

        {/* Блок меню для добавления или редактирования совещаний */}
        <MeetingAddPanelMain isVisible={isShowAddMeeting}>
          {isShowAddMeeting && (
            <>
              <CloseIcon>
                <Icon name="cross" onClick={closeHandler} />
              </CloseIcon>
              <MeetingAddPanel
                addMeeting={addMeeting}
                isShowAddMeeting={isShowAddMeeting}
                setIsShowAddMeeting={setIsShowAddMeeting}
                activeMeeting={activeMeeting}
                setActiveMeeting={setActiveMeeting}
                editMeeting={editMeeting}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                users={users}
              />
            </>
          )}
        </MeetingAddPanelMain>
        {/* Блок бокового меню для добавления или редактирования совещаний */}
        <Pagination
          paginationMeta={paginationMeta}
          setMeetingList={setListMeeting}
          setPaginationMeta={setPaginationMeta}
          page={page}
          setPage={setPage}
        />
      </MeetingMainBlock>
    </>
  );
};
const mapStateToProps = (state: State) => {
  return {
    addedMeeting: state.commonInfo.addedMeeting,
  };
};

const mapDispatchToProps = {
  setAddedMeeting
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Meetings);


