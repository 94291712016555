// Картинка для компании
import React, { useState, useCallback, useRef, useEffect } from "react";
import { CreateNotif } from "../../../utils/createNotification";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
//import { setCompanyImage } from "../../../pages/NewUser/actions";
import { urlApp } from "../../../App";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import "./styles.scss";
import { setCompany } from ".././actions";
import { useDispatch } from "react-redux";
import { fetchData } from "../../../utils/fetchData";
import { cookieMaster } from "../../../utils/CookieMaster";

const WrapperAvatar = styled.div`
  position: relative;
  justify-self: center;
  align-self: center;
  background-image: url("../img/avatar_placeholder.png");
  width: 200px;
  height: 200px;
  label {
    /* position: absolute; */
    /* bottom: -15px; */
    /* left: calc(50% - 15px); */
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(32, 48, 90, 0.1);
    /* width: 25px; */
    /* height: 25px; */
    /* cursor: pointer; */
    background-color: white;
    img {
      width: 200px;
      height: 200px;
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const Picture = ({
  image,
  edit,
  changeHandler1,
  setInputsValue,
  companyImage,
  setCompanyImage,
}) => {
  const dispatch = useDispatch();

  const [url, setUrl] = useState<string | null>(null);

  const img = useRef<HTMLImageElement>(null);

  const changeHandler = useCallback(
    (e) => {
      let file = e.target.files[0];
      if (!file) return;
      if (!file.type.match("image.*")) {
        CreateNotif("Файл не является изображением");
        return;
      }

      console.log("file", file);
      dispatch(setCompany({ logo: file }));
      setInputsValue((inputsValue) => {
        return { ...inputsValue, ["logo"]: file };
      });

      if (file instanceof File) {
        let formData = new FormData();
        formData.append("file", file);

        fetchData
          .post(`/api/v1/files`, formData, {
            Authorization: cookieMaster.getCookie("access_token"),
          })
          .then((res) => setCompanyImage(res?.id));
      }
    },
    [setCompanyImage]
  );

  useEffect(() => {
    if (!image) return;
    if (image instanceof File) {
      let reader = new FileReader();
      reader.onload = ((theFile) => {
        return function (e) {
          setUrl(e.target.result);

          img.current?.setAttribute("srс", e.target.result);
        };
      })(image);

      reader.readAsDataURL(image);
    } else if (image.url) {
      setUrl(urlApp + `/files/${image.url}/download`);
    }
  }, [image]);

  return (
    <WrapperAvatar>
      <label style={{ display: "block" }}>
        <img className="picture" src={url ? url : ""} />
        {
          <input
            onChange={(e) => {
              changeHandler(e);
              //  setTimeout(()=>changeHandler1(),2000);
            }}
            type="file"
          />
        }
      </label>
    </WrapperAvatar>
  );
};

export default Picture;
