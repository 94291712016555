import React, { useState } from "react";

const Project: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 28H6.99902C6.73381 28 6.47945 27.8946 6.29192 27.7071C6.10438 27.5196 5.99902 27.2652 5.99902 27V5C5.99902 4.73478 6.10438 4.48043 6.29192 4.29289C6.47945 4.10536 6.73381 4 6.99902 4H19L26 11V27C26 27.1313 25.9741 27.2614 25.9239 27.3827C25.8736 27.504 25.8 27.6142 25.7071 27.7071C25.6143 27.8 25.504 27.8736 25.3827 27.9239C25.2614 27.9741 25.1313 28 25 28Z"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 4V11H26.001"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 17H20"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 21H20"
        stroke="#C4C4C4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Project;
