// ***************** Тут выводится список задач *********************

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"; //*************************** */
import moment from "moment";
import TasksActions from "./components/TasksActions";
import { useDispatch, useSelector } from "react-redux";
import TasksTable from "./components/TasksTable";
import FactsTable from "./components/facts/FactsTable";
import { State } from "../../rootReducer";
import Visualization from "./components/Visualization/";
import SettingsPage from "./components/SettingsPage";
import { fetchData } from "../../utils/fetchData";
import { fetchUpdateData } from "../../utils/fetchUpdateData";
import {
  updateNewNotification,
  updateTimestamp,
  setProjects,
  setUsers,
} from "../../common/actions";
import { timeUpdate } from "../../App";
import { changeTaskList } from "./actions";
import { selectTask, updateTaskInfoPlate } from "../TaskInfoPlate/actions";
import { cookieMaster } from "../../utils/CookieMaster"; //*********************************** */

const Tasks: React.FC = () => {
  const [isFactPage, setIsFactPage] = useState<boolean>(true);
  const [isSettingsPage, setIsSettingsPage] = useState<boolean>(false);

  // Если пользователь не залогинен - переводим его на страницу авторизации
  useLayoutEffect(() => {
    let token = cookieMaster.getCookie("access_token");
    if (token === undefined || token === null) {
      window.location.replace(`/login`);
    }
    if (token.length == 0) {
      window.location.replace(`/login`);
    }
    if (localStorage.getItem("firstEnter")) {
      const currentDate = new Date();
      const momentDate = moment(currentDate).format("L");
      if (localStorage.getItem("firstEnter") !== momentDate) {
        setIsFactPage(true);
      } else {
        setIsFactPage(false);
      }
    } else {
      const date = new Date();
      const momentDate = moment(date).format("L");
      localStorage.setItem("firstEnter", momentDate);
    }
  }, []);
  // *********************************************************************** */

  const [updateTask, setUpdateTask] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { selectedUserId, visualizationData, showVisualization, tasks } =
    useSelector((state: State) => state.tasksPage);
  const { timestamp, users } = useSelector((state: State) => state.commonInfo);
  const projectsCompany = useSelector(
    (state: State) => state.commonInfo.projects
  );
  const executors = useSelector(
    (state: State) => state.tasksPage.filters.appliedFilters.executors
  );
  const statuses = useSelector(
    (state: State) => state.tasksPage.filters.appliedFilters.statuses
  );
  const authors = useSelector(
    (state: State) => state.tasksPage.filters.appliedFilters.authors
  );
  const { isShownTaskInfoPlate, selectedTask } = useSelector(
    (state: State) => state.taskInfoPlate
  );
  const templateProjects = [...projectsCompany];
  const templateTasks = [...tasks];
  const templateUsers = [...users];

  // console.log("tasks",tasks);

  // const cmp = (props) => {

  // if(hash) return <HashPage>
  //   else return <NormalPage></NormalPage>
  // }

  // useEffect(() => {
  // if (id && users.length > 0) {
  // let user = users.find(u => u.id === +id)
  // if (user) {
  //     openExistingUser(user)
  // }
  // setUserAddMode(true)
  // }
  // }, [id, task_id])

  useEffect(() => {
    if (timestamp === "") {
      let day =
        new Date().getDate() > 9
          ? new Date().getDate()
          : "0" + new Date().getDate();
      let month =
        new Date().getMonth() > 9
          ? Number(new Date().getMonth()) + 1
          : "0" + (Number(new Date().getMonth()) + 1);

      let year = new Date().getFullYear();

      let dataString = `${year}-${month}-${day} ${new Date().toLocaleTimeString()}`;

      fetchData
        .get(`/api/v1/update-data?timestamp=${dataString}`)
        .then(({ timestamp }) => dispatch(updateTimestamp(timestamp)))
        .catch((error) => console.warn(error));
    } else {
      let filterTasks = "";
      if (executors.length > 0 && authors.length === 0) {
        filterTasks = `tasks_executor_id{@${executors.join(" ")}}`;
      } else if (authors.length > 0 && executors.length === 0) {
        filterTasks = `tasks_author_id{@${authors.join(" ")}}`;
      } else if (executors.length > 0 && authors.length > 0) {
        filterTasks = `tasks_author_id{@${authors.join(
          " "
        )}},tasks_executor_id{@${executors.join(" ")}}`;
      } else {
        filterTasks = `tasks_all`;
      }
      if (statuses.length > 0) {
        filterTasks += `,status_id{@${statuses.join(" ")}}`;
      }

      const intervalFetch = setInterval(() => {
        fetchUpdateData(timestamp, "tasks", [filterTasks])
          ?.then((data) => {
            dispatch(updateTimestamp(data.timestamp));
            if (data.projects.length > 0) {
              data.projects.forEach((updateProject) => {
                templateProjects.map((el, index) => {
                  if (el.id === updateProject.id) {
                    templateProjects.splice(index, 1, updateProject);
                  }
                });
              });
              dispatch(setProjects(templateProjects));
            }
            return data;
          })
          .then((data) => {
            if (data.tasks_all?.length > 0) {
              data.tasks_all.forEach((updateTask) => {
                templateTasks.map((el, index) => {
                  if (
                    (el.id === updateTask.id && !updateTask?.delete) ||
                    (el.id === updateTask.id && updateTask?.create)
                  ) {
                    templateTasks.splice(index, 1, updateTask);
                  } else if (el.id === updateTask.id && updateTask?.delete) {
                    templateTasks.splice(index, 1);
                  } else if (updateTask?.create) {
                    if (el.id === updateTask.id) {
                    } else if (
                      el.id === updateTask.id &&
                      templateTasks.length === ++index
                    ) {
                      templateTasks.unshift(updateTask);
                      setUpdateTask(true);
                    }

                    // templateTasks.pop();
                  }
                });
                // if (
                //   isShownTaskInfoPlate &&
                //   selectedTask?.id === updateTask.id
                // ) {
                //   dispatch(updateTaskInfoPlate(updateTask));
                //   dispatch(selectTask(updateTask));
                // }
              });
              dispatch(changeTaskList(templateTasks));

              return data;
            }
            if (data.authors_executors?.length > 0) {
              data.authors_executors.forEach((updateTask) => {
                templateTasks.map((el, index) => {
                  if (
                    (el.id === updateTask.id && !updateTask?.delete) ||
                    (el.id === updateTask.id && updateTask?.create)
                  ) {
                    templateTasks.splice(index, 1, updateTask);
                  } else if (el.id === updateTask.id && updateTask?.delete) {
                    templateTasks.splice(index, 1);
                  } else if (updateTask?.create) {
                    if (el.id === updateTask.id) {
                    } else if (
                      el.id === updateTask.id &&
                      templateTasks.length === ++index
                    ) {
                      templateTasks.unshift(updateTask);
                      setUpdateTask(true);
                    }

                    // templateTasks.pop();
                  }
                });
                if (
                  isShownTaskInfoPlate &&
                  selectedTask?.id === updateTask.id
                ) {
                  dispatch(updateTaskInfoPlate(updateTask));
                  dispatch(selectTask(updateTask));
                }
              });
              dispatch(changeTaskList(templateTasks));

              return data;
            }

            if (data.tasks_executor_id?.length > 0) {
              data.tasks_executor_id.forEach((updateTask) => {
                templateTasks.map((el, index) => {
                  if (
                    (el.id === updateTask.id && !updateTask?.delete) ||
                    (el.id === updateTask.id && updateTask?.create)
                  ) {
                    templateTasks.splice(index, 1, updateTask);
                  } else if (el.id === updateTask.id && updateTask?.delete) {
                    templateTasks.splice(index, 1);
                  } else if (updateTask?.create) {
                    if (el.id === updateTask.id) {
                    } else if (
                      el.id === updateTask.id &&
                      templateTasks.length === ++index
                    ) {
                      templateTasks.unshift(updateTask);
                      setUpdateTask(true);
                    }

                    // templateTasks.pop();
                  }
                });
                if (
                  isShownTaskInfoPlate &&
                  selectedTask?.id === updateTask.id
                ) {
                  dispatch(updateTaskInfoPlate(updateTask));
                  dispatch(selectTask(updateTask));
                }
              });
              dispatch(changeTaskList(templateTasks));
              return data;
            }
            if (data.tasks_author_id?.length > 0) {
              data.tasks_author_id.forEach((updateTask) => {
                templateTasks.map((el, index) => {
                  if (
                    (el.id === updateTask.id && !updateTask?.delete) ||
                    (el.id === updateTask.id && updateTask?.create)
                  ) {
                    templateTasks.splice(index, 1, updateTask);
                  } else if (el.id === updateTask.id && updateTask?.delete) {
                    templateTasks.splice(index, 1);
                  } else if (updateTask?.create) {
                    if (el.id === updateTask.id) {
                    } else if (
                      el.id === updateTask.id &&
                      templateTasks.length === ++index
                    ) {
                      templateTasks.unshift(updateTask);
                      setUpdateTask(true);
                    }

                    // templateTasks.pop();
                  }
                });
                if (
                  isShownTaskInfoPlate &&
                  selectedTask?.id === updateTask.id
                ) {
                  dispatch(updateTaskInfoPlate(updateTask));
                  dispatch(selectTask(updateTask));
                }
              });
              dispatch(changeTaskList(templateTasks));
              return data;
            }
            return data;
          })
          .then((data) => {
            if (data.users?.length > 0) {
              data.users.forEach((updateUser) => {
                templateUsers.map((el, index) => {
                  if (el.id === updateUser.id) {
                    templateUsers.splice(index, 1, updateUser);
                  }
                });
              });
              dispatch(setUsers(templateUsers));
            }
            return data;
          })
          .then((data) => {
            if (data.notifications?.length > 0) {
              data.notifications.map((el) => {
                if (el.hasOwnProperty("create")) {
                  dispatch(updateNewNotification([el]));
                }
              });
            }
          })
          .catch((error) => console.warn(error));
      }, timeUpdate);
      return () => {
        clearInterval(intervalFetch);
      };
    }
    // timestamp,
    // authors,
    // executors,
    // projectsCompany,
    // users,
    // tasks,
    // templateTasks,
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
  ]);

  const handleChangeFactPage = () => {
    setIsFactPage((prev) => !prev);
    setIsSettingsPage(false);
  };

  const handleChangeSettingsPage = () => {
    setIsSettingsPage(true);
    setIsFactPage(false);
  };

  return (
    <>
      <TasksActions
        isSettingsPage={isSettingsPage}
        handleChangeSettingsPage={handleChangeSettingsPage}
        handleChangeFactPage={handleChangeFactPage}
        isFactPage={isFactPage}
      />
      {/* <Collapse in={showVisualization}>
          </Collapse> */}
      {showVisualization && (
        <Visualization
          tasks={tasks}
          showVisualization={showVisualization}
          selectedUserId={selectedUserId}
          visualizationData={visualizationData}
        />
      )}
      <TasksTable updateTask={updateTask} setUpdateTask={setUpdateTask} />
      {/*{*/}
      {/*  isSettingsPage ?*/}
      {/*    <SettingsPage />*/}
      {/*    :*/}
      {/*    <>*/}
      {/*      {*/}
      {/*        isFactPage ?*/}
      {/*          <FactsTable updateTask={updateTask} setUpdateTask={setUpdateTask} />*/}
      {/*          :*/}
      {/*          <TasksTable updateTask={updateTask} setUpdateTask={setUpdateTask} />*/}
      {/*      }*/}
      {/*    </>*/}
      {/*}*/}
    </>
  );
};

export default Tasks;
