// Тут заводится и рассчитывается дата окончания задачи

import React, { useCallback, useEffect, useState } from "react";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePikerTask";
import withTitle from "../../../utils/hoc/withTitle";
import { connect } from "react-redux";
import {
  recalculateTaskLoad,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskEnd,
} from "../actions";
import { State } from "../../../rootReducer";
import { updateTask } from "../../../common/actions";
import moment from "moment";

const TaskEnd = ({
  checkedPersonalTask,
  checkedTaskEnd,
  disabled,
  end,
  minDate,
  setTaskEnd,
  delegated,
  recalculateTaskLoad,
  selectedTask,
  updateTask,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  cellData,
  setDataEnd,
}) => {
  const acceptHandler = useCallback(
    function (e): any {
      setTaskEnd(e);
      setDataEnd(e);
      if (selectedTask) {
        recalculateTaskLoad({ task_load: selectedTask.task_load, end: e });
        if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
          // сравнение одного с несколькими
          // эта задача вообще циклическая или сама по себе ?
          // значит циклическая
          if (flagForActionCyclycTask === "") {
            setCyclicTaskToChange({ end: e }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
          } else if (flagForActionCyclycTask === "task") {
            updateTask(selectedTask!.id, {
              end: e,
              private: checkedPersonalTask ? true : false,
            });
          } else {
            updateTask(selectedTask!.id, {
              end: e,
              private: checkedPersonalTask ? true : false,
            });
            sendReqToChangeTemplRepTask({ end: e });
          }
        } else
          updateTask(selectedTask!.id, {
            end: e,
            private: checkedPersonalTask ? true : false,
          });
      }
    },
    [selectedTask]
  );

  let [date, time] = selectedTask
    ? selectedTask.begin.split(" ")
    : minDate.split(" ");
  let minDateObj = new Date(`${date.split("-").reverse().join("-")} ${time}`);
  // in particular cases, 'end' date comes here in format dd/mm/yyyy hh:mm:ss
  // expected format is dd-mm-yyyy
  end = end.replace(/\//g, "-");

  const [cellEnd, setCellEnd] = useState<any>(new Date());
  // оставлено на врпмя проверки
  // useEffect(() => {
  //   if (cellData) {
  //     setCellEnd(cellData.end_at);
  //   }
  // }, [cellData]);

  // useEffect(() => {
  //   acceptHandler(moment(cellEnd).format("DD-MM-YYYY HH:mm:ss"));
  // }, [cellEnd]);

  return (
    <TaskDateTime
      disabled={disabled || delegated}
      acceptHandler={
        acceptHandler
      }
      date={
         checkedTaskEnd
          ? new Date(minDate.getTime() + 24 * 60 * 60 * 1000)
          : selectedTask
          ? selectedTask.end
          : end
      }
      minDate={minDateObj}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    end: state.taskInfoPlate.end,
    minDate: state.taskInfoPlate.begin,
    delegated: state.taskInfoPlate.delegated,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEnd);
