import React from "react";

const On_approval = ({props}) => {
  return (
<svg 
{...props}
width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#FFD600"/>
<path d="M15 14V26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24 14V26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default On_approval;