import React from "react";

const Canceled = () => {
  return (
<svg 
width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#E0E0E0"/>
<path d="M26 14H14V26H26V14Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default Canceled;