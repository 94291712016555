import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

interface Props {
  isShowAddObject: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  changeSection: number;
  setChangeSection: (mode: number) => void;
  cellData: any;
  setCellData: (mode: any) => void;
}

const HeaderAdd = styled.div`
  margin-bottom: 35px;
`;

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
`;

const CloseHeader = styled.button`
  font-size: 27px;
  background: inherit;
  color: #377cb3;
`;

const Head = styled.button`
  display: flex;
  background: inherit;
  width: 100%;
  font-size: 20px;
  margin-top: 20px;
`;

const TaskSection = styled.div`
  width: 100%;
  text-align: start;
  color: ${({ section }) => section === 1 && "#FFFFFF"};
  background: ${({ section }) => section === 1 && "#01B0E9"};
`;

const MeetSection = styled.div`
  width: 100%;
  text-align: start;
  color: ${({ section }) => section === 2 && "#FFFFFF"};
  background: ${({ section }) => section === 2 && "#01B0E9"};
`;

const EventSection = styled.div`
  width: 100%;
  text-align: start;
  padding-left: 40px;
  color: ${({ section }) => section === 3 && "#FFFFFF"};
  background: ${({ section }) => section === 3 && "#01B0E9"};
`;

const scheduler = window.scheduler;

const HeaderSidebar: React.FC<Props> = ({
  isShowAddObject,
  setIsShowAddObject,
  changeSection,
  setChangeSection,
  cellData,
  setCellData,
}) => {
  const userId = localStorage.getItem("user_id");
  const params = useParams<{ id: string }>();

  function deleteSelectedEvent() {
    scheduler.deleteEvent(cellData?.newEvent?.id);
    setIsShowAddObject(!isShowAddObject);
    setCellData(null);
  }
  const closeHandler = () => {
    setIsShowAddObject(!isShowAddObject);
    deleteSelectedEvent();
  };
  return (
    <HeaderAdd>
      <TitleHeader>
        <Title>Добавляем объект</Title>
        <CloseHeader onClick={() => closeHandler()}>x</CloseHeader>
      </TitleHeader>
      <Head>
        <TaskSection
          style={{
            border: "1px solid #C4C4C4",
            borderRadius: "5px 0px 0px 5px",
            textAlign: "center",
            padding: "10px 0",
          }}
          onClick={() => setChangeSection(1)}
          section={changeSection}
        >
          Задача
        </TaskSection>
        <MeetSection
          style={{
            border: "1px solid #C4C4C4",
            textAlign: "center",
            padding: "10px 0",
          }}
          onClick={() => setChangeSection(2)}
          section={changeSection}
        >
          Совещание
        </MeetSection>
        {userId === params.id && (
          <EventSection
            style={{
              border: "1px solid #C4C4C4",
              borderRadius: "0px 5px 5px 0px",
              textAlign: "center",
              padding: "10px 0",
            }}
            onClick={() => setChangeSection(3)}
            section={changeSection}
          >
            Событие
          </EventSection>
        )}
      </Head>
    </HeaderAdd>
  );
};

export default HeaderSidebar;
