import { types, CommonAction } from './action_types';
import {
  Project,
  User,
  Status,
  Role,
  Position,
  Department,
  Priority,
  Workgroup,
  Section,
  Item,
} from './types';
import { Reducer } from 'redux';
import { cookieMaster } from '../utils/CookieMaster';

const initialState = {
  projects: [] as Project[],
  users: [] as User[],
  statuses: [] as Status[],
  roles: [] as Role[],
  positions: [] as Position[],
  wasFetched: false,
  notifications: [] as any[],
  remote_notifications: {
    unread: [],
    read: [],
    load_next: 'unread',
    offset: 0,
    end: false,
  },
  timestamp: '',
  priorities: [] as Priority[],
  departments: [] as Department[],
  workgroups: [] as Workgroup[],
  sections: [] as Section[],
  current_user: parseInt(cookieMaster.getCookie('user_id')),
  currentUserInfo: null as null | User,
  isVerified: true,
  show_notification_window: false,
  show_animate_company_window: false,
  show_company_window: false,
  scheduleUser: {},
  company: {},
  addedMeeting: null,
  searchMeetings: null,
  filters: {
    current_set: {
      date_from: '',
      date_to: '',
      executors: [] as Item[],
      authors: [] as Item[],
      statuses: [] as number[],
      actual: false,
    },

    showFiltersWindow: false,

    templates: {},
    default_templates: {},
  },
  createdTaskInfo:null,

};

export type CommonState = typeof initialState;

// @ts-ignore
const commonReducer: Reducer<CommonState, CommonAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.ADD_COMMON_INFO:
      // console.log(action.company)
      return {
        ...state,
        projects: action.projects || [],
        users: action.users || [],
        statuses: action.statuses || [],
        roles: action.roles || [],
        positions: action.positions || [],
        departments: action.departments || [],
        priorities: action.priorities || [],
        workgroups: action.workgroups || [],
        sections: action.sections || [],
        wasFetched: true,
        remote_notifications: {
          unread: action.unread_notifications || [],
          read: action.read_notifications || [],
          load_next: action.read_notifications.length
            ? 'read'
            : state.remote_notifications.load_next,
          offset: action.read_notifications.length
            ? action.read_notifications.length
            : action.unread_notifications.length,
          end: action.end ? action.end : state.remote_notifications.end,
        },
        company: action.company,
      };

    case types.SET_FILTERS:
      let filters = { ...state.filters };
      let newData = { ...filters['current_set'] };

      // console.log(newData.statuses)
      // console.log(action.statuses)
      if (action.date_from !== undefined)
        newData['date_from'] = action.date_from;
      if (action.date_to !== undefined) newData['date_to'] = action.date_to;
      if (action.executors !== undefined)
        newData['executors'] = action.executors;
      if (action.authors !== undefined) newData['authors'] = action.authors;
      if (action.statuses !== undefined) newData['statuses'] = action.statuses;
      if (action.actual !== undefined) newData['actual'] = action.actual;

      // console.log(newData.statuses)

      filters['current_set'] = newData;

      return {
        ...state,
        filters: filters,
      };

    case types.SET_FILTER_TEMPLATES:
      let result = { ...state.filters };
      result['templates'] = action.data;
      return {
        ...state,
        filters: result,
      };

    case types.SET_DEFAULT_FILTER_TEMPLATES:
      let state_filters = { ...state.filters };
      state_filters['default_templates'] = action.data;
      return {
        ...state,
        filters: state_filters,
      };

    case types.SHOW_FILTERS_WINDOW:
      let fltrs = { ...state.filters };
      fltrs['showFiltersWindow'] = !fltrs['showFiltersWindow'];
      return {
        ...state,
        filters: fltrs,
      };
      case types.SET_CREATED_TASK_INFO:
        console.log(action.task)
      return {
        ...state,
        createdTaskInfo: action.task
      };
    case types.CHANGE_WORK_SCHEDULE:
      return {
        ...state,
        scheduleUser: action.schedule,
        // schedule.workTime:
      };
      case types.SET_ADDED_MEETING:
      return {
        ...state,
        addedMeeting: action.meeting,
        // schedule.workTime:
      };
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };
    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notif: any) =>
          notif.key === action.key
            ? { ...notif, dismissed: true }
            : { ...notif }
        ),
      };
    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notif: any) => notif.key !== action.key
        ),
      };
    case types.SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case types.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case types.SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments,
      };
    case types.SET_WORKGROUPS:
      return {
        ...state,
        workgroups: action.workgroups,
      };
    case types.SET_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };
      case types.SET_POSITIONS:
        console.log(action.positions)
      return {
        ...state,
        positions: action.positions,
      };
    case types.SET_VERIFYED:
      return {
        ...state,
        isVerified: action.verified,
      };
    case types.SET_CURRENT_USER_INFO:
      return {
        ...state,
        currentUserInfo: action.user,
      };
    case types.SET_SHOW_NOTIF_WINDOW:
      return {
        ...state,
        show_notification_window: action.val,
      };

    case types.SET_ANIMATE_SHOW_COMPANY_WINDOW:
      return {
        ...state,
        show_animate_company_window: action.value,
      };

    case types.SET_SHOW_COMPANY_WINDOW:
      return {
        ...state,
        show_company_window: action.value,
      };
    case types.SET_REMOTE_NOTIFICATIONS:
      return {
        ...state,
        remote_notifications: {
          unread: action.unread,
          read: action.read,
          load_next:
            action.load_next === undefined
              ? state.remote_notifications.load_next
              : action.load_next,
          end: action.end === undefined ? state.remote_notifications.end : true,
          offset:
            action.offset === undefined
              ? state.remote_notifications.read.length
              : action.offset,
        },
      };
    case types.UPDATE_TIMESTAMP:
      return {
        ...state,
        timestamp: action.timestamp,
      };
    case types.UPDATE_NEW_NOTOFICATION:
      return {
        ...state,
        remote_notifications: {
          unread: [
            ...action.newNotifications,
            ...state.remote_notifications.unread,
          ],
          read: state.remote_notifications.read,
        },
      };
      case types.SET_SEARCH_MEETINGS: 
      return{
        ...state,
        searchMeetings: action.search
      }
    default:
      return state;
  }
};

export default commonReducer;
