import { Avatar } from "@material-ui/core";
import React from "react";
import { MeetingListItem } from "../../../../types";
import styled from "styled-components";
import { User } from "../../../../../../common/types";
import { State } from "../../../../../../rootReducer";
import { connect } from "react-redux";
import On_approval from "../../../Icons/ImageWithColor/ImgMemberStatus/On_approval";
import Refusal from "../../../Icons/ImageWithColor/ImgMemberStatus/Refusal";
import Time from "../../../Icons/ImageWithColor/ImgMemberStatus/Time";
import Approved from "../../../Icons/ImageWithColor/Approved";
import RefusalMembers from "../../../Icons/ImageWithColor/ImgMemberStatus/RefusalMembers";
import TimeMembers from "../../../Icons/ImageWithColor/ImgMemberStatus/TimeMembers";

interface Props {
  meeting: any;
  commonUsers?: User[];
}

interface ArrayElement {
  [key: string]: any;
}

const DivMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 20px;
`;

const MemberCard = styled.div`
  display: flex;
  min-width: 250px;
  max-width: 340px;
  align-items: center;
  // position: relative;
`;

const AvatarStatus = styled.div`
  position: relative;
`;

const JobTitle = styled.div`
  font-size: 14px;
  align-items: center;
  color: rgba(41, 42, 52, 0.5);
`;

const MemberName = styled.div`
  font-size: 16px;
  align-items: center;
  margin-bottom: 5px;
`;

const Member = styled.div`
  margin-left: 12px;
  align-items: center;
`;

const MemberStatus = styled.div`
  position: absolute;
  witdh: 30px;
  top: 25px;
  left: 28px;
`;

const MeetingMemberItem: React.FC<Props> = ({ meeting, commonUsers }) => {
  const userId = Number(localStorage.getItem("user_id"));

  const statusMember = (item) => {
    if (item === 0) return <On_approval props={30} />;
    if (item === 1) return <Approved props={30} />;
    if (item === 2) return <RefusalMembers props={30} />;
    if (item === 3) return <TimeMembers props={30} />;
  };

  const chairman = commonUsers?.find(
    (user) => user.id === meeting.chairman.user_id
  );
  const secretary = commonUsers?.find(
    (user) => user.id === meeting.secretary.user_id
  );

  const findObjectsByDifferentKeys = (
    array1: ArrayElement[],
    array2: ArrayElement[],
    key1: string,
    key2: string
  ): ArrayElement[] => {
    const result: ArrayElement[] = [];

    // Проходим по всем элементам первого массива
    for (let i = 0; i < array1.length; i++) {
      const element1 = array1[i];

      // Находим соответствующий элемент второго массива по значению ключа key2
      const element2 = array2.find((item) => item[key2] === element1[key1]);

      // Если элементы не найдены, переходим к следующему элементу
      if (!element2) {
        continue;
      }

      // Если значения ключей совпадают, добавляем объект из первого массива в результат
      if (element1[key1] === element2[key2]) {
        result.push({
          ...element1,
          status_meet: element2.status,
          data: element2.data,
        });
      }
    }

    return result;
  };

  const members =
    commonUsers &&
    findObjectsByDifferentKeys(commonUsers, meeting.members, "id", "user_id");

  return (
    <DivMain>
      <MemberCard>
        <AvatarStatus>
          <Avatar
            src={`${process.env.REACT_APP_PUBLIC_URL}/files/${
              chairman && chairman.image && chairman?.image.url
            }/download`}
            style={{ background: "#01B0E9", width: "52.5px", height: "52.5px" }}
          >
            {chairman?.name[0]}
            {chairman?.surname[0]}
          </Avatar>
          <MemberStatus>{statusMember(meeting.chairman?.status)}</MemberStatus>
        </AvatarStatus>
        <Member>
          <MemberName>
            {chairman?.name} {chairman?.surname}
          </MemberName>
          <JobTitle>Председатель</JobTitle>
          {meeting.initiator_id === userId ||
          meeting.chairman.user_id === userId ? (
            meeting.chairman.status === 3 ? (
              <JobTitle>
                {"Предлагает перенести на: " +
                  new Date(meeting.chairman.data).toLocaleString("ru", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
              </JobTitle>
            ) : meeting.chairman.status === 2 ? (
              <JobTitle>Причина отказа: {meeting.chairman.data}</JobTitle>
            ) : null
          ) : null}
        </Member>
      </MemberCard>
      <MemberCard>
        <AvatarStatus>
          <Avatar
            src={`${process.env.REACT_APP_PUBLIC_URL}/files/${
              secretary && secretary.image && secretary?.image.url
            }/download`}
            style={{ background: "#01B0E9", width: "52.5px", height: "52.5px" }}
          >
            {secretary?.name[0]}
            {secretary?.surname[0]}
          </Avatar>
          <MemberStatus>{statusMember(meeting.secretary?.status)}</MemberStatus>
        </AvatarStatus>
        <Member>
          <MemberName>
            {secretary?.name} {secretary?.surname}
          </MemberName>
          <JobTitle>Секретарь</JobTitle>
          {meeting.initiator_id === userId ||
          meeting.chairman.user_id === userId ? (
            meeting.secretary.status === 3 ? (
              <JobTitle>
                {"Предлагает перенести на: " +
                  new Date(meeting.secretary.data).toLocaleString("ru", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
              </JobTitle>
            ) : meeting.secretary.status === 2 ? (
              <JobTitle>Причина отказа: {meeting.secretary.data}</JobTitle>
            ) : null
          ) : null}
        </Member>
      </MemberCard>
      {members?.map((item) => {
        return (
          <MemberCard>
            <AvatarStatus>
              <Avatar
                src={`${process.env.REACT_APP_PUBLIC_URL}/files/${
                  item && item.image && item?.image.url
                }/download`}
                style={{
                  background: "#01B0E9",
                  width: "52.5px",
                  height: "52.5px",
                }}
              >
                {item?.name[0]}
                {item?.surname[0]}
              </Avatar>
              <MemberStatus title="">
                {statusMember(item?.status_meet)}
              </MemberStatus>
            </AvatarStatus>
            <Member>
              <MemberName>
                {item?.name} {item?.surname}
              </MemberName>
              <JobTitle>Участник</JobTitle>
              {meeting.initiator_id === userId ||
              meeting.chairman.user_id === userId ? (
                item.status_meet === 3 ? (
                  <JobTitle>
                    {"Предлагает перенести на: " +
                      new Date(item.data).toLocaleString("ru", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                  </JobTitle>
                ) : item.status_meet === 2 ? (
                  <JobTitle>Причина отказа: {item.data}</JobTitle>
                ) : null
              ) : null}
            </Member>
          </MemberCard>
        );
      })}
    </DivMain>
  );
};

const mapStateToProps = (state: State) => {
  return {
    commonUsers: state.commonInfo.users,
  };
};

export default connect(mapStateToProps)(MeetingMemberItem);
