import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { StatusContext } from "../../../context/status.js";

const BpItemStatus = ({ status, workflow, id, deadline, taskEnd }) => {
  const {
    createBpStatus,
    createBpSampleStatus,
    createTaskStatus,
    setOpenTasks,
    openMenuTasks,
    openMenuBp,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    bearer,
    company_id,
  } = useContext(StatusContext);
  const [statusObj, setStatusObj] = useState({});
  const overdueBp = new Date(deadline || taskEnd) < new Date();
  // Статусы в бизнес-процесса:
  // 0 - черновик
  // 2 - в работе
  // 4 - завершена
  // 5 - отменена

  // Статусы в задачах:
  //  0 - Черновик
  //  1 - Ожидание
  //  2 - В работе
  //  3 - Выполнена
  //  4 - Завершена
  //  5 - Отменена
  //  6 - Пропущена
  //  7 - На согласовании

  const [statusIcon, setStatusIcon] = useState();

  let draft = require("../../Icons/status/draft.svg"); //  0 - Черновик
  let waiting = require("../../Icons/status/waiting.svg"); //  1 - Ожидание
  let inWork = require("../../Icons/status/inWork.svg"); //  2 - В работе
  let completed = require("../../Icons/status/completed.svg"); //  3 - Выполнена
  let done = require("../../Icons/status/done.svg"); //  4 - Завершена
  let canceled = require("../../Icons/status/canceled.svg"); //  5 - Отменена
  let onApprove = require("../../Icons/status/onApprove.svg"); //  7 - На согласовании
  let Overdue = require("../../Icons/status/Overdue.svg"); //  7 - просрочено

  useEffect(() => {
    if (status === 0) setStatusIcon(draft);
    else if (status === 1) setStatusIcon(waiting);
    else if (status === 2 && !overdueBp) setStatusIcon(inWork);
    else if (status === 3) setStatusIcon(completed);
    else if (status === 4) setStatusIcon(done);
    else if (status === 5) setStatusIcon(canceled);
    else if (status === 7) setStatusIcon(onApprove);
    else if (overdueBp && status === 2) setStatusIcon(Overdue);
  }, [status]);

  useEffect(() => {
    if (status === 0) {
      setStatusObj({
        text: "Черновик",
        styleText: {
          color: "black",
        },
      });
    } else if (status === 1) {
      setStatusObj({
        text: "Ожидание",
        styleText: {
          color: "#FF9900",
        },
      });
    } else if (status === 2 && !overdueBp) {
      setStatusObj({
        text: "В работе",
        styleText: {
          color: "#436EA0",
        },
      });
    } else if (status === 3) {
      setStatusObj({
        text: "Выполнено",
        styleText: {
          color: "#43A047",
        },
      });
    } else if (status === 4) {
      setStatusObj({
        text: "Завершено",
        styleText: {
          color: "#43A047",
        },
      });
    } else if (status === 5) {
      setStatusObj({
        text: "Отменено",
        styleText: {
          color: "#7B7B7B",
        },
      });
    } else if (status === 7) {
      setStatusObj({
        text: "На согласовании",
        styleText: {
          color: "#FF9900",
        },
        styleIcons: {
          borderColor: "rgba(255, 153, 0, 0.1)",
          background: "rgba(255, 153, 0, 0.1)",
        },
        src: "onApprove.svg",
      });
    } else if (overdueBp && status === 2) {
      setStatusObj({
        text: "Просрочено",
        styleText: {
          color: "#F44336",
        },
        styleIcons: {
          borderColor: "1px solid #F44336",
          background: "rgba(244, 67, 54, 0.1);",
        },
      });
    } else {
      setStatusObj({
        text: "Черновик",
        styleText: {
          color: "black",
        },
      });
    }
  }, [
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    setStatusObj,
    status,
  ]);

  const makeActiveTask = (e) => {
    if (e.dataset.status == 50) {
      axios
        .patch(
          `${process.env.REACT_APP_PUBLIC_API}/tasks/${e.dataset.id}`,
          { status_id: 3 },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .catch((e) => {
          console.log(e);
        });
      setOpenTasks("");
    }
  };

  return (
    <div
      className="business__main-content__list-block__item__status"
      onClick={(e) => {
        console.log("e", e);
        e.stopPropagation();
        makeActiveTask(e.currentTarget);
      }}
      //  dataId={id}
      data-status={status}
    >
      <button title={statusObj.text} style={{ width: "32px", height: "32px" }}>
        <img
          src={statusIcon}
          alt="status"
          style={{ width: "32px", height: "32px" }}
        />
      </button>
      {/* {createBpStatus ||
      createTaskStatus ||
      createBpSampleStatus ||
      openMenuTasks ||
      openMenuBp ? (
        <></>
      ) : ( */}
        <p title={statusObj.text} style={statusObj.styleText}>{statusObj.text}</p>
      {/* )} */}
    </div>
  );
};

export default BpItemStatus;
