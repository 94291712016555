import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../rootReducer';
import './styles.sass'

type Props = {
    searchValue: string,
    isOpen?: boolean,
    setSearchValue: (value: string) => void
}


const TableSearch: React.FC<Props> = ({searchValue, isOpen = false, setSearchValue}) => {

    const [searchMode, setMode] = useState<boolean>(isOpen)

    const input = useRef<HTMLInputElement>(null)
    const window = useRef<HTMLInputElement>(null)

    const {searchMeetings} = useSelector((state: State)=> state.commonInfo)


    useEffect(()=>{
        if(searchMeetings == null){
            if(input.current !== null){
                input.current.value = ""

            }
        }
    }, [searchMeetings])




    return (
        <div ref={window} onClick={() => {
            setMode(true)
            setTimeout(() => input.current?.focus(), 0)
        }} className={`table_search${searchMode ? ' in_search_mode' : ''}`}>
            {searchMode ? (
                <>
                    <input
                        ref={input}
                        value={searchValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    />
                    <div onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        setMode(false)
                        setSearchValue('')
                    }} className="close_search"/>
                </>
            ) : (
                <div>Поиск</div>
            )}
        </div>
    )
}


export default TableSearch